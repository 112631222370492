import React, { useContext, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { showSnackbar } from 'helpers/snackbar';

import { media } from 'context';
import { Animate, CHATBOT_THREAD_INPUT_HEIGHT, IconButton, Layout, ScrollShadowContainer, Text } from 'ds';
import usePageVisibility from 'hooks/usePageVisibility';
import { actions } from 'store/AIAssistant';
import { selectChatbotThreadById, selectPendingThreadInteraction } from 'store/AIAssistant/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createOrUpdateSupportTicketView } from 'ux/Customer/Support/requests';

import ChatbotThinkingDisplay from './ChatbotThinkingDisplay';
import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import ChatbotThreadMessage from './ChatbotThreadMessage';
import ChatbotViewSupportTicket from './ChatbotWidgets/ChatbotViewSupportTicket';
import ChatbotWidget from './ChatbotWidgets/ChatbotWidget';
import { CHATBOT_NAVIGATION_HEADER_HEIGHT } from './constants';
import { addFeedbackToLlmMessage } from './requests';
import useChatbotHeight from './useChatbotHeight';
import { getGreetingVariation } from './utils';

interface Props {
  threadId: number;
}

const THREAD_ID_TO_GREETING_VARIATION = new Map<number, string>();

const ChatbotThread: React.FC<Props> = ({ threadId }) => {
  const user = useAppSelector(selectUser);
  const parsedThreadId = threadId || -1;
  const thread = useAppSelector(selectChatbotThreadById(parsedThreadId));
  const [messageIdHovered, setMessageIdHovered] = useState<number | null>(null);
  const { isMobile } = useContext(media);

  let greetingVariation = THREAD_ID_TO_GREETING_VARIATION.get(parsedThreadId);
  if (!greetingVariation) {
    greetingVariation = getGreetingVariation(user?.firstname || '');
    THREAD_ID_TO_GREETING_VARIATION.set(parsedThreadId, greetingVariation);
  }

  const pendingInteraction = useAppSelector(selectPendingThreadInteraction(parsedThreadId));
  const lastInteraction = thread?.chatbot_interactions.slice(-1)[0];
  const containerRef = useRef<HTMLDivElement>(null);
  const [_hasScrolled, setHasScrolled] = useState(false);

  const height = useChatbotHeight();
  const supportTicket = lastInteraction?.chatbot_intents.map(ci => ci.support_ticket).find(st => st?.id);
  const updateKey =
    (thread
      ? Math.max(...thread.chatbot_interactions.map(i => DateTime.fromISO(i.updated_at).toMillis()))
      : 0
    ).toString() +
    (pendingInteraction ? 'isPending' : '') +
    lastInteraction?.chatbot_intents.map(ci => ci.support_ticket?.messages.length || 0).reduce((a, b) => a + b, 0);

  const handleSupportTicketView = async () => {
    if (supportTicket?.id) {
      const { data } = await createOrUpdateSupportTicketView({ supportTicketId: supportTicket.id });

      dispatch(actions.updateSupportTicket(data));
    }
  };

  usePageVisibility(isVisible => {
    if (isVisible) {
      handleSupportTicketView();
    }
  });

  const scrollToBottom = () => {
    const container = containerRef.current;

    if (!container) return;

    container.scrollTo({ top: container.scrollHeight });
  };

  useEffect(() => {
    scrollToBottom();
  }, [updateKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!supportTicket?.id) return;

    handleSupportTicketView();
  }, [supportTicket?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (threadId === -1) {
        THREAD_ID_TO_GREETING_VARIATION.delete(threadId);
      }
    };
  }, [threadId]);

  const dispatch = useAppDispatch();

  if (!user) return null;

  const llmMessagesOrdered =
    thread?.chatbot_interactions
      .flatMap(interaction =>
        (interaction.llm_conversation?.llm_messages || []).map(message => ({
          ...message,
          chatbotInteraction: interaction
        }))
      )
      .sort((a, b) => DateTime.fromISO(a.created_at).toMillis() - DateTime.fromISO(b.created_at).toMillis()) || [];

  const handleAddFeedback = async (llmMessageId: number, chatbotInteractionId: number, liked: boolean) => {
    const response = await addFeedbackToLlmMessage(llmMessageId, liked);
    if (response.status === 200) {
      showSnackbar({
        message: 'Thanks for the feedback!',
        type: 'light'
      });
      dispatch(actions.updateLlmMessageFeedback({ llmMessageId, threadId, interactionId: chatbotInteractionId }));
    } else {
      showSnackbar({
        message: 'Failed to add feedback',
        negative: true
      });
    }
  };

  return (
    <ScrollShadowContainer
      setHasScrolled={setHasScrolled}
      position="relative"
      paddingX={24}
      __ref={containerRef}
      {...{
        height: height - CHATBOT_NAVIGATION_HEADER_HEIGHT - CHATBOT_THREAD_INPUT_HEIGHT,
        overflowY: 'auto',
        paddingBottom: 24,
        width: 768
      }}
    >
      <Layout>
        {!!thread?.created_at && (
          <Layout justify="center" align="center" paddingY={12}>
            <Layout borderRadius={1000} color="gray-25" paddingX={12} paddingY={2} flexShrink={1}>
              <Text size="body-sm">{DateTime.fromISO(thread?.created_at).toFormat('MMM d, h:mm a')}</Text>
            </Layout>
          </Layout>
        )}
        {llmMessagesOrdered.map(message => (
          <React.Fragment key={message.id}>
            {message.role === 'user' && (
              <Layout marginLeft={24} justify="flex-end">
                <ChatbotThreadCustomerMessage>
                  <div dangerouslySetInnerHTML={{ __html: message.content }} />
                </ChatbotThreadCustomerMessage>
              </Layout>
            )}
            {message.role === 'assistant' && (
              <div onMouseOver={() => setMessageIdHovered(message.id)} onMouseLeave={() => setMessageIdHovered(null)}>
                <Layout marginTop={16} flex direction="column">
                  <ChatbotThreadMessage>
                    <div dangerouslySetInnerHTML={{ __html: message.content }} />
                  </ChatbotThreadMessage>
                  {!message.has_given_feedback && (
                    <Layout
                      flex
                      direction="row"
                      marginLeft={64}
                      gap={2}
                      visibility={isMobile || messageIdHovered === message.id ? 'visible' : 'hidden'}
                    >
                      <IconButton
                        name="thumbsUp"
                        type="noBackground"
                        size="sm"
                        stroke={2}
                        onClick={() => handleAddFeedback(message.id, message.chatbotInteraction.id, true)}
                      />
                      <IconButton
                        name="thumbsDown"
                        type="noBackground"
                        size="sm"
                        stroke={2}
                        onClick={() => handleAddFeedback(message.id, message.chatbotInteraction.id, false)}
                      />
                    </Layout>
                  )}
                </Layout>
              </div>
            )}
            {message.role === 'metadata' && message.metadata.resolution?.type === 'support_ticket' && (
              <Layout marginTop={16}>
                <ChatbotViewSupportTicket supportTicket={message.metadata.resolution?.support_ticket} />
              </Layout>
            )}
            {message.role === 'metadata' && message.metadata.resolution?.type === 'widget' && (
              <Layout marginTop={16}>
                <ChatbotWidget
                  chatbotInteraction={message.chatbotInteraction}
                  threadId={threadId}
                  chatbotIntentId={message.chatbot_intent_id}
                />
              </Layout>
            )}
          </React.Fragment>
        ))}
        {!!pendingInteraction && (
          <Animate marginBottom={24} animation="fade-up" duration={300} marginLeft={24} delay={0} justify="flex-end">
            <ChatbotThreadCustomerMessage>{pendingInteraction.input}</ChatbotThreadCustomerMessage>
          </Animate>
        )}
        {!!pendingInteraction && (
          <Animate marginTop={24} delay={300} duration={300}>
            <ChatbotThinkingDisplay />
          </Animate>
        )}
      </Layout>
    </ScrollShadowContainer>
  );
};

export default ChatbotThread;
