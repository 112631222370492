import React, { ForwardedRef, MutableRefObject, PropsWithChildren, RefObject, useState } from 'react';

import IconButton from 'ds/IconButton';
import { ANIMATION_DURATION, MODAL_Z_INDEX, SCROLLABLE_BOX_SHADOW } from 'ds/constants';

import { ModalProps } from './types';
import Layout, { LayoutBorderRadius } from '../Layout';
import ScrollShadowContainer from '../ScrollShadowContainer';
import Spinner from '../Spinner';
import Text from '../Text';

const MARGIN_X = 24;
const MARGIN_Y = 48;

interface Props extends ModalProps {
  scrollContainerRef: ForwardedRef<HTMLDivElement>;
  containerWidth?: number;
  containerHeight?: number;
  borderRadius?: LayoutBorderRadius;
  width: number;
  footerRef: RefObject<HTMLDivElement>;
  footerHeight: number;
}

const ModalContent: React.FC<PropsWithChildren<Props>> = ({
  header,
  footer,
  children,
  onClose,
  topLeft,
  headerProps,
  headerAlign,
  closeDisabled,
  isFullScreen,
  isSideDrawer,
  paddingTop = 16,
  paddingX = 36,
  paddingBottom = 24,
  closeButtonTop = 24,
  showCloseButton = true,
  scrollContainerRef,
  showLoadingState,
  containerWidth,
  containerHeight,
  borderRadius,
  width,
  footerRef,
  footerHeight
}) => {
  const [hasScrolled, setHasScrolled] = useState<boolean>();

  const hideFullScreenModal = () => {
    if (closeDisabled) return;

    setTimeout(() => {
      onClose();
    }, ANIMATION_DURATION);
  };

  const closeButton = showCloseButton ? (
    <Layout zIndex={MODAL_Z_INDEX + 2} top={isFullScreen ? 24 : closeButtonTop} right={MARGIN_X} position="absolute">
      <IconButton
        onClick={isFullScreen ? hideFullScreenModal : onClose}
        name="close"
        stroke={2}
        type="gray"
        size="md"
        disabled={closeDisabled}
      />
    </Layout>
  ) : null;

  const maxHeight = isFullScreen
    ? undefined
    : `calc(100vh - ${(isSideDrawer ? 0 : MARGIN_Y) * 2 + (header ? 72 : 0) + footerHeight}px)`;

  return (
    <>
      {header ? (
        <Layout
          borderTopLeftRadius={12}
          borderTopRightRadius={12}
          className="Modal-header"
          position="relative"
          {...(isFullScreen
            ? { width: '100%', height: 0 }
            : { color: 'white', paddingTop: 24, paddingX: 36, paddingBottom: 12, width, height: 72 })}
          zIndex={MODAL_Z_INDEX + 1}
          boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
          {...headerProps}
        >
          {topLeft && (
            <Layout zIndex={MODAL_Z_INDEX + 1} left={MARGIN_X} position="absolute">
              {topLeft}
            </Layout>
          )}
          {closeButton}
          <Layout justify={headerAlign === 'left' ? undefined : 'center'} align="center" height="100%">
            {typeof header === 'string' ? (
              <Text size="headline-xs" align={headerAlign || 'center'} ellipsis>
                {header}
              </Text>
            ) : (
              header
            )}
          </Layout>
        </Layout>
      ) : (
        <Layout width={width}>{closeButton}</Layout>
      )}
      <ScrollShadowContainer
        paddingTop={isFullScreen ? undefined : paddingTop}
        paddingX={isFullScreen ? undefined : paddingX}
        paddingBottom={isFullScreen ? undefined : paddingBottom}
        overflowY="auto"
        maxHeight={isSideDrawer ? undefined : maxHeight}
        height={isFullScreen ? '100%' : isSideDrawer ? maxHeight : undefined}
        setHasScrolled={setHasScrolled}
        __ref={scrollContainerRef as MutableRefObject<HTMLDivElement>}
      >
        {children}
      </ScrollShadowContainer>
      {footer && (
        <Layout
          position="sticky"
          paddingX={paddingX}
          paddingY={16}
          color="white"
          __ref={footerRef}
          borderBottomLeftRadius={12}
          borderBottomRightRadius={12}
          boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
          className="Modal-footer"
        >
          {footer}
        </Layout>
      )}
      {showLoadingState && (
        <Layout
          opacity={showLoadingState ? 0.2 : undefined}
          width={containerWidth}
          height={containerHeight}
          position="absolute"
          top={0}
          left={0}
          zIndex={MODAL_Z_INDEX + 2}
          color="black"
          {...(isSideDrawer ? {} : { borderRadius })}
        />
      )}
      {showLoadingState && (
        <Layout
          position="fixed"
          {...(isSideDrawer
            ? { top: '50vh', right: (containerWidth || 0) / 2, transform: 'translateX(50%) translateY(-50%)' }
            : { top: '50vh', left: '50vw', transform: 'translateX(-50%) translateY(-50%)' })}
        >
          <Spinner size="md" />
        </Layout>
      )}
    </>
  );
};

export default ModalContent;
