import React, { useContext, useState } from 'react';
import { Message as MessageType } from 'shared';
import { DateTime } from 'luxon';

import { media } from 'context';
import Avatar from 'ds/Avatar';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Lightbox from 'ds/Lightbox';
import RichTextDisplay from 'ds/RichTextDisplay';
import Text from 'ds/Text';
import { namePartsToFirstLastInitial } from 'ds/utils';

import MessageImageThumbnail from './MessageImageThumbnail';

interface Props {
  message: MessageType;
}

const Message: React.FC<Props> = ({ message: { author_email, author, liked, body, images, created_at } }) => {
  const { avatar_url, first, last, customer } = author ?? {};
  const [lightboxStartingIndex, setLightboxStartingIndex] = useState<number>(-1);
  const { xs } = useContext(media);

  const likedOrDisliked = liked !== null;

  const bodyDisplay = !body && !!liked ? 'Gave positive feedback.' : likedOrDisliked ? `"${body}"` : body;

  return (
    <Layout flexGrow={1}>
      {lightboxStartingIndex >= 0 && (
        <Lightbox
          images={images}
          startingIndex={lightboxStartingIndex}
          onClose={() => setLightboxStartingIndex(-1)}
          padResize
        />
      )}
      <Layout direction="row" flexGrow={1}>
        <Avatar size={32} avatarUrl={avatar_url} borderWidth={0} />
        <Layout marginLeft={16} />
        <Layout direction="column" flexGrow={1}>
          <Layout direction="row" paddingY={6} justify="space-between" align="center">
            {!!author_email ? (
              <Text size="body-xs" color="gray-400">
                {author_email}
              </Text>
            ) : (
              <Text size="body-xs" color="gray-400">
                {namePartsToFirstLastInitial({ firstname: first!, lastname: last! })}
                {!!customer && ` • ${customer.name}`}
              </Text>
            )}
            <Text size="body-xs" color="gray-400">
              {DateTime.fromISO(created_at).toRelative()}
            </Text>
          </Layout>
          <Layout marginTop={4} />
          <Layout direction="column">
            <Layout
              padding={likedOrDisliked ? 12 : undefined}
              color={likedOrDisliked ? 'gray-25' : undefined}
              borderRadius={likedOrDisliked ? 8 : undefined}
              align="flex-start"
            >
              {likedOrDisliked && (
                <Layout
                  width={32}
                  minWidth={32}
                  height={32}
                  minHeight={32}
                  borderRadius="circular"
                  color={liked ? 'blue-500' : 'gray-700'}
                  align="center"
                  justify="center"
                  marginRight={12}
                >
                  <Icon name={liked ? 'thumbsUpFilled' : 'thumbsDownFilled'} size="xs" color="white" />
                </Layout>
              )}
              <Layout marginTop={likedOrDisliked ? 6 : undefined}>
                <Text size="body-sm" color={likedOrDisliked ? 'gray-700' : undefined} italic={likedOrDisliked}>
                  <RichTextDisplay value={bodyDisplay} />
                </Text>
              </Layout>
            </Layout>
            <Layout marginTop={12} />
            {!!images.length && (
              <Layout marginTop={24} flex wrap position="relative" left={-6} width="calc(100% + 12px)">
                {images.map(({ key }, index) => {
                  return (
                    <MessageImageThumbnail
                      onClick={() => setLightboxStartingIndex(index)}
                      style={{
                        margin: xs ? '10px 6px' : '8px 6px',
                        width: 98,
                        height: 98
                      }}
                      key={key}
                      srcKey={key}
                    />
                  );
                })}
              </Layout>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Message;
