import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { media } from 'context';
import { APP_BANNER_HEIGHT, Clickable, IconButton, Layout, Text } from 'ds';
import { AI_ASSISTANT_PATH } from 'routes';
import { selectChatbotThreadsAndGroupByMonth } from 'store/AIAssistant/selectors';
import { selectUIState } from 'store/UI/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { CHATBOT_BOTTOM_NAVIGATION_HEIGHT, CHATBOT_NAVIGATION_HEADER_HEIGHT } from './constants';
import useChatbotHeight from './useChatbotHeight';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  loadedFirstTime: boolean;
  setLoadedFirstTime: (loadedFirstTime: boolean) => void;
  sideBarIsAlreadyShown: boolean;
}

const ChatbotThreads: React.FC<Props> = ({
  isVisible,
  onClose,
  loadedFirstTime,
  setLoadedFirstTime,
  sideBarIsAlreadyShown
}) => {
  const user = useAppSelector(selectUser);
  const { appBannerType } = useAppSelector(selectUIState);
  const { id: threadIdSelected } = useParams<{ id: string | undefined }>();
  const threadsByMonth = useAppSelector(selectChatbotThreadsAndGroupByMonth);
  const height = useChatbotHeight();
  const { isMobile } = useContext(media);
  const history = useHistory();

  useEffect(() => {
    if (isVisible && !loadedFirstTime) {
      setLoadedFirstTime(true);
    }
  }, [isVisible, loadedFirstTime, setLoadedFirstTime]);

  if (!user || !loadedFirstTime) return null;

  function toSentenceCase(str: string) {
    return str
      .toLowerCase()
      .replace(/(^\s*\w|[.!?]\s*\w)/g, match => match.toUpperCase())
      .replace(/\bcodi\b/gi, 'Codi');
  }

  return (
    <Layout
      direction="column"
      justify="flex-start"
      align="flex-start"
      color="gray-25"
      height="100%"
      width="320px"
      opacity={1}
      top={appBannerType ? APP_BANNER_HEIGHT : 0}
      left={60}
      flex
      padding={24}
      position="fixed"
      overflow="hidden"
      className={`threads-sidebar ${sideBarIsAlreadyShown ? '' : isVisible ? 'slide-in' : 'slide-out'}`}
    >
      <Layout width="100%" direction="row" justify="flex-end" align="center">
        <IconButton iconColor="gray-400" name="sideMenu" size="md" onClick={onClose} type="noBackground" stroke={2} />
      </Layout>
      <Layout
        {...(isMobile ? {} : { height: height - CHATBOT_NAVIGATION_HEADER_HEIGHT - CHATBOT_BOTTOM_NAVIGATION_HEIGHT })}
        overflowY="auto"
        flex
        direction="column"
        gap={12}
        marginTop={12}
        flexGrow={1}
        width="100%"
      >
        {threadsByMonth.map(threadGroup => (
          <Layout key={threadGroup.threads[0]?.created_at} direction="column" gap={12}>
            <Layout marginLeft={24}>
              <Text size="body-xs" color="gray-400" semibold>
                {threadGroup.name}
              </Text>
            </Layout>
            <Layout flex direction="column">
              {threadGroup.threads.map(({ id: threadId, chatbot_interactions }) => {
                const lastInteraction = chatbot_interactions[chatbot_interactions.length - 1];

                if (!lastInteraction) return '';

                const isSupportTicket = !!lastInteraction.chatbot_intents
                  .map(ci => ci.support_ticket?.messages.length || 0)
                  .reduce((a, b) => a + b, 0);

                const messagePreview = isSupportTicket
                  ? lastInteraction.chatbot_intents
                      .map(ci => ci.support_ticket?.messages.slice(-1)[0].body || '')
                      .reduce((a, b) => a + b, '')
                  : lastInteraction.input;

                return (
                  <Clickable
                    onClick={e => {
                      e.stopPropagation();
                      history.push(`${AI_ASSISTANT_PATH}/${threadId}`);
                    }}
                    key={threadId}
                  >
                    <Layout
                      paddingRight={8}
                      paddingLeft={24}
                      height={48}
                      borderRadius={8}
                      hoverColor={threadIdSelected === threadId.toString() ? undefined : 'gray-50'}
                      color={threadIdSelected === threadId.toString() ? 'purple-gradient-transparent' : 'gray-25'}
                      align="center"
                      maxWidth={272}
                    >
                      <Text size="body-sm" align="left" ellipsis>
                        {toSentenceCase(messagePreview)}
                      </Text>
                    </Layout>
                  </Clickable>
                );
              })}
            </Layout>
          </Layout>
        ))}
      </Layout>
    </Layout>
  );
};

export default ChatbotThreads;
