import React from 'react';

import { Layout, PageLayout, Text } from 'ds';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

interface Props {}

const MobileComingSoon: React.FC<Props> = () => {
  const user = useAppSelector(selectUser);
  const adminUserFirstNames = user?.customer?.coworkers
    .filter(c => c.admin)
    .map(c => `${c.firstname} ${c.lastname}`)
    .join(', ')
    .replace(/,([^,]*)$/, count =>
      count.split(',').length === 2 ? ' or' + count.split(',')[1] : ', or' + count.split(',')[1]
    );

  return (
    <PageLayout type="default">
      <Layout height="80vh" flexGrow={1} justify="center" align="center" direction="column">
        <span
          id="hi-user-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '8px'
          }}
        >
          <div
            id="hi-user"
            style={{
              fontSize: '48.76px',
              fontWeight: 700,
              lineHeight: '64px',
              background: 'linear-gradient(90deg, #0078FF 0%, #8E5FF4 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Hi, {user?.firstname}.
          </div>
        </span>
        {adminUserFirstNames && (
          <Layout marginTop={16} maxWidth={560}>
            <Text size="body-sm" align="center">
              Please speak to {adminUserFirstNames} about getting access to your company's Codi account.
            </Text>
          </Layout>
        )}
      </Layout>
    </PageLayout>
  );
};

export default MobileComingSoon;
