import React, { useContext } from 'react';

import { app, media } from 'context';
import Icon from 'ds/Icon';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Link from 'ds/Link';
import Text from 'ds/Text';
import { HEADER_Z_INDEX } from 'ds/constants';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import AppMobileNavBar from './AppMobileNavBar';
import AppPageTitleContainer from './AppPageTitleContainer';
import BreadCrumbs from './BreadCrumbs';
import MobileTransitionNavBar from './MobileTransitionNavBar';
import { APP_PAGE_TITLE_HEIGHT_MOBILE } from './constants';
import { CommonPageLayoutProps, PageTypeProps } from './types';

type Props = Pick<
  CommonPageLayoutProps & PageTypeProps,
  | 'CTA'
  | 'title'
  | 'breadCrumbs'
  | 'StickyBar'
  | 'mobileBackLink'
  | 'backLink'
  | 'type'
  | 'MobileNavBarInitial'
  | 'MobileNavBarTransitioned'
  | 'mobileColor'
  | 'hasPaddingX'
  | 'hasPaddingXMobile'
  | 'suppressHeaderShadow'
  | 'color'
  | 'titleColor'
  | 'mobileNavBarType'
  | 'hasBorderBottom'
  | 'navBarTopOffset'
>;

const AppPageNavBar: React.FC<Props> = ({ color = 'white', ...props }) => {
  const { isMobile } = useContext(media);

  if (isMobile) {
    return <ContentMobile {...props} />;
  }

  const { title, breadCrumbs, CTA } = props;

  if (!title && !breadCrumbs && !CTA) {
    return null;
  }

  return (
    <StickyHeader
      top={props.navBarTopOffset ?? 0}
      zIndex={HEADER_Z_INDEX - 1}
      shadow={!props.suppressHeaderShadow && !props.StickyBar}
      color={color}
      borderBottom={props.hasBorderBottom}
    >
      <AppPageTitleContainer {...props}>
        <Content {...props} />
      </AppPageTitleContainer>
    </StickyHeader>
  );
};

const Content: React.FC<Props> = ({ title, breadCrumbs, CTA, backLink, titleColor }) => {
  const titleElement = (
    <Text size="headline-md" color={titleColor}>
      {title}
    </Text>
  );
  if (backLink) {
    return (
      <>
        <Layout align="center">
          <IconButton size="lg" type="noBackground" href={backLink.href} onClick={backLink.onClick} name="leftArrow" />
          <Layout marginLeft={12}>{titleElement}</Layout>
        </Layout>
        {CTA}
      </>
    );
  }

  return (
    <>
      {breadCrumbs ? <BreadCrumbs items={breadCrumbs} /> : titleElement}
      {CTA}
    </>
  );
};

const ContentMobile: React.FC<Props> = ({
  mobileBackLink,
  type,
  MobileNavBarInitial,
  MobileNavBarTransitioned,
  title,
  titleColor,
  CTA,
  mobileColor,
  hasPaddingXMobile,
  mobileNavBarType
}) => {
  const { appPaddingX } = useContext(app);

  switch (type) {
    case 'secondary':
    case 'default':
    case 'table':
    case 'customerTable':
    case 'index':
      return (
        <AppMobileNavBar
          backLink={mobileBackLink}
          title={title}
          CTA={CTA}
          color={mobileColor}
          titleColor={titleColor}
          hasPaddingX={hasPaddingXMobile}
          type={mobileNavBarType}
        />
      );
    case 'object':
    case 'tertiary':
      return MobileNavBarInitial && MobileNavBarTransitioned ? (
        <>
          {(mobileBackLink || CTA) && (
            <Layout
              paddingX={hasPaddingXMobile === false ? undefined : appPaddingX}
              height={APP_PAGE_TITLE_HEIGHT_MOBILE}
              align="center"
              justify="space-between"
            >
              {mobileBackLink && (
                <Link href={mobileBackLink.href}>
                  <Layout align="center">
                    <Icon name="leftChevron" color="gray-900" size="sm" />
                    <Layout marginLeft={4}>
                      <Text size="body1" color="gray-900" maxLines={2}>
                        {mobileBackLink.label}
                      </Text>
                    </Layout>
                  </Layout>
                </Link>
              )}
              {CTA}
            </Layout>
          )}
          <MobileTransitionNavBar
            Initial={MobileNavBarInitial}
            Transitioned={MobileNavBarTransitioned}
            color={mobileColor}
          />
        </>
      ) : (
        <AppMobileNavBar
          backLink={mobileBackLink}
          title={title}
          titleColor={titleColor}
          color={mobileColor}
          CTA={CTA}
          hasPaddingX={hasPaddingXMobile}
          type={mobileNavBarType}
        />
      );
    default:
      return null;
  }
};

export default AppPageNavBar;
