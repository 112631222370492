import hexToRgb from './utils/hexToRgb';

export const BLUE_900 = 'blue-900';
export const BLUE_900_HEX = '#001141';

export const BLUE_800 = 'blue-800';
export const BLUE_800_HEX = '#103084';

export const BLUE_700 = 'blue-700';
export const BLUE_700_HEX = '#002d9c';

export const BLUE_600 = 'blue-600';
export const BLUE_600_HEX = '#0043ce';

export const BLUE_500 = 'blue-500';
export const BLUE_500_HEX = '#0078ff';

export const BLUE_400 = 'blue-400';
export const BLUE_400_HEX = '#4589ff';

export const BLUE_300 = 'blue-300';
export const BLUE_300_HEX = '#a6c8ff';

export const BLUE_200 = 'blue-200';
export const BLUE_200_HEX = '#d0e2ff';

export const BLUE_100 = 'blue-100';
export const BLUE_100_HEX = '#edf5ff';

export const BLUE_50 = 'blue-50';
export const BLUE_50_HEX = '#f2f8ff';

export const BLUE_25 = 'blue-25';
export const BLUE_25_HEX = '#f8fbff';

export const TRUE_BLACK = 'true-black';
export const TRUE_BLACK_HEX = '#000000';

export const BLACK = 'black';
export const BLACK_HEX = '#263238';

export const GRAY_950 = 'gray-950';
export const GRAY_950_HEX = '#14181d';

export const GRAY_900 = 'gray-900';
export const GRAY_900_HEX = '#263238';

export const GRAY_800 = 'gray-800';
export const GRAY_800_HEX = '#37474f';

export const GRAY_700 = 'gray-700';
export const GRAY_700_HEX = '#455a64';

export const GRAY_600 = 'gray-600';
export const GRAY_600_HEX = '#546e7a';

export const GRAY_500 = 'gray-500';
export const GRAY_500_HEX = '#607d8b';

export const GRAY_400 = 'gray-400';
export const GRAY_400_HEX = '#78909c';

export const GRAY_300 = 'gray-300';
export const GRAY_300_HEX = '#90a4ae';

export const GRAY_200 = 'gray-200';
export const GRAY_200_HEX = '#b0bec5';

export const GRAY_100 = 'gray-100';
export const GRAY_100_HEX = '#cfd8dc';

export const GRAY_50 = 'gray-50';
export const GRAY_50_HEX = '#eceff1';

export const GRAY_25 = 'gray-25';
export const GRAY_25_HEX = '#fbfbfc';

export const WHITE = 'white';
export const WHITE_HEX = '#ffffff';

export const GOLD_100 = 'gold-100';
export const GOLD_100_HEX = '#fff8eb';

export const GOLD_200 = 'gold-200';
export const GOLD_200_HEX = '#fff1d6';

export const GOLD_300 = 'gold-300';
export const GOLD_300_HEX = '#fee2a9';

export const GOLD_400 = 'gold-400';
export const GOLD_400_HEX = '#fdcf72';

export const GOLD_500 = 'gold-500';
export const GOLD_500_HEX = '#fbbb3c';

export const GOLD_600 = 'gold-600';
export const GOLD_600_HEX = '#db7712';

export const GOLD_700 = 'gold-700';
export const GOLD_700_HEX = '#b25e09';

export const GOLD_800 = 'gold-800';
export const GOLD_800_HEX = '#96530f';

export const GOLD_900 = 'gold-900';
export const GOLD_900_HEX = '#80460d';

export const AQUA_500 = 'aqua-500';
export const AQUA_500_HEX = '#31e7b6';

export const AQUA_100 = 'aqua-100';
export const AQUA_100_HEX = '#e6fef8';

export const RED_100 = 'red-100';
export const RED_100_HEX = '#fef1f2';

export const RED_200 = 'red-200';
export const RED_200_HEX = '#fee1e3';

export const RED_300 = 'red-300';
export const RED_300_HEX = '#fec8cd';

export const RED_400 = 'red-400';
export const RED_400_HEX = '#fca6ad';

export const RED_500 = 'red-500';
export const RED_500_HEX = '#f8727d';

export const RED_600 = 'red-600';
export const RED_600_HEX = '#ef4352';

export const RED_700 = 'red-700';
export const RED_700_HEX = '#e02d3c';

export const RED_800 = 'red-800';
export const RED_800_HEX = '#ba2532';

export const RED_900 = 'red-900';
export const RED_900_HEX = '#981b25';

export const GREEN_100 = 'green-100';
export const GREEN_100_HEX = '#EDFDF8';

export const GREEN_500 = 'green-500';
export const GREEN_500_HEX = '#36D39F';

export const GREEN_700 = 'green-700';
export const GREEN_700_HEX = '#08875D';

export const GREEN_900 = 'green-900';
export const GREEN_900_HEX = '#066042';

export const PURPLE_400 = 'purple-400';
export const PURPLE_400_HEX = '#ac84ff';

export const PURPLE_500 = 'purple-500';
export const PURPLE_500_HEX = '#8e5ff4';

export const PURPLE_600 = 'purple-600';
export const PURPLE_600_HEX = '#7744e4';

export const PURPLE_700 = 'purple-700';
export const PURPLE_700_HEX = '#612dd0';

export const PURPLE_800 = 'purple-800';
export const PURPLE_800_HEX = '#4f1cb9';

export const PURPLE_900 = 'purple-900';
export const PURPLE_900_HEX = '#360b8f';

export const PRIMARY_COLORS = [
  BLUE_25,
  BLUE_50,
  BLUE_100,
  BLUE_200,
  BLUE_300,
  BLUE_400,
  BLUE_500,
  BLUE_600,
  BLUE_700,
  BLUE_800,
  BLUE_900
] as const;
export type PrimaryColor = typeof PRIMARY_COLORS[number];

export const SECONDARY_COLORS = [
  WHITE,
  GRAY_25,
  GRAY_50,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_800,
  GRAY_900,
  GRAY_950,
  BLACK,
  TRUE_BLACK
] as const;
export type SecondaryColor = typeof SECONDARY_COLORS[number];

export const TERTIARY_COLORS = [
  GOLD_100,
  GOLD_200,
  GOLD_300,
  GOLD_400,
  GOLD_500,
  GOLD_600,
  GOLD_700,
  GOLD_800,
  GOLD_900,
  RED_100,
  RED_200,
  RED_300,
  RED_400,
  RED_500,
  RED_600,
  RED_700,
  RED_800,
  RED_900,
  AQUA_100,
  AQUA_500,
  GREEN_100,
  GREEN_500,
  GREEN_700,
  GREEN_900,
  PURPLE_400,
  PURPLE_500,
  PURPLE_600,
  PURPLE_700,
  PURPLE_800,
  PURPLE_900
] as const;
export type TertiaryColor = typeof TERTIARY_COLORS[number];

export type Color = PrimaryColor | SecondaryColor | TertiaryColor;

export const ALL_COLORS = [...PRIMARY_COLORS, ...SECONDARY_COLORS, ...TERTIARY_COLORS] as const;

export const COLORS: Record<Color, string> = {
  'blue-900': BLUE_900_HEX,
  'blue-800': BLUE_800_HEX,
  'blue-700': BLUE_700_HEX,
  'blue-600': BLUE_600_HEX,
  'blue-500': BLUE_500_HEX,
  'blue-400': BLUE_400_HEX,
  'blue-300': BLUE_300_HEX,
  'blue-200': BLUE_200_HEX,
  'blue-100': BLUE_100_HEX,
  'blue-50': BLUE_50_HEX,
  'blue-25': BLUE_25_HEX,
  'true-black': TRUE_BLACK_HEX,
  black: GRAY_900_HEX,
  'gray-950': GRAY_950_HEX,
  'gray-900': GRAY_900_HEX,
  'gray-800': GRAY_800_HEX,
  'gray-700': GRAY_700_HEX,
  'gray-600': GRAY_600_HEX,
  'gray-500': GRAY_500_HEX,
  'gray-400': GRAY_400_HEX,
  'gray-300': GRAY_300_HEX,
  'gray-200': GRAY_200_HEX,
  'gray-100': GRAY_100_HEX,
  'gray-50': GRAY_50_HEX,
  'gray-25': GRAY_25_HEX,
  white: WHITE_HEX,
  'gold-100': GOLD_100_HEX,
  'gold-200': GOLD_200_HEX,
  'gold-300': GOLD_300_HEX,
  'gold-400': GOLD_400_HEX,
  'gold-500': GOLD_500_HEX,
  'gold-600': GOLD_600_HEX,
  'gold-700': GOLD_700_HEX,
  'gold-800': GOLD_800_HEX,
  'gold-900': GOLD_900_HEX,
  'aqua-500': AQUA_500_HEX,
  'aqua-100': AQUA_100_HEX,
  'red-100': RED_100_HEX,
  'red-200': RED_200_HEX,
  'red-300': RED_300_HEX,
  'red-400': RED_400_HEX,
  'red-500': RED_500_HEX,
  'red-600': RED_600_HEX,
  'red-700': RED_700_HEX,
  'red-800': RED_800_HEX,
  'red-900': RED_900_HEX,
  'green-100': GREEN_100_HEX,
  'green-500': GREEN_500_HEX,
  'green-700': GREEN_700_HEX,
  'green-900': GREEN_900_HEX,
  'purple-400': PURPLE_400_HEX,
  'purple-500': PURPLE_500_HEX,
  'purple-600': PURPLE_600_HEX,
  'purple-700': PURPLE_700_HEX,
  'purple-800': PURPLE_800_HEX,
  'purple-900': PURPLE_900_HEX
};

export function colorClassName(color: Color) {
  return `is-color-${color}`;
}

export function colorToRgba(color: Color, opacity: number) {
  const { r, g, b } = hexToRgb(COLORS[color]);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
