import React from 'react';
import { useLocation } from 'react-router-dom';

import { Layout, NavItem, SIDEBAR_EXPANDED_WIDTH } from 'ds';
import { selectSelectedOfficeId } from 'store/UI/selectors';
import { selectHasCustomerAdminRole, selectUser } from 'store/User/selectors';
import { selectCustomerOfficePaths } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import NavIcon from './NavIcon';
import NavImage from './NavImage';
import { injectWorkspaceId } from './utils';
import { isNavigationItemActive } from '../utils';

interface Props {
  items: NavItem[];
  theme: 'light' | 'dark' | 'white';
  isExpanded: boolean;
}

const SidebarItems: React.FC<Props> = ({ items, theme, isExpanded }) => {
  const { pathname } = useLocation();
  const user = useAppSelector(selectUser);

  const isCustomerAdmin = useAppSelector(selectHasCustomerAdminRole);
  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);
  const customerOfficePaths = useAppSelector(selectCustomerOfficePaths);

  const shouldDisplayDot = (label: string) => {
    return label === 'Favorites';
  };

  const itemElements = items.map(({ iconName, href, label, exact, target, imageKey, ...args }, index) => {
    const isFirst = index === 0;

    const injectedHref =
      customerOfficePaths.map(p => p.label).includes(label) && href && selectedOfficeId
        ? injectWorkspaceId({ href, selectedOfficeId })
        : href;
    const matchHrefs = args.matchHrefs?.map(matchHref => {
      const injectedHref =
        typeof matchHref.href === 'string' &&
        isCustomerAdmin &&
        customerOfficePaths.map(o => o.label).includes(label) &&
        matchHref.href &&
        selectedOfficeId
          ? injectWorkspaceId({ href: matchHref.href, selectedOfficeId })
          : matchHref.href;
      return {
        ...matchHref,
        href: injectedHref
      };
    });

    const navElement = imageKey ? (
      <NavImage
        href={injectedHref}
        imageKey={imageKey}
        index={index}
        label={label}
        theme={theme}
        isActive={isNavigationItemActive({ href: injectedHref, matchHrefs, exact, pathname })}
        displayDot={shouldDisplayDot(label)}
        isExpanded={isExpanded}
      />
    ) : (
      iconName && (
        <NavIcon
          iconName={iconName}
          target={target}
          href={injectedHref}
          index={index}
          label={label}
          theme={theme}
          isActive={isNavigationItemActive({ href: injectedHref, matchHrefs, exact, pathname })}
          displayDot={shouldDisplayDot(label)}
          isExpanded={isExpanded}
        />
      )
    );

    return {
      label: label,
      element: (
        <React.Fragment key={label}>
          <Layout
            marginTop={isFirst && !isCustomerAdmin ? 24 : 8}
            transition="width 200ms ease-in-out"
            width={isExpanded ? SIDEBAR_EXPANDED_WIDTH - 24 : 36}
          >
            {navElement}
          </Layout>
        </React.Fragment>
      )
    };
  });

  if (!user) return null;

  return itemElements.map(item => item.element);
};

export default SidebarItems;
