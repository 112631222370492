import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

export const getSnackbarMessages = (state: RootState) => state.UI.snackbarMessages;
export const selectPublicHeaderHasShadow = (state: RootState) => state.UI.publicHeaderHasShadow;
export const selectOfficeRequestWizardHideFooter = (state: RootState) => state.UI.officeRequestWizardHideFooter;
export const selectDefaultTypeaheadResults = createSelector(
  (state: RootState) => state.UI.defaultTypeaheadResults,
  (_state: RootState, name?: string) => name,
  (defaultTypeaheadResults, name) => {
    return defaultTypeaheadResults[name || ''] || { ids: [] };
  }
);
export const selectUIState = (state: RootState) => state.UI;
export const selectUxType = (state: RootState) => state.UI.uxType;
export const selectIsTenantUx = (state: RootState) => state.UI.uxType === 'tenant';
export const selectSelectedOfficeId = (state: RootState) => state.UI.selectedOfficeId;
