import loadImage from 'blueimp-load-image';

export const IMAGE_KIT_URL = 'https://assets.codi.com';

const S3_ASSETS_ENV = process.env.REACT_APP_S3_ASSETS_ENV || process.env.REACT_APP_ENV;

export const originalImageUrl = (key: string, folder = S3_ASSETS_ENV) =>
  `${IMAGE_KIT_URL}/${folder || S3_ASSETS_ENV}/tr:orig-true,f-jpg/${key}`;
export const imageKitUrl = ({
  key,
  transformationChain,
  folder = S3_ASSETS_ENV
}: {
  key: string;
  transformationChain?: string[];
  folder?: string;
}) => {
  let url = `${IMAGE_KIT_URL}/${folder || S3_ASSETS_ENV}`;

  if (transformationChain && transformationChain.length) {
    const transformations = transformationChain.map(transformation => `${transformation}`);
    url += `/tr:${transformations}`;
  }

  return `${url}/${key}`;
};

export const createTransformations = ({
  width,
  height,
  quality,
  padResize = false
}: {
  width?: number;
  height?: number;
  quality?: number;
  padResize?: boolean;
}) => {
  const dimensionTransformation = [];

  if (width) {
    dimensionTransformation.push(`w-${width}`);
  }
  if (height) {
    dimensionTransformation.push(`h-${height}`);
  }
  if (quality) {
    dimensionTransformation.push(`q-${quality}`);
  }
  if (padResize) {
    dimensionTransformation.push('cm-pad_resize');
  }

  return [dimensionTransformation.join(',')];
};

export const generateImgSrc = ({
  srcKey,
  width,
  height,
  folder,
  quality,
  padResize = false
}: {
  srcKey: string;
  width?: number;
  height?: number;
  folder?: string;
  quality?: number;
  padResize?: boolean;
}): string => {
  return imageKitUrl({
    key: srcKey,
    transformationChain: createTransformations({ width, height, quality, padResize }),
    folder
  });
};

// NOTE: the following are still used for avatar and check-in instructions uploads
export interface ResizedImg {
  url: string;
}

// TODO: type return value and refactor returnValidationBoolean into separate function
export function resizeImg({
  file,
  width = 1200,
  height = 800,
  crop = true,
  validate = false,
  returnValidationBoolean = false
}: {
  file: File;
  width: number;
  height: number;
  crop?: boolean;
  validate?: boolean;
  returnValidationBoolean?: boolean;
}) {
  return new Promise(function (resolve, reject) {
    loadImage.parseMetaData(file, function (data: any) {
      const landscape = width > height;
      //default image orientation
      let orientation = 0;
      //if exif data available, update orientation
      if (data.exif) {
        orientation = data.exif.get('Orientation');
      }
      const isRotated = 4 < orientation && orientation < 9;
      if (isRotated) {
        const newHeight = height;
        height = width;
        width = newHeight;
      }
      let reader = new FileReader();
      reader.onload = e => {
        let img = document.createElement('img');
        img.onload = () => {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');

          let imgWidth = img.width;
          let imgHeight = img.height;
          let rImgWidth = img.width;
          let rImgHeight = img.height;
          let x = 0;
          let y = 0;
          // used for if we just want to check the size:
          if (returnValidationBoolean) {
            if (imgWidth < width || imgHeight < height) {
              return resolve(false);
            } else {
              return resolve(true);
            }
          }
          if (validate && (imgWidth < width || imgHeight < height)) {
            reject('size_validation_error');
            return false;
          }
          if (crop) {
            rImgHeight = (height / width) * rImgWidth;
            if (rImgHeight > imgHeight) {
              rImgHeight = imgHeight;
              rImgWidth = (width / height) * imgHeight;
            }
            x = Math.max(0, (imgWidth - rImgWidth) / 2);
            y = Math.max(0, (imgHeight - rImgHeight) / 2);
            imgWidth = width;
            imgHeight = height;
          } else {
            if (imgWidth > imgHeight) {
              if (imgWidth > width) {
                imgHeight *= width / imgWidth;
                imgWidth = width;
              }
            } else {
              if (imgHeight > height) {
                imgWidth *= height / imgHeight;
                imgHeight = height;
              }
            }
          }
          if (isRotated) {
            canvas.width = imgHeight;
            canvas.height = imgWidth;
          } else {
            canvas.width = imgWidth;
            canvas.height = imgHeight;
          }
          // transform context before drawing image
          if (!!ctx) {
            switch (orientation) {
              case 6:
                if (landscape) {
                  const scale = imgHeight / imgWidth;
                  const vertical = -(imgHeight * scale) / 3;
                  ctx.transform(scale, 0, 0, scale, 0, vertical);
                }
                break;
              default:
                break;
            }

            ctx.drawImage(img, x, y, rImgWidth, rImgHeight, 0, 0, imgWidth, imgHeight);
          }

          canvas.toBlob(
            blob => {
              if (!!blob) resolve({ url: URL.createObjectURL(blob) });
            },
            'image/jpeg',
            0.85
          );
        };
        if (!!e.target) {
          img.src = e.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    });
  });
}
