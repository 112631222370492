import axios from 'shared/lib/axios';
import {
  BulkUpdateConsumablesProductSubscription,
  ConsumablesOrder,
  ConsumablesProduct,
  ConsumablesProductCategory,
  ConsumablesProductSubscription,
  ConsumablesProductType,
  DraftConsumablesOrderItem
} from 'shared/types';

export function getConsumablesProducts() {
  return axios.get<ConsumablesProduct[]>('/v1/consumables_products');
}

export function getConsumablesProductCategories() {
  return axios.get<ConsumablesProductCategory[]>('/v1/consumables_product_categories');
}

export function getConsumablesProductTypes() {
  return axios.get<ConsumablesProductType[]>('/v1/consumables_product_types');
}

export function getConsumablesOrders() {
  return axios.get<ConsumablesOrder[]>('/v1/consumables_orders');
}

export function getConsumablesProductSubscriptions() {
  return axios.get<ConsumablesProductSubscription[]>('/v1/consumables_product_subscriptions');
}

export function deleteConsumablesProductSubscription(id: number) {
  return axios.delete(`/v1/consumables_product_subscriptions/${id}`);
}

export function subscribeToProduct(consumableServiceProductId: number, quantity: number) {
  return axios.post('/v1/consumables_product_subscriptions/subscribe_to_product', {
    consumables_product_id: consumableServiceProductId,
    quantity: quantity
  });
}

export function updateConsumablesProductSubscription(id: number, quantity: number, consumableServiceProductId: number) {
  return axios.put(`/v1/consumables_product_subscriptions/${id}`, {
    quantity: quantity,
    consumables_product_id: consumableServiceProductId
  });
}

export function deleteConsumablesOrderItem(id: number) {
  return axios.delete(`/v1/consumables_order_items/${id}`);
}

export function createConsumablesOrderItem(consumablesProductId: number, quantity: number) {
  return axios.post('/v1/consumables_order_items', {
    consumables_product_id: consumablesProductId,
    quantity: quantity
  });
}

export function updateConsumablesOrderItem(id: number, quantity: number) {
  return axios.put(`/v1/consumables_order_items/${id}`, { quantity: quantity });
}

export function updateConsumablesProductSubscriptions(
  consumablesProductSubscriptions: BulkUpdateConsumablesProductSubscription[]
) {
  return axios.post('/v1/consumables_product_subscriptions/update_product_subscriptions', {
    consumables_product_subscriptions: consumablesProductSubscriptions
  });
}

export function updateConsumablesOrderItems(orderId: number, orderItems: DraftConsumablesOrderItem[]) {
  return axios.post('/v1/consumables_order_items/update_order_items', {
    orderId: orderId,
    order_items: orderItems
  });
}

export function setUpProductSubscriptions(consumablesProductSubscriptions: BulkUpdateConsumablesProductSubscription[]) {
  return axios.post('/v1/consumables_product_subscriptions/set_up_product_subscriptions', {
    consumables_product_subscriptions: consumablesProductSubscriptions
  });
}

export function createInitialOnboardingOrder() {
  return axios.post('/v1/consumables_orders/create_initial_onboarding_order');
}
