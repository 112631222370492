import { axios } from 'shared';
import { PlatformFee } from 'store/Tenancy/types';

export const getPlatformFees = ({ tenancyId }: { tenancyId: number }) => {
  return axios.get<PlatformFee[]>(`v2/tenancies/${tenancyId}/platform_fees`);
};

export const updatePlatformFee = ({
  tenancyId,
  platformFeeId,
  platformFee
}: {
  tenancyId: number;
  platformFeeId: number;
  platformFee: PlatformFee;
}) => {
  return axios.put<PlatformFee>(`v2/tenancies/${tenancyId}/platform_fees/${platformFeeId}`, platformFee);
};
