import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Icon, IconButton, Layout, Text } from 'ds';
import { PANTRY_SUBSCRIPTION_PATH } from 'routes/paths';
import { formatDate } from 'shared';
import { actions } from 'store/Consumables';
import { selectCategories, selectConsumablesOrders, selectProductSubscriptions } from 'store/Consumables/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ConsumablesPaymentMethod from './ConsumablesPaymentMethod';
import CostSummaryBox from '../CostSummaryBox';
import {
  getConsumablesOrders,
  getConsumablesProductCategories,
  getConsumablesProductSubscriptions,
  getConsumablesProductTypes,
  getConsumablesProducts
} from '../requests';
import { nextDeliveryDate } from '../utils';

const ConsumablesStartSubscriptionPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const consumablesProductSubscriptions = useAppSelector(selectProductSubscriptions);
  const consumablesOrders = useAppSelector(selectConsumablesOrders);
  const categories = useAppSelector(selectCategories);
  const total =
    consumablesProductSubscriptions?.reduce(
      (acc, curr) => acc + curr.consumables_product.amount_cents * curr.order_quantity,
      0
    ) ?? 0;

  useEffect(() => {
    if (!consumablesProductSubscriptions || !consumablesOrders || !categories) {
      Promise.all([
        getConsumablesProducts(),
        getConsumablesProductCategories(),
        getConsumablesProductTypes(),
        getConsumablesOrders(),
        getConsumablesProductSubscriptions()
      ]).then(
        ([
          { data: products },
          { data: newCategories },
          { data: newTypes },
          { data: orders },
          { data: subscriptions }
        ]) => {
          dispatch(actions.setProducts(products));
          dispatch(actions.setCategories(newCategories));
          dispatch(actions.setTypes(newTypes));
          dispatch(actions.setConsumablesOrders(orders));
          dispatch(actions.setConsumablesProductSubscriptions(subscriptions));
          dispatch(actions.setConsumablesStateLoaded(true));
        }
      );
    }
  }, [dispatch, consumablesProductSubscriptions, consumablesOrders, categories]);

  if (consumablesOrders && consumablesOrders.length > 0) {
    return <Redirect to={PANTRY_SUBSCRIPTION_PATH} />;
  }

  if (!consumablesProductSubscriptions) return null;

  const deliveryDateTime = nextDeliveryDate();
  const orderDeliveryDate = formatDate({
    date: deliveryDateTime,
    format: 'MED_WITH_SHORT_WEEKDAY'
  });
  const fiveDaysBeforeOrderDeliveryDate = formatDate({
    date: deliveryDateTime.minus({ days: 4 }),
    format: 'MED_WITH_SHORT_WEEKDAY'
  });
  return (
    <Layout paddingLeft={80} paddingBottom={64} flex direction="row" width="100%">
      <Layout flex direction="column" width={760} minWidth={760} paddingRight={40} gap={36}>
        <Layout height={88} align="center">
          <IconButton
            size="lg"
            type="noBackground"
            name="leftArrow"
            onClick={() => {
              history.push(PANTRY_SUBSCRIPTION_PATH);
            }}
          />
        </Layout>
        <Text size="headline-lg">Complete your order</Text>
        <Layout
          borderRadius={8}
          paddingX={8}
          paddingY={12}
          background="linear-gradient(90deg, rgba(0, 120, 255, 0.1) 0%, rgba(142, 95, 244, 0.1) 100%)"
          flex
          direction="row"
          gap={8}
          align="center"
        >
          <Icon name="info" size="xs" color="purple-gradient" stroke={2} />
          <Text size="body-xs" color="purple-gradient" semibold>
            Start today and get your first delivery on {orderDeliveryDate}. Modify or cancel this order before{' '}
            {fiveDaysBeforeOrderDeliveryDate}.
          </Text>
        </Layout>
        <ConsumablesPaymentMethod />
      </Layout>
      <Layout flexGrow={1} paddingX={80} paddingTop={80} paddingBottom={64} color="gray-25">
        <Layout flex direction="column" gap={24} width={440}>
          <Text size="headline-sm">Order summary</Text>
          <CostSummaryBox
            itemsSubtotalCents={total}
            disclaimerMessage={
              <Text size="body-xs" color="gray-400">
                First payment on {fiveDaysBeforeOrderDeliveryDate}. Billed on the Monday before the 2nd and 4th Fridays
                each month.
              </Text>
            }
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ConsumablesStartSubscriptionPage;
