import { CreatableMessage, UpdatableMessage } from 'ds/MessageThread/types';
import { EditableImage, Message } from 'shared';
import axios from 'shared/lib/axios';
import { WorkOrder } from 'ux/Tenant/types';

import { CreatableSupportTicket, SupportTicket, UpdatableSupportTicket } from './types';

export function getCustomerSupportTickets() {
  return axios.get<SupportTicket[]>('/v1/support_tickets');
}

export async function updateSupportTicket({
  supportTicket: { id, title, description, status, images = [] }
}: {
  supportTicket: UpdatableSupportTicket;
}) {
  const newImages = images.filter(i => !!i.file);
  const imagesToDestroy: { id: number; _destroy: boolean }[] = [];

  images.forEach(({ id, _destroy }) => {
    if (id && _destroy) {
      imagesToDestroy.push({ id, _destroy });
    }
  });

  if (newImages.length) {
    await uploadImages({ images: newImages, supportTicketId: id });
  }

  return axios.put<SupportTicket>(`/v1/support_tickets/${id}`, {
    title,
    id,
    description,
    status,
    images_attachments_attributes: imagesToDestroy
  });
}

export async function createSupportTicket({ supportTicket }: { supportTicket: CreatableSupportTicket }) {
  return axios.post<SupportTicket>('/v1/support_tickets', supportTicket);
}

function uploadImages({ images, supportTicketId }: { images: EditableImage[]; supportTicketId: number }) {
  const formData = new FormData();
  images.forEach(i => {
    if (i.file) {
      formData.append('images[]', i.file);
    }
  });
  return axios.patch<SupportTicket>(`/v1/support_tickets/${supportTicketId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function createOrUpdateSupportTicketView({ supportTicketId }: { supportTicketId: number }) {
  return axios.post<SupportTicket>('/v1/support_ticket_views', { support_ticket_id: supportTicketId });
}

export const getSupportTicketMessages = async ({ supportTicketId }: { supportTicketId: number }) => {
  return axios.get<SupportTicket>(`/v1/support_tickets/${supportTicketId}/messages`);
};

export async function updateSupportTicketMessage({
  supportTicketId,
  message: { id, images = [], body }
}: {
  supportTicketId: number;
  message: UpdatableMessage;
}) {
  const newImages = images.filter(i => !!i.file);
  const imagesToDestroy: { id: number; _destroy: boolean }[] = [];

  images.forEach(({ id, _destroy }) => {
    if (id && _destroy) {
      imagesToDestroy.push({ id, _destroy });
    }
  });

  if (newImages.length) {
    await uploadSupportTicketMessageImages({ images: newImages, supportTicketId, messageId: id });
  }

  return axios.put<SupportTicket>(`/v1/support_tickets/${supportTicketId}/messages`, {
    body
  });
}

export async function createSupportTicketMessage({
  supportTicketId,
  message: { images = [], body }
}: {
  supportTicketId: number;
  message: CreatableMessage;
}) {
  const newImages = images.filter(i => !!i.file);

  let response = await axios.post<Message>(`/v1/support_tickets/${supportTicketId}/messages`, {
    body
  });

  if (newImages.length) {
    response = await uploadSupportTicketMessageImages({
      images: newImages,
      messageId: response.data.id,
      supportTicketId
    });
  }

  return response;
}

function uploadSupportTicketMessageImages({
  images,
  supportTicketId,
  messageId
}: {
  images: EditableImage[];
  supportTicketId: number;
  messageId: number;
}) {
  const formData = new FormData();
  images.forEach(i => {
    if (i.file) {
      formData.append('images[]', i.file);
    }
  });
  return axios.patch<Message>(`/v1/support_tickets/${supportTicketId}/messages/${messageId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function createSupportTicketMessageEmoji({
  supportTicketId,
  messageId,
  value
}: {
  supportTicketId: number;
  messageId: number;
  value: string;
}) {
  return axios.post<Message>(`/v1/support_tickets/${supportTicketId}/message_emojis`, {
    message_id: messageId,
    value
  });
}

export function destroySupportTicketMessageEmoji({
  supportTicketId,
  messageEmojiId
}: {
  supportTicketId: number;
  messageEmojiId: number;
}) {
  return axios.delete<Message>(`/v1/support_tickets/${supportTicketId}/message_emojis/${messageEmojiId}`);
}

interface CreateSupportRequestParams {
  description: string;
  images: EditableImage[];
}

export const createSupportRequest = async ({ description, images }: CreateSupportRequestParams) => {
  const formData = new FormData();
  formData.append('description', description);
  if (images.length) {
    images.forEach(image => {
      if (image.file) {
        formData.append('images[]', image.file);
      }
    });
  }

  return await axios.post<SupportTicket>('/v1/support_tickets', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const approveWorkOrder = async ({
  supportTicketId,
  workOrderId
}: {
  supportTicketId: number;
  workOrderId: number;
}) => {
  return axios.post<WorkOrder>(`/v1/support_tickets/${supportTicketId}/work_orders/${workOrderId}/approve`);
};

export const addFeedback = async ({
  supportTicketId,
  workOrderId,
  liked
}: {
  supportTicketId: number;
  workOrderId: number;
  liked: boolean;
}) => {
  return axios.post<WorkOrder>(`/v1/support_tickets/${supportTicketId}/work_orders/${workOrderId}/add_feedback`, {
    liked
  });
};
