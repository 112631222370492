import React, { useContext, useState } from 'react';

import { media } from 'context';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { formatMoneyK } from 'ds/utils';
import { apiTrack } from 'lib/analytics';

import RangeFilterContainer, { RangeFilter } from './RangeFilter';

interface Props {
  values: number[];
  minPrice?: number;
  maxPrice?: number;
  maxPriceRequired?: boolean;
  offsitesOnly?: boolean;
  align?: 'left' | 'right';
  onChange?: (params: { minPrice?: number; maxPrice?: number }) => void;
  onSubmit?: (params: { minPrice?: number; maxPrice?: number }) => void;
  initialState?: 'open' | 'closed';
}

const SLIDER_MIN = 0;
const SLIDER_MAX = 100000;
const INPUT_MAX = 1000000;
const INTERVAL = 5000;

const PriceFilter: React.FC<Props> = ({
  values,
  minPrice,
  maxPrice,
  offsitesOnly,
  align,
  onSubmit,
  onChange,
  maxPriceRequired,
  initialState = 'closed'
}) => {
  const { xs } = useContext(media);
  const buttonText = generateButtonText({ minPrice, maxPrice, offsitesOnly });
  const [sliderMinValue, setSliderMinValue] = useState<number | undefined>(
    typeof minPrice === 'number' ? minPrice : SLIDER_MIN
  );
  const [sliderMaxValue, setSliderMaxValue] = useState<number | undefined>(
    typeof maxPrice === 'number' && maxPrice <= SLIDER_MAX ? maxPrice : SLIDER_MAX
  );
  const [inputMinValue, setInputMinValue] = useState<number | undefined>(minPrice);
  const [inputMaxValue, setInputMaxValue] = useState<number | undefined>(maxPrice);

  const resetToInitialValues = () => {
    setSliderMinValue(typeof minPrice === 'number' ? minPrice : SLIDER_MIN);
    setSliderMaxValue(typeof maxPrice === 'number' && maxPrice <= SLIDER_MAX ? maxPrice : SLIDER_MAX);
    setInputMinValue(minPrice);
    setInputMaxValue(maxPrice);
  };

  const handleClear = () => {
    if (!onSubmit) return;

    setSliderMinValue(SLIDER_MIN);
    setSliderMaxValue(SLIDER_MAX);
    setInputMinValue(undefined);
    setInputMaxValue(undefined);
    onSubmit({ minPrice: undefined, maxPrice: undefined });

    apiTrack('Filters Set', { minPrice: null, maxPrice: null });
  };

  if (xs && onChange) {
    return (
      <Layout>
        <Layout align="center" marginBottom={16}>
          <Icon name="dollarSign" size="md" color="gray-900" />
          <Layout align="center" marginLeft={8}>
            <Text size="h6" scale>
              {offsitesOnly ? 'Daily' : 'Monthly'} Price
            </Text>
          </Layout>
        </Layout>
        <RangeFilter
          shortLabel="Price"
          values={values}
          inputMinValue={inputMinValue}
          inputMaxValue={inputMaxValue}
          inputMax={INPUT_MAX}
          sliderMinValue={sliderMinValue}
          sliderMaxValue={sliderMaxValue}
          onSliderMinChange={value => {
            setInputMinValue(value);
            setSliderMinValue(value);
            onChange({ minPrice: value, maxPrice });
          }}
          onSliderMaxChange={value => {
            setInputMaxValue(value);
            setSliderMaxValue(value);
            onChange({ minPrice, maxPrice: value });
          }}
          onInputMinChange={value => {
            setInputMinValue(value);
            onChange({ minPrice: value, maxPrice });
          }}
          onInputMaxChange={value => {
            setInputMaxValue(value);
            onChange({ minPrice, maxPrice: value });
          }}
          sliderMin={SLIDER_MIN}
          sliderMax={SLIDER_MAX}
          inputInterval={INTERVAL}
          type="money"
        />
      </Layout>
    );
  }

  if (!onSubmit) return null;

  return (
    <RangeFilterContainer
      initialState={initialState}
      label={`${offsitesOnly ? 'Daily' : 'Monthly'} Price`}
      align={align}
      icon="dollarSign"
      shortLabel="Price"
      buttonText={buttonText}
      values={values}
      suffix={offsitesOnly ? '/day' : '/mo'}
      sliderMin={SLIDER_MIN}
      sliderMax={SLIDER_MAX}
      inputMinValue={inputMinValue}
      inputMaxValue={inputMaxValue}
      inputMax={INPUT_MAX}
      sliderMinValue={sliderMinValue}
      sliderMaxValue={sliderMaxValue}
      onInputMinChange={value => setInputMinValue(value)}
      onInputMaxChange={value => setInputMaxValue(value)}
      onSliderMinChange={value => {
        setInputMinValue(value);
        setSliderMinValue(value);
      }}
      onSliderMaxChange={value => {
        setInputMaxValue(value);
        setSliderMaxValue(value);
      }}
      maxRequired={maxPriceRequired}
      inputInterval={INTERVAL}
      type="money"
      isDefaultSelected={minPrice === undefined && maxPrice === undefined}
      onClear={maxPriceRequired ? undefined : handleClear}
      onClickOutside={resetToInitialValues}
      onSubmit={() => {
        let minPrice = inputMinValue === undefined ? undefined : inputMinValue;
        let maxPrice = inputMaxValue === undefined ? undefined : inputMaxValue;

        if (typeof minPrice === 'number' && typeof maxPrice === 'number' && maxPrice <= minPrice) {
          setInputMaxValue(undefined);
          maxPrice = undefined;
        }

        const sliderMax = maxPrice ? Math.min(maxPrice, SLIDER_MAX) : SLIDER_MAX;

        setSliderMinValue(minPrice || 0);
        setSliderMaxValue(sliderMax);

        onSubmit({ minPrice, maxPrice });

        apiTrack('Filters Set', { minPrice, maxPrice });
      }}
    />
  );
};

const generateButtonText = ({
  minPrice,
  maxPrice,
  offsitesOnly
}: {
  minPrice?: number;
  maxPrice?: number;
  offsitesOnly?: boolean;
}) => {
  const suffix = offsitesOnly ? 'day' : 'mo';

  if (typeof minPrice === 'number' && !isNaN(minPrice) && typeof maxPrice === 'number' && !isNaN(maxPrice)) {
    return `${formatMoneyK(minPrice)}-${formatMoneyK(maxPrice)} /${suffix}`;
  } else if (typeof minPrice === 'number' && !isNaN(minPrice)) {
    return `${formatMoneyK(minPrice)}+ /${suffix}`;
  } else if (typeof maxPrice === 'number' && !isNaN(maxPrice)) {
    return `${formatMoneyK(maxPrice)} /${suffix} max`;
  } else {
    return 'Price';
  }
};

export default PriceFilter;
