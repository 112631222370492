import { axios } from 'shared';
import { PaymentMethod } from '@stripe/stripe-js';

export interface CreateSetupIntentResponse {
  clientSecret: string;
}

export function createSetupIntent() {
  return axios.post<CreateSetupIntentResponse>('/v1/stripe/setup_intents');
}

export function listPaymentMethods() {
  return axios.get<PaymentMethod[]>('/v1/stripe/payment_methods');
}

export function deletePaymentMethod(id: string) {
  return axios.delete<PaymentMethod[]>(`/v1/stripe/payment_methods/${id}`);
}

export function setDefaultPaymentMethod() {
  return axios.post<PaymentMethod>('/v1/stripe/payment_methods');
}
