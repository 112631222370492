import React from 'react';
import { DateTime, MonthNumbers } from 'luxon';

import { CalendarDateTaskGroup } from 'store/CleaningService/types';
import { groupCalendarDatesIntoWeeks } from 'ux/Tenant/CleaningService/utils';

import CalendarDate from './CalendarDate';
import { makeCalendarDatesFromTaskGroups } from './utils';
import IconButton from '../IconButton';
import Layout from '../Layout';
import Text from '../Text';

interface Props {
  taskGroups: CalendarDateTaskGroup[];
  selectedMonth: number;
  setSelectedMonth: (month: MonthNumbers) => void;
}

const TaskCalendar: React.FC<Props> = ({ taskGroups, selectedMonth, setSelectedMonth }) => {
  const selectedMonthDateTime = DateTime.fromObject({ month: selectedMonth });
  const calendarDates = makeCalendarDatesFromTaskGroups({
    taskGroups,
    startDate: DateTime.fromObject({ month: selectedMonth }).startOf('month').startOf('week'),
    endDate: DateTime.fromObject({ month: selectedMonth }).endOf('month').endOf('week')
  });
  const weeks = groupCalendarDatesIntoWeeks(calendarDates);

  return (
    <Layout gap={64} flex>
      <Layout flexGrow={1}>
        <Layout align="center" gap={16}>
          <Layout align="center" gap={8}>
            <IconButton
              size="md"
              type="noBackground"
              name="leftChevron"
              onClick={() => {
                setSelectedMonth(selectedMonthDateTime.minus({ month: 1 }).month);
              }}
              disabled={selectedMonthDateTime <= DateTime.now().minus({ months: 2 })}
            />
            <IconButton
              size="md"
              type="noBackground"
              name="rightChevron"
              onClick={() => setSelectedMonth(selectedMonthDateTime.plus({ month: 1 }).month)}
              disabled={selectedMonthDateTime > DateTime.now().plus({ months: 1 })}
            />
          </Layout>
          <Text size="body-lg" color="gray-400">
            {selectedMonthDateTime.toFormat('MMMM yyyy')}
          </Text>
        </Layout>
        <Layout marginTop={24}>
          <Layout>
            {weeks.map((week, weekIndex) => (
              <Layout key={weekIndex} flex>
                {week.map(calendarDate => (
                  <Layout equalWidth key={calendarDate.date.toISO()}>
                    <CalendarDate month={selectedMonthDateTime.month} calendarDate={calendarDate} />
                  </Layout>
                ))}
              </Layout>
            ))}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default TaskCalendar;
