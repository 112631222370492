import { CompactUser, Vendor, VendorTask, VendorTaskListRow } from 'shared';
import axios from 'shared/lib/axios';
import { VendorUser } from 'types';

import { VendorTaskSchedule } from './TaskSchedules/types';

export function getVendor({ userId }: { userId: number }) {
  return axios.get<Vendor>(`/v1/users/${userId}/vendors`);
}

export function getVendorUsers({ vendorId }: { vendorId: number }) {
  return axios.get<VendorUser[]>(`/v1/vendors/${vendorId}/vendor_users`);
}

export function updateVendorUser({
  vendorId,
  deactivate,
  vendorUser: { id, user: { first, last, email, phone } = {}, is_vendor_admin }
}: {
  vendorId: number;
  deactivate?: boolean;
  vendorUser: { id: number; is_vendor_admin?: boolean } & { user?: Partial<CompactUser> };
}) {
  return axios.patch<VendorUser>(`/v1/vendors/${vendorId}/vendor_users/${id}`, {
    is_vendor_admin: is_vendor_admin,
    first,
    last,
    email,
    phone,
    deactivate
  });
}

export function inviteVendorUser({
  email,
  first,
  last,
  vendorId,
  isVendorAdmin
}: {
  email: string;
  first: string;
  last: string;
  vendorId: number;
  isVendorAdmin: boolean;
}) {
  return axios.post(`/v1/vendors/${vendorId}/vendor_users`, { email, first, last, is_vendor_admin: isVendorAdmin });
}

export function getVendorTasks() {
  return axios.get<VendorTaskListRow[]>('/v1/vendors/tasks');
}

export function getVendorTask(id: number) {
  return axios.get<VendorTask>(`/v1/vendors/tasks/${id}`);
}

export function confirmVendorTask({ taskId }: { taskId: number }) {
  return axios.post<VendorTaskListRow>(`/v1/vendors/tasks/${taskId}/confirm`);
}

export function rejectVendorTask({ taskId }: { taskId: number }) {
  return axios.post<VendorTaskListRow>(`/v1/vendors/tasks/${taskId}/reject`);
}

export function rejectVendorTaskSchedule({ taskScheduleId, vendorId }: { taskScheduleId: number; vendorId: number }) {
  return axios.post<VendorTaskSchedule>(`/v1/vendors/task_schedules/${taskScheduleId}/reject`, { vendor_id: vendorId });
}

export function getVendorTaskSchedules({ vendorId }: { vendorId: number }) {
  return axios.get<VendorTaskSchedule[]>(`/v1/vendors/task_schedules?vendor_id=${vendorId}`);
}
