import { DateTime } from 'luxon';

import { CleaningServiceCalendarDate } from 'store/CleaningService/types';

export const groupCalendarDatesIntoWeeks = (calendarDates: CleaningServiceCalendarDate[]) => {
  return calendarDates.reduce<Array<typeof calendarDates>>((acc, date, index) => {
    const weekIndex = Math.floor(index / 7);
    if (!acc[weekIndex]) {
      acc[weekIndex] = [];
    }
    acc[weekIndex].push(date);
    return acc;
  }, []);
};

export const nBusinessDaysFromStartDate = ({ startDate, nDays }: { startDate: DateTime; nDays: number }) => {
  if (nDays < 1) return startDate;

  let count = 0;
  let businessDays = [];
  let x = 0;
  while (count <= nDays) {
    const day = startDate.plus({ days: x });
    if (day.weekday !== 6 && day.weekday !== 7) {
      businessDays.push(day);
      count++;
    }
    x++;
  }
  return businessDays[businessDays.length - 1];
};
