import React, { useEffect, useRef, useState } from 'react';

import { track } from 'lib/analytics';

import Icon from '../Icon';
import Layout from '../Layout';
import Text from '../Text';

const TRANSITION_TIME = 200;

export interface Props {
  title: string;
  body: string;
}

const Item: React.FC<Props> = ({ title, body }) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [measuredHeight, setMeasuredHeight] = useState<number | null>(null);
  const [height, setHeight] = useState<number | string>('auto');

  const bodyEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bodyEl && bodyEl.current && measuredHeight === null) {
      setMeasuredHeight(bodyEl.current.clientHeight);
      setHeight(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasBeenMeasured = measuredHeight !== null;

  return (
    <Layout borderBottom borderColor="blue-200">
      <button
        onClick={() => {
          if (isTransitioning) {
            return;
          }

          track('Element Interacted', { type: 'button', name: title });

          setIsTransitioning(true);
          setTimeout(() => setIsTransitioning(false), TRANSITION_TIME);

          if (!isExpanded) {
            // expanding
            measuredHeight && setHeight(measuredHeight);
            setTimeout(() => setHeight('auto'), TRANSITION_TIME);
            setTimeout(() => {
              bodyEl?.current && setMeasuredHeight(bodyEl.current.clientHeight);
            }, TRANSITION_TIME + 40);
          } else {
            // contracting
            // set height from auto to a number so it can transition
            measuredHeight && setHeight(measuredHeight);
            // kick off the transition by setting height to 0
            setTimeout(() => setHeight(0), 10);
          }

          setExpanded(!isExpanded);
        }}
        style={{
          width: '100%',
          cursor: 'pointer',
          paddingTop: 24,
          paddingBottom: isExpanded ? 0 : 24
        }}
      >
        <Layout justify="space-between" align="center">
          <Layout>
            <Text size="headline-md" color="blue-600" align="left" scale>
              {title}
            </Text>
          </Layout>
          <Layout padding={12}>
            <Icon size={24} stroke={2} name={isExpanded ? 'upChevron' : 'downChevron'} color="blue-600" />
          </Layout>
        </Layout>
      </button>
      <div
        ref={bodyEl}
        style={{
          visibility: isExpanded || hasBeenMeasured ? undefined : 'hidden',
          overflow: hasBeenMeasured ? 'hidden' : undefined,
          transition: `height ${TRANSITION_TIME}ms ease, opacity ${TRANSITION_TIME}ms ease`,
          paddingTop: 12,
          paddingBottom: isExpanded ? 24 : 0,
          opacity: isExpanded ? 1 : 0,
          height
        }}
      >
        <Text size="body-lg" color="gray-400" scale>
          <Layout inline __className="CmsContent">
            <span dangerouslySetInnerHTML={{ __html: body }} />
          </Layout>
        </Text>
      </div>
    </Layout>
  );
};

export default Item;
