import React from 'react';

import { Button, Icon, Layout } from 'ds';
import { CUSTOMER_SERVICES_PATH } from 'routes';
import { actions as chatbotActions } from 'store/AIAssistant';
import { selectSelectedOfficeId } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CHATBOT_THREAD_VERTICAL_SPACING } from 'ux/Tenant/AIAssistant/constants';
import { ChatbotIntentProps } from 'ux/Tenant/AIAssistant/types';

import { LLMResponse } from '../LLMResponse';

interface Props extends ChatbotIntentProps {}

const Consumables: React.FC<Props> = ({ chatbotInteraction }) => {
  const { llm_response } = chatbotInteraction;
  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);

  const dispatch = useAppDispatch();

  return (
    <Layout>
      <LLMResponse llmResponse={llm_response} />
      <Layout marginTop={CHATBOT_THREAD_VERTICAL_SPACING} color="white" borderRadius={16} direction="column">
        <Layout
          borderTopRightRadius={16}
          borderTopLeftRadius={16}
          height={112}
          flexGrow={1}
          direction="row"
          justify="space-evenly"
          align="center"
          paddingX={48}
          color="blue-400"
        >
          <Icon name="beverageSupplies" size={64} color="blue-200" />
          <Icon name="coffee" size={64} color="blue-200" />
          <Icon name="kitchen" size={64} color="blue-200" />
        </Layout>
        <Layout padding={16}>
          <Button
            type="secondary"
            size="xs"
            text="View consumable supplies"
            fullWidth
            icon="externalLink"
            iconSize="xs"
            href={`${CUSTOMER_SERVICES_PATH}/${selectedOfficeId}/pantry`}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Consumables;
