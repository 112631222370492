import { useState } from 'react';

import { ComboBox } from 'ds';
import { filterByInclusion } from 'shared/filter';
import { DraftConsumablesOrderItem, DraftConsumablesProductSubscription } from 'shared/types';
import { selectProducts } from 'store/Consumables/selectors';
import { useAppSelector } from 'store/hooks';

import ConsumablesProductResult from './ConsumablesProductResult';

interface ConsumablesAddProductsComboBoxProps {
  onSelect: (result: any) => void;
  existingProductSelections: (DraftConsumablesProductSubscription | DraftConsumablesOrderItem)[];
}
const ConsumablesAddProductsComboBox = ({
  onSelect,
  existingProductSelections
}: ConsumablesAddProductsComboBoxProps) => {
  const products = useAppSelector(selectProducts);

  const [query, setQuery] = useState('');

  const resultProducts = query
    ? products
        .filter(
          product => !existingProductSelections.some(selection => selection.consumables_product.id === product.id)
        )
        .filter(
          product =>
            filterByInclusion(product.brand, query) ||
            filterByInclusion(`${product.brand} ${product.detail} ${product.unit_type}`, query) ||
            filterByInclusion(product.detail, query) ||
            filterByInclusion(product.unit_type, query) ||
            filterByInclusion(product.package_type, query) ||
            filterByInclusion((product.amount_cents / 100).toString(), query)
        )
    : products.filter(
        product => !existingProductSelections.some(selection => selection.consumables_product.id === product.id)
      );

  const results = resultProducts.map(product => ({
    value: product.id,
    element: <ConsumablesProductResult product={product} query={query} />
  }));

  return (
    <ComboBox
      query={query}
      icon="search"
      size="sm"
      maxItems={results.length}
      maxVisibleItems={8}
      results={results}
      onChange={({ value }) => {
        setQuery(value);
        onSelect(undefined);
      }}
      onSelect={value => {
        const product = products.find(product => product.id === value);
        onSelect(product);
      }}
      label="Add products"
    ></ComboBox>
  );
};

export default ConsumablesAddProductsComboBox;
