import { Layout, ProductImg, Text } from 'ds';
import { getHighlightedPartsOfText } from 'ds/utils';
import { formatUsd } from 'helpers';
import { ConsumablesProduct } from 'shared';

interface ConsumablesProductResultProps {
  product: ConsumablesProduct;
  query: string | undefined;
}
const ConsumablesProductResult = ({
  product: { detail, brand, amount_cents, unit_type, units_per_package, package_type, image_key },
  query
}: ConsumablesProductResultProps) => {
  const getHighlightedText = ({ text, query }: { text: string; query?: string }) => {
    if (!query) return text;

    const parts = getHighlightedPartsOfText({ text, query });

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? <b key={part + index}>{part}</b> : part
        )}
      </span>
    );
  };

  return (
    <Layout height={60} width="100%" flex direction="row" paddingX={16} paddingY={8} gap={12}>
      <ProductImg srcKey={image_key} style={{ width: 44, height: 44 }} size="sm" alt={detail} />

      <Layout flex direction="column" flexGrow={1} gap={4}>
        <Layout direction="row" justify="space-between" height={24}>
          <Text size="body-sm" maxLines={1}>
            {getHighlightedText({
              text: `${brand} ${detail}`,
              query
            })}
          </Text>
          <Text size="body-sm" color="gray-700">
            {formatUsd(amount_cents / 100)} ea.
          </Text>
        </Layout>
        <Layout>
          <Text size="body-xs" color="gray-400">
            {unit_type}s ({units_per_package}/{package_type})
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ConsumablesProductResult;
