import { DateTime } from 'luxon';

import axios from 'shared/lib/axios';
import {
  CalendarDateTaskGroup,
  CleaningServicePricing,
  CleaningServiceSchedule,
  OnboardExistingCleanerInquiry
} from 'store/CleaningService/types';

export function getCleaningServiceSchedules() {
  return axios.get<CleaningServiceSchedule[]>('/v1/cleaning_service/schedules');
}

export function getCleaningServiceCalendar({ startDate, endDate }: { startDate?: DateTime; endDate?: DateTime } = {}) {
  return axios.get<CalendarDateTaskGroup[]>('/v1/cleaning_service/calendar', {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  });
}

export function createCleaningService({
  numCleaningsPerWeek,
  numTouchUpsPerWeek
}: {
  numCleaningsPerWeek: number;
  numTouchUpsPerWeek: number;
}) {
  return axios.post<CleaningServiceSchedule[]>('/v1/cleaning_service', {
    num_cleanings_per_week: numCleaningsPerWeek,
    num_touch_ups_per_week: numTouchUpsPerWeek
  });
}

export function upgradeTouchUpsToCleanings({ taskIds }: { taskIds: number[] }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/upgrade_touch_ups_to_cleanings', {
    task_ids: taskIds
  });
}

export function cancelCleanings({ taskIds, date }: { taskIds: number[]; date: DateTime }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/cancel_cleanings', {
    task_ids: taskIds,
    date: date.toISODate()
  });
}

export function createCleaning({ date }: { date: DateTime }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/create_cleaning', {
    date: date.toISODate()
  });
}

export function createTouchUp({ date }: { date: DateTime }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/create_touch_up', {
    date: date.toISODate()
  });
}

export function createCleaningSchedule({ dayOfWeek, date }: { dayOfWeek: number; date: DateTime }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/create_cleaning_schedule', {
    day_of_week: dayOfWeek,
    date: date.toISODate()
  });
}

export function createTouchUpSchedule({ dayOfWeek, date }: { dayOfWeek: number; date: DateTime }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/create_touch_up_schedule', {
    day_of_week: dayOfWeek,
    date: date.toISODate()
  });
}

export function cancelSchedules({ taskScheduleIds, date }: { taskScheduleIds: number[]; date: DateTime }) {
  return axios.post<CalendarDateTaskGroup[]>('/v1/cleaning_service/cancel_schedules', {
    task_schedule_ids: taskScheduleIds,
    date: date.toISODate()
  });
}

export function getCleaningServicePricing() {
  return axios.get<CleaningServicePricing>('/v1/cleaning_service/pricing');
}

export function createOnboardExistingCleanerInquiry() {
  return axios.post<OnboardExistingCleanerInquiry>('/v1/onboard_existing_cleaner_inquiries');
}

export function getOnboardExistingCleanerInquiry() {
  return axios.get<OnboardExistingCleanerInquiry | null>('/v1/onboard_existing_cleaner_inquiries');
}
