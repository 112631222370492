import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { app } from 'context';
import { Button, Icon, IconButton, Layout, Pressable, Text } from 'ds';
import { lockScreenScroll, unlockScreenScroll } from 'ds/utils';
import { track } from 'lib/analytics';
import { NavigationMenu } from 'lib/butter/types';
import { OFFICE_REQUEST_PATH } from 'routes';
import { BLUE_50_HEX, User } from 'shared';
import { actions as authActions } from 'store/Auth';
import { useAppDispatch } from 'store/hooks';

import MenuSections from './MenuSections';

interface Props {
  menus: NavigationMenu[];
  closeMenu: VoidFunction;
  user?: User | null;
}

const PublicMenu: React.FC<Props> = ({ menus, closeMenu, user }) => {
  const { contentPaddingX, navBarHeight } = useContext(app);
  const [openMenuIndex, setOpenMenuIndex] = useState<number>(-1);
  const menuIsOpen = openMenuIndex > -1;
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    lockScreenScroll();

    return () => {
      unlockScreenScroll();
    };
  }, []);

  return (
    <>
      <Layout position="absolute" top={0} left={contentPaddingX} height={navBarHeight} justify="center" align="center">
        <IconButton name="close" onClick={closeMenu} size="sm" type="primary" stroke={2} />
      </Layout>
      <Layout
        position="absolute"
        color="white"
        width="100%"
        height="100dvh"
        __className="Navigation-mobileMenuContainer"
        overflow="auto"
        paddingTop={24}
        paddingBottom={64}
      >
        {menus.map(({ name, sections, locations, cta_section, path }, index) => {
          const isOpen = openMenuIndex === index;
          const ctaSection = cta_section?.fields;

          if (path) {
            return (
              <Pressable
                onPress={() => {
                  closeMenu();
                  history.push(path);
                }}
                fullWidth
                name="navigation menu"
                style={{ textAlign: 'left' }}
                key={name}
              >
                <Layout
                  paddingX={contentPaddingX}
                  paddingY={20}
                  __className="Navigation-mobileMenuButton"
                  __style={{ animationDelay: `${index * 30}ms` }}
                >
                  <Text size="body1" semibold color="gray-900" align="left">
                    {name}
                  </Text>
                </Layout>
              </Pressable>
            );
          }

          return (
            <React.Fragment key={name}>
              <button
                style={{
                  display: 'block',
                  width: '100%',
                  paddingLeft: contentPaddingX,
                  paddingRight: contentPaddingX,
                  backgroundColor: isOpen ? BLUE_50_HEX : undefined,
                  animationDelay: `${index * 30}ms`
                }}
                className="Navigation-mobileMenuButton"
                onClick={() => {
                  track('Element Interacted', { type: 'button', name: 'navigation menu', value: name });

                  if (isOpen) {
                    setOpenMenuIndex(-1);
                  } else {
                    setOpenMenuIndex(index);
                  }
                }}
              >
                <Layout
                  paddingY={20}
                  key={name}
                  align="center"
                  justify="space-between"
                  borderColor={isOpen ? 'transparent' : 'gray-50'}
                  borderTop
                  borderBottom={index === menus.length - 1}
                  inline
                >
                  <Text size="body1" semibold color={isOpen ? 'blue-500' : 'black'} inline>
                    {name}
                  </Text>
                  <Layout
                    __style={{
                      transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)'
                    }}
                  >
                    <Icon name="upChevron" size="xs" color="gray-600" />
                  </Layout>
                </Layout>
              </button>
              {isOpen && (
                <Layout paddingX={contentPaddingX} paddingBottom={index === menus.length - 1 ? 0 : 32}>
                  <MenuSections
                    sections={sections}
                    ctaSection={ctaSection}
                    locations={locations || []}
                    closeMenu={closeMenu}
                  />
                </Layout>
              )}
            </React.Fragment>
          );
        })}
        <Layout marginTop={36} paddingX={contentPaddingX}>
          <Layout __className="Navigation-mobileMenuButton" __style={{ animationDelay: `${menus.length * 30}ms` }}>
            <Button text="Get started" size="md" type="primary" href={OFFICE_REQUEST_PATH} fullWidth />
          </Layout>
        </Layout>
        {!user && (
          <Layout marginTop={12} paddingX={contentPaddingX} marginBottom={menuIsOpen ? 48 : 24}>
            <Layout
              __className="Navigation-mobileMenuButton"
              __style={{ animationDelay: `${(menus.length - 1) * 30}ms` }}
            >
              <Button
                text="Sign in"
                size="md"
                type="secondaryOutline"
                href="/sign-in"
                onClick={() => {
                  dispatch(authActions.setAuthFlowType('default'));
                  closeMenu();
                }}
                fullWidth
              />
            </Layout>
          </Layout>
        )}
      </Layout>
    </>
  );
};

export default PublicMenu;
