import React from 'react';
import { DateTime } from 'luxon';

import Layout, { Props as LayoutProps } from 'ds/Layout';
import Text from 'ds/Text';
import { CleaningServiceCalendarDate } from 'store/CleaningService/types';

import CalendarDateTaskGroupDisplay from './CalendarDateTaskGroupDisplay';

interface Props {
  calendarDate: CleaningServiceCalendarDate;
  month: number;
  roundTopLeft?: boolean;
  roundTopRight?: boolean;
  roundBottomLeft?: boolean;
  roundBottomRight?: boolean;
}

const CalendarDate: React.FC<Props> = ({
  calendarDate: { date, summary, tasks },
  month,
  roundTopLeft,
  roundTopRight,
  roundBottomLeft,
  roundBottomRight
}) => {
  const props: LayoutProps = {};
  const isFirstOfWeek = date.weekday === 1;
  const isLastOfWeek = date.weekday === 7;
  const isFirstWeekOfMonth = date.weekNumber === DateTime.fromObject({ month }).startOf('month').weekNumber;
  const isLastWeekOfMonth = date.weekNumber === DateTime.fromObject({ month }).endOf('month').weekNumber;
  const dateInMonth = date.month === month;
  const dateHasTask = summary && tasks.length;
  const dateIsToday = date.startOf('day').equals(DateTime.now().startOf('day'));

  if ((isFirstOfWeek && isFirstWeekOfMonth) || roundTopLeft) {
    props.borderTopLeftRadius = 16;
  }

  if ((isLastOfWeek && isFirstWeekOfMonth) || roundTopRight) {
    props.borderTopRightRadius = 16;
  }

  if ((isFirstOfWeek && isLastWeekOfMonth) || roundBottomLeft) {
    props.borderBottomLeftRadius = 16;
  }

  if ((isLastOfWeek && isLastWeekOfMonth) || roundBottomRight) {
    props.borderBottomRightRadius = 16;
  }

  return (
    <Layout {...props} borderColor="gray-50" borderWidth={1} padding={4} className="CleaningServiceCalendarDateDisplay">
      {isFirstWeekOfMonth && (
        <Layout justify="center">
          <Layout>
            <Text size="body-xs">{date.weekdayShort}</Text>
          </Layout>
        </Layout>
      )}
      <Layout height={96} paddingTop={4} direction="column" align="center">
        <Layout
          {...(dateIsToday ? { color: 'blue-500' } : {})}
          height={20}
          width={32}
          borderRadius={1_000}
          align="center"
          justify="center"
          display="inline-flex"
        >
          <Text
            size="body-xs"
            color={dateIsToday ? 'white' : dateInMonth ? 'gray-900' : 'gray-400'}
            align="center"
            semibold
          >
            {date.day}
          </Text>
        </Layout>
        {dateHasTask ? (
          <Layout marginTop={16} width="100%">
            <CalendarDateTaskGroupDisplay summary={summary} />
          </Layout>
        ) : null}
      </Layout>
    </Layout>
  );
};

export default CalendarDate;
