import { createSlice } from '@reduxjs/toolkit';

import { ProductRentalCart, ProductSet, ProductSetRentalInventory, ProductSetRentalOrder } from 'shared';

const initialState: FurnitureAndEquipmentState = {
  productSets: null,
  productRentalCart: null,
  productSetRentalOrders: null,
  productSetRentalInventory: null
};

export type FurnitureAndEquipmentState = {
  productSets: ProductSet[] | null;
  productRentalCart: ProductRentalCart | null;
  productSetRentalOrders: ProductSetRentalOrder[] | null;
  productSetRentalInventory: ProductSetRentalInventory | null;
};

const furnitureAndEquipmentSlice = createSlice({
  name: 'FurnitureAndEquipment',
  initialState,
  reducers: {
    setProductSets: (state, action) => {
      state.productSets = action.payload;
    },
    setProductRentalCart: (state, action) => {
      state.productRentalCart = action.payload;
    },
    addProductRentalCartItem: (state, action) => {
      if (!state.productRentalCart) return;

      state.productRentalCart.items.push(action.payload);
    },
    updateProductRentalCartItem: (state, action) => {
      if (!state.productRentalCart) return;

      state.productRentalCart.items = state.productRentalCart.items.map(item =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    removeProductRentalCartItem: (state, action) => {
      if (!state.productRentalCart) return;

      state.productRentalCart.items = state.productRentalCart.items.filter(item => item.id !== action.payload.id);
    },
    setProductSetRentalOrders: (state, action) => {
      state.productSetRentalOrders = action.payload;
    },
    addProductSetRentalOrder: (state, action) => {
      if (!state.productSetRentalOrders) return;

      state.productSetRentalOrders.unshift(action.payload);
    },
    setProductSetRentalInventory: (state, action) => {
      state.productSetRentalInventory = action.payload;
    },
    addProductSetRentalInventoryItems: (state, action) => {
      if (!state.productSetRentalInventory) return;

      const inventory = state.productSetRentalInventory;

      action.payload.forEach((item: { product_set: ProductSet; quantity: number }) => {
        // if the product set is already in the inventory, increment the quantity
        if (item.product_set.id in inventory) {
          inventory[item.product_set.id].quantity += item.quantity;
        } else {
          inventory[item.product_set.id] = {
            product_set: item.product_set,
            quantity: item.quantity
          };
        }
      });
    },
    setHasGivenFeedback: (state, action) => {
      if (!state.productSetRentalOrders) return;
      const order = state.productSetRentalOrders.find(order => order.id === action.payload.product_set_rental_order_id);
      if (!order) return;

      const group = order.product_set_rental_groups.find(group => group.id === action.payload.id);
      if (!group) return;

      group.has_given_feedback = true;
    },
    setHasGivenFurniturePurchaseInquiry: (state, action) => {
      if (!state.productSets) return;

      const productSet = state.productSets.find(set => set.id === action.payload.id);
      if (!productSet) return;

      productSet.has_given_furniture_purchase_inquiry = true;
    }
  }
});

export const { actions } = furnitureAndEquipmentSlice;
export default furnitureAndEquipmentSlice.reducer;
