import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_READY, REQUEST_SUCCEEDED } from 'helpers/constants';
import { validBounds } from 'helpers/map';

import { app, media } from 'context';
import { HEADER_Z_INDEX, Icon, Layout, Pressable, Text } from 'ds';
import { OnLocationChangeParams } from 'ds/inputs/Location/LocationInput';
import { OnClusterClick, OnMapChange, OnMarkerClick } from 'ds/map/types';
import { RequestState } from 'ds/types';
import { apiTrack } from 'lib/analytics';
import { Bounds, LatLng, searchWorkspaces } from 'shared';
import { actions } from 'store/Search';
import { selectSearchWorkspaces } from 'store/Search/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import MapView from './MapView';
import TopBar from './TopBar';
import WorkspaceList from './WorkspaceList';
import { FILTER_BAR_HEIGHT, FILTER_BAR_HEIGHT_STACKED, LIST_MAP_SPACE } from './constants';
import { SearchFilters, SearchSortLabel } from './types';
import { filterWorkspaces, generateSearch, getWorkspaceListWidth, sortWorkspaces } from './utils';

interface Props extends SearchFilters {
  center: LatLng;
  zoom: number;
  location: string;
  bounds?: Bounds;
  showMap: boolean;
  selectedWorkspaceId: number | null;
  sortLabel: SearchSortLabel;
}

const UI: React.FC<Props> = ({
  center,
  zoom,
  bounds,
  location,
  selectedWorkspaceId,
  daysPerWeek = 5,
  minPrice,
  maxPrice,
  minSqft,
  maxSqft,
  offsitesOnly,
  sortLabel,
  numMeetingRooms,
  ...props
}) => {
  const [locationSearchType, setLocationSearchType] = useState<'place' | 'street address'>('place');
  const allWorkspaces = useAppSelector(selectSearchWorkspaces);
  const filteredWorkspaces = filterWorkspaces({
    workspaces: allWorkspaces,
    filters: { daysPerWeek, minPrice, maxPrice, minSqft, maxSqft, numMeetingRooms, offsitesOnly }
  });
  const workspaces = sortWorkspaces({
    workspaces: filteredWorkspaces,
    sortLabel
  });
  const mediaContext = useContext(media);
  const { xs, lg } = mediaContext;
  const { windowHeight, navBarHeight, width, contentPaddingX } = useContext(app);
  const splitMapView = lg;
  const splitViewMapWidth = `calc(100% - ${getWorkspaceListWidth(mediaContext) + LIST_MAP_SPACE + contentPaddingX}px)`;
  const showMap = lg || props.showMap;
  const mapRef = useRef<google.maps.Map | null>(null);
  const history = useHistory();
  const { search } = useLocation();
  const filterBarHeight = xs ? FILTER_BAR_HEIGHT_STACKED : FILTER_BAR_HEIGHT;

  const mapHeight = `calc(100vh - ${navBarHeight + filterBarHeight}px)`;
  const [requestState, setRequestState] = useState<RequestState>(REQUEST_READY);

  const { lat: centerLat, lng: centerLng } = center;
  const {
    ne: { lat: neLat, lng: neLng },
    sw: { lat: swLat, lng: swLng }
  } = bounds || { ne: {}, sw: {} };
  const dispatch = useAppDispatch();

  const setSelectedWorkspaceId = (id: number | null) => {
    history.replace({ search: generateSearch({ search: history.location.search, selectedWorkspaceId: id }) });
  };

  const onLocationChange = ({ location, place }: OnLocationChangeParams) => {
    if (!place) return;
    const map = mapRef.current;

    if (!map) return;

    const { geometry, address_components } = place;
    const viewPort = geometry?.viewport;
    if (!viewPort) return;

    const bounds = geometry
      ? { ne: viewPort.getNorthEast().toJSON(), sw: viewPort.getSouthWest().toJSON() }
      : undefined;

    const hasStreetComponent = !!(address_components && address_components.find(c => c.types.includes('route')));
    const zoom = hasStreetComponent ? 14 : undefined;

    setLocationSearchType(!!hasStreetComponent ? 'street address' : 'place');

    if (hasStreetComponent && geometry) {
      zoom && map.setZoom(zoom);
      map.setValues({ center: geometry.location, zoom });
    } else if (!!bounds) {
      map.fitBounds({
        east: bounds.ne.lng,
        west: bounds.sw.lng,
        north: bounds.ne.lat,
        south: bounds.sw.lat
      });
    }

    history.push({ search: generateSearch({ search: history.location.search, location, zoom }) });
  };

  useEffect(() => {
    const bounds =
      neLat && neLng && swLat && swLng ? { ne: { lat: neLat, lng: neLng }, sw: { lat: swLat, lng: swLng } } : undefined;

    if (!centerLat || !centerLng || !bounds) return;

    setRequestState(REQUEST_IN_PROGRESS);

    searchWorkspaces({
      bounds,
      center: { lat: centerLat, lng: centerLng }
    })
      .then(({ data }) => {
        apiTrack('Location Searched', {
          value: location,
          type: locationSearchType,
          results: data.length
        });

        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }

        dispatch(actions.setWorkspaces(data));
        setRequestState(REQUEST_SUCCEEDED);
      })
      .catch(() => {
        dispatch(actions.setWorkspaces([]));
        setRequestState(REQUEST_FAILED);
      });
  }, [centerLat, centerLng, dispatch, neLat, neLng, swLat, swLng]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePriceClick: OnMarkerClick = ({ workspace: { id } }) => {
    setSelectedWorkspaceId(id);
  };

  const handleClusterClick: OnClusterClick = ({ map, workspaces }) => {
    if (workspaces.length === 1) {
      const workspace = workspaces[0];
      const { lat, lon: lng } = workspace.address;
      map.setValues({ center: { lat, lng }, zoom: 14 });
      setSelectedWorkspaceId(workspace.id);
      return;
    }
    const bounds = new google.maps.LatLngBounds();

    workspaces.forEach(({ address: { lat, lon: lng } }) => {
      bounds.extend({ lat, lng });
    });

    map.fitBounds(bounds, 100);
  };

  const handleMapChange: OnMapChange = ({ zoom: updatedZoom, ...params }) => {
    const newCenter = params.center;
    // const wasDragged = JSON.stringify(center) !== JSON.stringify(newCenter);
    // setMapChangeOrdinal(mapChangeOrdinal + 1);

    const newBounds = { ne: params.bounds.ne, sw: params.bounds.sw };
    // const location = wasDragged ? 'Map area' : undefined;

    const updatedSearch = validBounds(newBounds)
      ? generateSearch({ search, bounds: newBounds, center: newCenter, zoom: updatedZoom })
      : generateSearch({ search, center: newCenter, zoom: updatedZoom });

    history.replace({ search: updatedSearch });
  };

  return (
    <Layout position="relative" color="white">
      <TopBar
        location={location}
        onLocationChange={onLocationChange}
        daysPerWeek={daysPerWeek}
        minPrice={minPrice}
        maxPrice={maxPrice}
        minSqft={minSqft}
        maxSqft={maxSqft}
        numMeetingRooms={numMeetingRooms}
        offsitesOnly={offsitesOnly}
      />
      <Layout
        justify="space-between"
        {...(!splitMapView && !showMap
          ? { minHeight: `calc(100vh - ${navBarHeight + filterBarHeight}px)` }
          : undefined)}
        flex
      >
        {(splitMapView || !showMap) && (
          <Layout flexGrow={1} minWidth={0}>
            <WorkspaceList
              daysPerWeek={daysPerWeek}
              workspaces={workspaces}
              fullWidth={!showMap || !splitMapView}
              requestState={requestState}
              sortLabel={sortLabel}
              offsitesOnly={!!offsitesOnly}
            />
          </Layout>
        )}
        {splitMapView ? (
          <Layout
            position={showMap ? 'relative' : 'absolute'}
            width={splitViewMapWidth}
            zIndex={showMap ? 5 : -1}
            flexShrink={0}
          >
            <Layout
              height={`calc(100vh - ${navBarHeight + filterBarHeight}px)`}
              position="sticky"
              top={navBarHeight + filterBarHeight}
            >
              <MapView
                mapRef={mapRef}
                splitMapView={splitMapView}
                setSelectedWorkspaceId={setSelectedWorkspaceId}
                selectedWorkspaceId={selectedWorkspaceId}
                workspaces={workspaces}
                center={center}
                zoom={zoom}
                bounds={bounds}
                daysPerWeek={daysPerWeek}
                offsitesOnly={!!offsitesOnly}
                onMarkerClick={handlePriceClick}
                onClusterClick={handleClusterClick}
                onChange={handleMapChange}
              />
            </Layout>
          </Layout>
        ) : (
          <>
            <Layout
              height={mapHeight}
              __style={{ width: showMap ? '100vw' : width }}
              position={showMap ? 'fixed' : 'absolute'}
              top={navBarHeight + filterBarHeight}
              opacity={showMap ? 1 : 0}
              zIndex={showMap ? HEADER_Z_INDEX - 7 : -1}
              color="white"
            >
              <MapView
                mapRef={mapRef}
                splitMapView={splitMapView}
                selectedWorkspaceId={selectedWorkspaceId}
                setSelectedWorkspaceId={setSelectedWorkspaceId}
                workspaces={workspaces}
                center={center}
                zoom={zoom}
                bounds={bounds}
                daysPerWeek={daysPerWeek}
                offsitesOnly={!!offsitesOnly}
                onMarkerClick={handlePriceClick}
                onClusterClick={handleClusterClick}
                onChange={handleMapChange}
              />
            </Layout>
            <Layout
              top={windowHeight - 60}
              width={width}
              justify="center"
              height={0}
              zIndex={HEADER_Z_INDEX - 1}
              position="fixed"
            >
              <Pressable
                onPress={() => {
                  history.replace({ search: generateSearch({ search, map: showMap ? 'hide' : 'show' }) });
                }}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  height: 48,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderRadius: 100,
                  boxShadow: '0px 2px 4px 0px #00000021'
                }}
                color="white"
                activeColor="gray-100"
                hoverColor="gray-50"
              >
                <Text size="body1">Show {showMap ? 'list' : 'map'}</Text>
                <Layout marginLeft={8} display="inline-flex">
                  <Icon size="md" name={showMap ? 'list' : 'map'} color="gray-900" />
                </Layout>
              </Pressable>
            </Layout>
          </>
        )}
      </Layout>
    </Layout>
  );
};

export default UI;
