import React from 'react';
import classNames from 'classnames';

import { Color } from 'shared';

import { Size } from './Text';
import { DS_CLASS_NAME } from './constants';

interface Props {
  value?: string | null;
  color?: Color;
  textSize?: Size;
  maxLines?: 1 | 2 | 3 | 4;
}

const RichTextDisplay: React.FC<Props> = ({ value, textSize, color, maxLines }) => {
  if (value === null || value === undefined) return null;

  const classNameList = ['RichTextDisplay ql-editor', DS_CLASS_NAME];

  if (maxLines) {
    classNameList.push('is-max-lines', `max-line-count-${maxLines}`);
  }

  if (color) {
    classNameList.push(`is-color-${color}`);
  }

  if (textSize) {
    classNameList.push(`is-${textSize}`);
  }

  return <div className={classNames(classNameList)} dangerouslySetInnerHTML={{ __html: value }}></div>;
};

export default RichTextDisplay;
