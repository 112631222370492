import React from 'react';

import ButtonPrivate, { Props as ButtonPrivateProps } from './ButtonPrivate';
import { BUTTON_TYPE_CONFIG } from './constants';
import { ButtonBorderRadius, ButtonType } from './types';

export interface Props
  extends Pick<
    ButtonPrivateProps,
    | 'onClick'
    | 'disabled'
    | 'icon'
    | 'iconPosition'
    | 'iconStroke'
    | 'showSpinner'
    | 'tabIndex'
    | 'htmlType'
    | '__ref'
    | 'text'
    | 'href'
    | 'search'
    | 'textSize'
    | 'fullWidth'
    | 'iconSize'
  > {
  type: ButtonType;
  size?: 'sm' | 'md' | 'lg';
  borderRadius?: ButtonBorderRadius;
}

const CONFIG = {
  sm: {
    iconSize: 'xs',
    size: 'pill-sm',
    borderRadius: 16
  },
  md: {
    iconSize: 'sm',
    size: 'pill-md',
    borderRadius: 16
  },
  lg: {
    iconSize: 'sm',
    size: 'pill-lg',
    borderRadius: 40
  }
} as const;

const PillButton: React.FC<Props> = ({ type, size = 'sm', ...props }) => {
  const config = { ...BUTTON_TYPE_CONFIG[type] };
  const { iconSize, size: buttonSize, borderRadius } = CONFIG[size];

  return (
    <ButtonPrivate
      borderRadius={borderRadius}
      size={buttonSize}
      iconGutter={4}
      {...props}
      {...config}
      iconSize={iconSize}
    />
  );
};

export default PillButton;
