import React, { useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { app, media } from 'context';
import { Button, HEADER_Z_INDEX, Icon, Layout, Link, Text } from 'ds';
import { OnClusterClick, OnMapChange, OnMarkerClick } from 'ds/map/types';
import { OFFICE_REQUEST_PATH, SEARCH_PATH } from 'routes';
import { Bounds, LatLng, Listing } from 'shared';
import { selectMarkets } from 'store/App/selectors';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import HeaderTags from './HeaderTags';
import MarketNavigationTree from './MarketNavigationTree';
import SeoContent from './SeoContent';
import { SearchLocation, SearchState } from './types';
import { officeRequestLocationFromSearchLocation, transformStateToSearch } from './utils';
import MapView from '../MapView';
import NoResults from '../NoResults';
import RequestOfficePrompt from '../RequestOfficePrompt';
import WorkspaceListCard from '../WorkspaceListCard';
import { LIST_MAP_SPACE, SEARCH_WORKSPACE_CARD_PADDING_Y } from '../constants';
import { getWorkspaceListWidth } from '../utils';

interface Props {
  center: LatLng;
  zoom: number;
  selectedWorkspaceId: number | null;
  setSearchState: (state: SearchState) => void;
  searchLocation: SearchLocation;
  workspaces: Listing[];
  bounds: Bounds;
}

const UI: React.FC<Props> = ({
  center,
  zoom,
  searchLocation,
  workspaces,
  setSearchState,
  selectedWorkspaceId,
  bounds
}) => {
  const mediaContext = useContext(media);
  const { sm, md, lg } = mediaContext;
  const { navBarHeight, contentPaddingX } = useContext(app);
  const splitMapView = lg;
  const splitViewMapWidth = `calc(100% - ${getWorkspaceListWidth(mediaContext) + LIST_MAP_SPACE + contentPaddingX}px)`;
  const mapRef = useRef<google.maps.Map | null>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const markets = useAppSelector(selectMarkets);
  const market = markets.find(
    m => m.name === (searchLocation.type === 'Market' ? searchLocation.name : searchLocation.market?.name)
  )!;

  const setSelectedWorkspaceId = (id: number | null) => {
    const searchState = {
      center,
      zoom,
      bounds
    };
    setSearchState({ ...searchState, selectedWorkspaceId: id });
  };

  const handlePriceClick: OnMarkerClick = ({ workspace: { id } }) => {
    setSelectedWorkspaceId(id);
  };

  const paddingLeft = contentPaddingX;
  const paddingRight = LIST_MAP_SPACE;

  const listWorkspaces = workspaces.slice(0, 5);

  const handleClusterClick: OnClusterClick = ({ map, workspaces }) => {
    if (workspaces.length === 1) {
      const workspace = workspaces[0];
      const { lat, lon: lng } = workspace.address;
      map.setValues({ center: { lat, lng }, zoom: 14 });
      setSelectedWorkspaceId(workspace.id);
      return;
    }
    const bounds = new google.maps.LatLngBounds();

    workspaces.forEach(({ address: { lat, lon: lng } }) => {
      bounds.extend({ lat, lng });
    });

    map.fitBounds(bounds, 100);
  };

  const handleMapChange: OnMapChange = ({ zoom }) => {
    setSearchState({ selectedWorkspaceId, bounds, center, zoom });
  };

  const requestAnOffice = () => {
    dispatch(officeRequestActions.clearLocations());
    dispatch(officeRequestActions.setLaunchPath(location.pathname));
    dispatch(officeRequestActions.setMarket(market.name));
    officeRequestLocationFromSearchLocation({
      searchLocation,
      market
    }).then(officeRequestLocation => {
      if (!!officeRequestLocation) dispatch(officeRequestActions.addLocation(officeRequestLocation));
      history.push(OFFICE_REQUEST_PATH);
    });
  };

  return (
    <Layout position="relative" color="white">
      <Layout justify="space-between" flex>
        <Layout flexGrow={1} minWidth={0}>
          <StickyHeader top={navBarHeight} zIndex={HEADER_Z_INDEX - 5}>
            <Layout
              paddingLeft={paddingLeft}
              paddingRight={paddingRight}
              justify="space-between"
              paddingY={8}
              align="center"
              color="white"
            >
              <HeaderTags numResults={workspaces.length} searchLocation={searchLocation} />
              <Button
                size="xs"
                text="Browse"
                href={SEARCH_PATH}
                search={transformStateToSearch({ center, zoom, location: searchLocation })}
                type="primary"
              />
            </Layout>
          </StickyHeader>
          <Layout paddingLeft={paddingLeft} paddingRight={paddingRight} paddingBottom={40} flexGrow={1}>
            {listWorkspaces.map((ws, index) => {
              return (
                <React.Fragment key={ws.id}>
                  <Layout paddingY={SEARCH_WORKSPACE_CARD_PADDING_Y} borderTop={index !== 0} key={ws.id}>
                    <WorkspaceListCard workspace={ws} daysPerWeek={5} offsitesOnly={false} />
                  </Layout>
                  {index === 0 && (
                    <Layout paddingY={SEARCH_WORKSPACE_CARD_PADDING_Y} borderTop>
                      <RequestOfficePrompt onClick={requestAnOffice} />
                    </Layout>
                  )}
                </React.Fragment>
              );
            })}
            {workspaces.length > listWorkspaces.length && (
              <Layout paddingTop={40} width="100%" justify="flex-end">
                <Link
                  href={SEARCH_PATH}
                  search={transformStateToSearch({ center, zoom, location: searchLocation })}
                  text={
                    <Layout direction="row" align="center">
                      <Text size="body2">
                        View all {workspaces.length} offices in {searchLocation.name}
                      </Text>
                      <Layout marginLeft={4} />
                      <Icon name="rightArrow" size="xs" color="blue-500" />
                    </Layout>
                  }
                />
              </Layout>
            )}
            {!listWorkspaces.length && (
              <Layout marginTop={64}>
                <NoResults searchLocation={searchLocation} market={market} />
              </Layout>
            )}
          </Layout>
        </Layout>
        {!sm && !md && (
          <Layout position="relative" width={splitViewMapWidth} zIndex={5} flexShrink={0}>
            <a href={`${SEARCH_PATH}?${transformStateToSearch({ center, zoom, location: searchLocation })}`}>
              <Layout
                height={`calc(100vh - ${navBarHeight}px)`}
                position="sticky"
                top={navBarHeight}
                __style={{ pointerEvents: 'none' }}
              >
                <MapView
                  mapRef={mapRef}
                  splitMapView={splitMapView}
                  setSelectedWorkspaceId={setSelectedWorkspaceId}
                  onMarkerClick={handlePriceClick}
                  selectedWorkspaceId={selectedWorkspaceId}
                  onGoogleApiLoaded={({ map }) => {
                    if (workspaces.length < 5) return;

                    const bounds = new google.maps.LatLngBounds();

                    workspaces.forEach(({ address: { lat, lon } }) => {
                      bounds.extend({ lat, lng: lon });
                    });

                    map.fitBounds(bounds, 100);
                  }}
                  bounds={bounds}
                  onClusterClick={handleClusterClick}
                  workspaces={workspaces}
                  center={center}
                  zoom={zoom}
                  daysPerWeek={5}
                  offsitesOnly={false}
                  onChange={handleMapChange}
                  updateSearch={false}
                  options={{ zoomControl: false }}
                />
              </Layout>
            </a>
          </Layout>
        )}
      </Layout>
      <SeoContent searchLocation={searchLocation} />
      <Layout direction="row" justify="space-between" flexGrow={1} align="flex-start">
        <MarketNavigationTree market={searchLocation.type === 'Market' ? searchLocation : searchLocation.market!} />
      </Layout>
    </Layout>
  );
};

export default UI;
