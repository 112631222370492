import React from 'react';

import Img, { Props as ImgProps } from './Img';
import { OnMeasure } from './types';

import defaultWorkspaceImage from './DefaultWorkspace.svg';

interface Props extends Omit<ImgProps, 'srcKey' | 'width' | 'height' | 'alt'> {
  size: 'tiny' | 'thumbnail' | 'sm' | 'lg' | 'hero';
  onMeasure?: OnMeasure;
  alt?: string;
  folder?: string;
  srcKey?: string | null;
}

const SIZE_CONFIG = {
  tiny: { width: 24, height: 24 },
  thumbnail: { width: 320, height: 320 },
  sm: { width: 600, height: 400 },
  lg: { width: 1200, height: 800 },
  hero: { width: 3000, height: 1080 }
} as const;

const WorkspaceImg: React.FC<Props> = ({ size, alt = 'workspace', srcKey, ...props }) => {
  return srcKey ? (
    <Img alt={alt} {...props} {...SIZE_CONFIG[size]} srcKey={srcKey} />
  ) : (
    <img src={defaultWorkspaceImage} alt="default" {...props} />
  );
};

export default WorkspaceImg;
