import { V2 } from 'shared';

export const isCustomerUser = (userV2: V2.User) => {
  const customerUserTypes: V2.UserTypeName[] = ['plan_admin', 'plan_employee', 'tenant_admin', 'tenant_employee'];
  return userV2.user_types.map(t => t.name).some(t => customerUserTypes.includes(t));
};

export const isVendorUser = (userV2: V2.User) => {
  const vendorUserTypes: V2.UserTypeName[] = ['vendor_admin', 'vendor_employee'];
  return userV2.user_types.map(t => t.name).some(t => vendorUserTypes.includes(t));
};

export const isTenantUser = (userV2: V2.User) => {
  const tenantUserTypes: V2.UserTypeName[] = ['tenant_employee', 'tenant_admin'];
  return userV2.user_types.map(t => t.name).some(t => tenantUserTypes.includes(t));
};

export const isTenantAdminUser = (userV2: V2.User) => {
  const tenantAdminTypes: V2.UserTypeName[] = ['tenant_admin'];
  return userV2.user_types.map(t => t.name).some(t => tenantAdminTypes.includes(t));
};
