import { HELP_URL } from 'helpers/constants';

import { NavItem } from 'ds/types';
import {
  ACCOUNT_PATH,
  ACCOUNT_PROFILE_PATH,
  ADMIN_BUILDINGS_PATH,
  ADMIN_CUSTOMERS_PATH,
  ADMIN_LICENSORS_PATH,
  ADMIN_MAP_PATH,
  ADMIN_PATH,
  ADMIN_PAYOUTS_PATH,
  ADMIN_PRODUCTS_PATH,
  ADMIN_SHORT_TERM_BOOKINGS_PATH,
  ADMIN_SUPPORT_PATH,
  ADMIN_TASKS_PATH,
  ADMIN_TENANTS_PATH,
  ADMIN_TOOLS_PATH,
  ADMIN_USERS_PATH,
  ADMIN_VENDORS_PATH,
  ADMIN_WORKSPACES_PATH,
  AI_ASSISTANT_PATH,
  CLEANING_SERVICE_PATH,
  CUSTOMER_ADD_FURNITURE_SLUG_PATH,
  CUSTOMER_CLEANING_SLUG_PATH,
  CUSTOMER_FURNITURE_SLUG_PATH,
  CUSTOMER_OFFICE_REQUEST_PATH,
  CUSTOMER_OFFSITES_PATH,
  CUSTOMER_ORDERS_PATH,
  CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH,
  CUSTOMER_SERVICES_PATH,
  CUSTOMER_SUPPORT_PATH,
  DASHBOARD_PATH,
  FURNITURE_GET_STARTED_PATH,
  FURNITURE_PATH,
  HOME_PATH,
  MEMBERSHIP_PATH,
  NOTIFICATION_PREFERENCES_PATH,
  OFFICE_REQUEST_PATH,
  PANTRY_PATH,
  PAYMENTS_PATH,
  PRIVACY_PATH,
  PROPERTIES_ACCOUNTS_PATH,
  PROPERTIES_LISTINGS_PATH,
  PROPERTIES_PATH,
  PROPERTIES_PAYOUTS_PATH,
  REFERRALS_PATH,
  ROOT_PATH,
  TENANT_ADMIN_PATH,
  TENANT_PAYMENT_METHODS_PATH,
  TERMS_PATH,
  VENDOR_TASKS_PATH,
  VENDOR_TASK_SCHEDULES_PATH,
  VENDOR_TEAM_PATH
} from 'routes';

export const TRANSITION_DELAY = 60;
export const TRANSITION_OFFSET = 35;
export const MENU_PADDING_Y = 32;

export const ACCOUNT_NAV_ITEM: NavItem = { href: ACCOUNT_PATH, label: 'Account', key: 'Account', iconName: 'avatar' };
export const MEMBERSHIP_NAV_ITEM: NavItem = {
  href: MEMBERSHIP_PATH,
  label: 'Membership',
  key: 'Membership',
  iconName: 'starOutline',
  matchHrefs: [{ href: PAYMENTS_PATH }]
};

export const ACCOUNT_NAV_ITEMS: NavItem[] = [
  { href: ACCOUNT_PROFILE_PATH, label: 'Profile', key: 'Profile', iconName: 'avatar' },
  { href: NOTIFICATION_PREFERENCES_PATH, label: 'Notifications', key: 'Notifications', iconName: 'notification' }
];

export const RESOURCE_NAV_ITEMS: NavItem[] = [
  { href: HELP_URL, label: 'Help center', key: 'Help Center', iconName: 'support', target: '_blank' },
  { href: TERMS_PATH, label: 'Terms & conditions', key: 'Terms & Conditions', iconName: 'page', target: '_blank' },
  { href: PRIVACY_PATH, label: 'Privacy policy', key: 'Privacy Policy', iconName: 'privacy', target: '_blank' }
];

export const ADMIN_TOOL_ITEMS: NavItem[] = [
  {
    href: ADMIN_SHORT_TERM_BOOKINGS_PATH,
    label: 'Short-term bookings',
    key: 'Short-term Bookings',
    iconName: 'calendar'
  },
  { href: ADMIN_PAYOUTS_PATH, label: 'Payouts', key: 'Payouts', iconName: 'payout' },
  { href: ADMIN_TASKS_PATH, label: 'Tasks', key: 'Tasks', iconName: 'list' },
  { href: ADMIN_PRODUCTS_PATH, label: 'Catalog', key: 'Catalog', iconName: 'chair' },
  { href: ADMIN_MAP_PATH, label: 'Map', key: 'Map', iconName: 'map' },
  { href: ADMIN_TOOLS_PATH, label: 'Tools', key: 'Tools', iconName: 'settings' }
];

export const ADMIN_PORTAL_NAV_ITEM: NavItem = {
  href: ADMIN_PATH,
  label: 'Admin portal',
  key: 'Admin Portal',
  iconName: 'membership'
};
export const PUBLIC_SITE_NAV_ITEM: NavItem = {
  href: ROOT_PATH,
  label: 'Codi.com',
  key: 'Codi.com',
  iconName: 'monitor',
  target: '_blank',
  exact: true
};

export const VENDOR_TASKS_ITEM: NavItem = {
  href: VENDOR_TASKS_PATH,
  label: 'Tasks',
  key: 'Tasks',
  iconName: 'list'
};

export const VENDOR_TEAM_NAV_ITEM: NavItem = {
  href: VENDOR_TEAM_PATH,
  label: 'Team',
  key: 'Team',
  iconName: 'users'
};

export const VENDOR_SCHEDULES_NAV_ITEM: NavItem = {
  href: VENDOR_TASK_SCHEDULES_PATH,
  label: 'Schedules',
  key: 'Schedules',
  iconName: 'calendar'
};

export const DASHBOARD_NAV_ITEM: NavItem = {
  href: DASHBOARD_PATH,
  label: 'Home',
  key: 'Home',
  iconName: 'house'
};

export const HOME_NAV_ITEM: NavItem = {
  href: HOME_PATH,
  label: 'Home',
  key: 'Home',
  iconName: 'house'
};

export const PANTRY_AND_SUPPLIES_NAV_ITEM: NavItem = {
  href: PANTRY_PATH,
  label: 'Pantry & supplies',
  key: 'Pantry & Supplies',
  iconName: 'beverageSupplies',
  mobileLabel: 'Supplies'
};

export const FURNITURE_AND_EQUIPMENT_NAV_ITEM: NavItem = {
  href: FURNITURE_PATH,
  label: 'Furniture & equipment',
  key: 'Furniture & equipment',
  iconName: 'comfortable_chairs',
  mobileLabel: 'Furniture',
  matchHrefs: [{ href: FURNITURE_GET_STARTED_PATH }]
};

export const CLEANING_SERVICE_NAV_ITEM: NavItem = {
  href: CLEANING_SERVICE_PATH,
  label: 'Cleaning service',
  key: 'Cleaning Service',
  iconName: 'featherDuster',
  mobileLabel: 'Cleaning'
};

export const TENANT_ADMIN_NAV_ITEM: NavItem = {
  href: TENANT_ADMIN_PATH,
  label: 'Admin',
  key: 'Admin',
  iconName: 'settings'
};

export const TENANT_PAYMENTS_NAV_ITEM: NavItem = {
  href: TENANT_PAYMENT_METHODS_PATH,
  label: 'Payments',
  key: 'Payments',
  iconName: 'creditCard'
};

export const AI_ASSISTANT_NAV_ITEM: NavItem = {
  href: AI_ASSISTANT_PATH,
  label: 'AI assistant',
  key: 'AI Assistant',
  imageKey: 'new_version_Ivy_Icon_3d_2UmlF3Tey.png'
};

export const REFERRALS_NAV_ITEM: NavItem = {
  href: REFERRALS_PATH,
  label: 'Refer & save',
  iconName: 'gift'
};

export const OFFSITES_NAV_ITEM: NavItem = {
  href: CUSTOMER_OFFSITES_PATH,
  label: 'Offsites',
  key: 'Offsites',
  iconName: 'membership2'
};

export const SERVICES_AND_AMENITIES_NAV_ITEM: NavItem = {
  href: CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH,
  label: 'Services & amenities',
  mobileLabel: 'Services',
  key: 'Services & amenities',
  iconName: 'clipboardNoPageFold'
};

export const CUSTOMER_SUPPORT_NAV_ITEM: NavItem = {
  href: CUSTOMER_SUPPORT_PATH,
  label: 'Requests',
  mobileLabel: 'Requests',
  key: 'Requests',
  iconName: 'checkmark'
};

export const PROPERTIES_NAV_ITEM: NavItem = {
  href: PROPERTIES_PATH,
  label: 'Properties',
  iconName: 'buildingNew',
  key: 'properties',
  exact: true
};

export const PROPERTIES_LISTINGS_NAV_ITEM: NavItem = {
  href: PROPERTIES_LISTINGS_PATH,
  label: 'Listings',
  iconName: 'buildingNew',
  key: 'listings',
  exact: true
};

export const PROPERTIES_ACCOUNTS_NAV_ITEM: NavItem = {
  href: PROPERTIES_ACCOUNTS_PATH,
  label: 'Accounts',
  key: 'Accounts',
  iconName: 'creditCard'
};

export const PROPERTIES_PAYOUTS_NAV_ITEM: NavItem = {
  href: PROPERTIES_PAYOUTS_PATH,
  label: 'Payouts',
  key: 'Payouts',
  iconName: 'payout'
};

export const ADMIN_NAV_ITEM: NavItem = {
  href: ADMIN_PATH,
  label: 'Admin',
  key: 'Admin',
  iconName: 'membership'
};

export const MORE_NAV_ITEM: NavItem = { iconName: 'ellipsisH', href: ACCOUNT_PATH, label: 'More' };
export const ADMIN_TOOLS_NAV_ITEM: NavItem = { iconName: 'settings', href: ADMIN_TOOLS_PATH, label: 'Tools' };
export const ADMIN_WORKSPACES_NAV_ITEM: NavItem = {
  href: ADMIN_WORKSPACES_PATH,
  label: 'Workspaces',
  iconName: 'building'
};
export const ADMIN_BUILDINGS_NAV_ITEM: NavItem = {
  href: ADMIN_BUILDINGS_PATH,
  label: 'Buildings',
  iconName: 'buildingRetail'
};
export const ADMIN_TENANTS_NAV_ITEM: NavItem = {
  href: ADMIN_TENANTS_PATH,
  label: 'Tenants',
  iconName: 'walk'
};
export const ADMIN_SUPPORT_NAV_ITEM: NavItem = { href: ADMIN_SUPPORT_PATH, label: 'Support', iconName: 'support' };
export const ADMIN_USERS_NAV_ITEM: NavItem = { href: ADMIN_USERS_PATH, label: 'Users', iconName: 'membership2' };
export const ADMIN_CUSTOMERS_NAV_ITEM: NavItem = {
  href: ADMIN_CUSTOMERS_PATH,
  label: 'Clients',
  iconName: 'membership'
};
export const ADMIN_LICENSORS_NAV_ITEM: NavItem = {
  href: ADMIN_LICENSORS_PATH,
  label: 'Licensors',
  iconName: 'buildingNew'
};
export const ADMIN_VENDORS_NAV_ITEM: NavItem = {
  href: ADMIN_VENDORS_PATH,
  label: 'Vendors',
  iconName: 'buildingRetail'
};
export const ADMIN_SHORT_TERM_BOOKINGS_NAV_ITEM: NavItem = {
  href: ADMIN_SHORT_TERM_BOOKINGS_PATH,
  label: 'Short-term bookings',
  iconName: 'calendar'
};
export const ADMIN_PAYOUTS_NAV_ITEM: NavItem = { href: ADMIN_PAYOUTS_PATH, label: 'Payouts', iconName: 'payout' };
export const ADMIN_REQUESTS_NAV_ITEM: NavItem = { href: ADMIN_TASKS_PATH, label: 'Tasks', iconName: 'list' };
export const ADMIN_PRODUCTS_NAV_ITEM: NavItem = {
  href: ADMIN_PRODUCTS_PATH,
  label: 'Products',
  iconName: 'chair'
};
export const ADMIN_MAP_NAV_ITEM: NavItem = { href: ADMIN_MAP_PATH, label: 'Map', iconName: 'map' };
export const OFFICE_SEARCH_NAV_ITEM: NavItem = {
  href: OFFICE_REQUEST_PATH,
  label: 'Search',
  iconName: 'search'
};

export const FAVORITES_NAV_ITEM: NavItem = {
  href: CUSTOMER_OFFICE_REQUEST_PATH,
  label: 'Favorites',
  iconName: 'heartOutline'
};

export const NAV_ICON_CONFIG = {
  white: {
    hoverColor: 'gray-50',
    mouseDownColor: 'gray-100',
    activeColor: 'blue-200',
    activeHoverColor: 'blue-300',
    hoverIconColor: 'gray-400',
    iconColor: 'gray-400',
    iconActiveColor: 'blue-500',
    mouseDownIconColor: 'gray-400'
  },
  light: {
    hoverColor: 'gray-50',
    mouseDownColor: 'gray-100',
    activeColor: 'blue-200',
    activeHoverColor: 'blue-300',
    hoverIconColor: 'gray-400',
    iconColor: 'gray-400',
    iconActiveColor: 'blue-500',
    mouseDownIconColor: 'gray-400'
  },
  dark: {
    hoverColor: 'blue-200',
    mouseDownColor: 'blue-300',
    activeColor: 'blue-200',
    activeHoverColor: 'blue-300',
    iconColor: 'gray-400',
    iconActiveColor: 'blue-500',
    hoverIconColor: 'blue-500',
    mouseDownIconColor: 'blue-500'
  }
} as const;

export const NAV_ITEM_ORDER: Record<string, string[]> = {};

NAV_ITEM_ORDER['other'] = [
  DASHBOARD_NAV_ITEM.label,
  SERVICES_AND_AMENITIES_NAV_ITEM.label,
  CUSTOMER_SUPPORT_NAV_ITEM.label,
  OFFICE_SEARCH_NAV_ITEM.label,
  FAVORITES_NAV_ITEM.label,
  OFFSITES_NAV_ITEM.label,
  MEMBERSHIP_NAV_ITEM.label,
  PROPERTIES_ACCOUNTS_NAV_ITEM.label,
  PROPERTIES_PAYOUTS_NAV_ITEM.label,
  REFERRALS_NAV_ITEM.label,
  OFFSITES_NAV_ITEM.label,
  ACCOUNT_NAV_ITEM.label,
  MORE_NAV_ITEM.label
];

NAV_ITEM_ORDER['tenant'] = [
  AI_ASSISTANT_NAV_ITEM.label,
  CLEANING_SERVICE_NAV_ITEM.label,
  PANTRY_AND_SUPPLIES_NAV_ITEM.label,
  FURNITURE_AND_EQUIPMENT_NAV_ITEM.label,
  CUSTOMER_SUPPORT_NAV_ITEM.label,
  TENANT_ADMIN_NAV_ITEM.label
];

export interface OfficePath {
  rootPath: string;
  officePath: string;
  label: string;
}

// paths that are office specific
export const CUSTOMER_OFFICE_PATHS: OfficePath[] = [
  {
    rootPath: CUSTOMER_SERVICES_PATH,
    officePath: CUSTOMER_CLEANING_SLUG_PATH,
    label: SERVICES_AND_AMENITIES_NAV_ITEM.label
  },
  {
    rootPath: CUSTOMER_SERVICES_PATH,
    officePath: CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH,
    label: SERVICES_AND_AMENITIES_NAV_ITEM.label
  },
  {
    rootPath: CUSTOMER_SERVICES_PATH,
    officePath: CUSTOMER_FURNITURE_SLUG_PATH,
    label: SERVICES_AND_AMENITIES_NAV_ITEM.label
  },
  {
    rootPath: CUSTOMER_SERVICES_PATH,
    officePath: CUSTOMER_ADD_FURNITURE_SLUG_PATH,
    label: SERVICES_AND_AMENITIES_NAV_ITEM.label
  },
  {
    rootPath: CUSTOMER_ORDERS_PATH,
    officePath: CUSTOMER_ORDERS_PATH,
    label: ''
  }
];
