import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Color, colorClassName } from 'shared';

import { DS_CLASS_NAME } from './constants';
import { Align, textAlignClass } from './textAlign';

export interface Props {
  size?: Size;
  inline?: boolean;
  color?: Color | 'blue-gradient' | 'purple-gradient';
  bold?: boolean;
  semibold?: boolean;
  hoverSemibold?: boolean;
  /** Used to override parent element's bold */
  notBold?: boolean;
  notSemibold?: boolean;
  align?: Align;
  underline?: boolean;
  ellipsis?: boolean;
  caps?: boolean;
  wrap?: boolean;
  italic?: boolean;
  tag?: HeadingTag;
  scale?: boolean;
  whiteSpace?: 'pre';
  wordBreak?: 'break-all' | 'break-word';
  maxLines?: 1 | 2 | 3 | 4;
  __style?: React.CSSProperties;
}

export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
export type HeadingTag = typeof HEADING_TAGS[number];
export type Size =
  | HeadingTag
  | 'body1'
  | 'body2'
  | 'body3'
  | 'link'
  | 'large-link'
  | 'paragraph'
  | 'quotation'
  | 'tag'
  | 'label'
  | 'display-lg'
  | 'display-md'
  | 'display-sm'
  | 'headline-xl'
  | 'headline-lg'
  | 'headline-md'
  | 'headline-sm'
  | 'headline-xs'
  | 'body-xl'
  | 'body-lg'
  | 'body-md'
  | 'body-sm'
  | 'body-xs'
  | 'overline-lg'
  | 'overline-md'
  | 'overline-sm'
  | 'link-lg'
  | 'link-md'
  | 'link-sm';

export const DEFAULT_BOLD_TEXT_SIZES = [
  'display-lg',
  'display-md',
  'display-sm',
  'headline-xl',
  'headline-lg',
  'headline-md',
  'headline-sm',
  'headline-xs'
];

const DEPRECATED_SIZES = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body1',
  'body2',
  'body3',
  'link',
  'large-link',
  'paragraph',
  'quotation',
  'label'
];

const Text: React.FC<PropsWithChildren<Props>> = ({
  inline = false,
  color,
  children,
  align,
  underline = false,
  ellipsis = false,
  caps = false,
  wrap = true,
  italic = false,
  semibold = false,
  notBold = false,
  notSemibold = false,
  tag,
  scale = false,
  wordBreak,
  maxLines,
  whiteSpace,
  hoverSemibold,
  __style,
  ...props
}) => {
  const customSizeHeading = tag && props.size && tag !== props.size;
  const size = customSizeHeading ? props.size : tag || props.size;
  const bold =
    (HEADING_TAGS.includes(size as HeadingTag) || DEFAULT_BOLD_TEXT_SIZES.includes(size as Size)) &&
    props.bold === undefined
      ? true
      : !!props.bold;
  const isScaled = customSizeHeading ? scale || false : scale === undefined ? true : scale;
  const Tag = tag ? tag : inline ? 'span' : 'p';
  const classes: string[] = [];

  if (color === 'blue-gradient') {
    classes.push('is-color-blue-gradient');
  } else if (color === 'purple-gradient') {
    classes.push('is-color-purple-gradient');
  } else if (color) {
    classes.push(colorClassName(color));
  }
  if (align) {
    classes.push(textAlignClass(align));
  }
  if (size) {
    if (DEPRECATED_SIZES.includes(size)) {
      classes.push(`is-size-${size}`);
    } else {
      classes.push(`is-${size}`);
    }
  }
  if (isScaled) {
    classes.push('is-scaled');
  }
  if (wordBreak) {
    classes.push(`is-word-${wordBreak}`);
  }
  if (maxLines) {
    classes.push(`is-max-lines-${maxLines}`);
  }
  if (whiteSpace) {
    classes.push(`is-white-space-${whiteSpace}`);
  }

  return (
    <Tag
      className={classNames('Text', DS_CLASS_NAME, classes, {
        'is-bold': bold,
        'is-semibold': semibold,
        'is-underlined': underline,
        'is-ellipsis': ellipsis,
        'is-caps': caps,
        'is-no-wrap': !wrap,
        'is-italic': italic,
        'is-not-bold': notBold,
        'is-not-semibold': notSemibold,
        'is-hover-semibold': hoverSemibold
      })}
      style={__style}
    >
      {children}
    </Tag>
  );
};

export default Text;
