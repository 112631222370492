import React from 'react';

import Icon from './Icon';
import Img, { Props as ImgProps } from './Img';
import Layout from './Layout';
import { OnMeasure } from './types';

interface Props extends Omit<ImgProps, 'width' | 'height' | 'srcKey'> {
  size: 'tiny' | 'smallThumbnail' | 'thumbnail' | 'sm' | 'lg' | 'hero';
  onMeasure?: OnMeasure;
  srcKey?: string | null;
}

const SIZE_CONFIG = {
  tiny: { width: 24, height: 24 },
  smallThumbnail: { width: 208, height: 208 },
  thumbnail: { width: 320, height: 320 },
  sm: { width: 600, height: 600 },
  lg: { width: 1200, height: 1200 },
  hero: { width: 3000, height: 1080 }
} as const;

const ProductImg: React.FC<Props> = ({ size, srcKey, ...props }) => {
  const pixelWidth =
    typeof props.style?.width === 'number'
      ? props.style?.width
      : parseInt(props.style?.width?.replace('px', '') || '0');
  return srcKey ? (
    <Img srcKey={srcKey} {...props} {...SIZE_CONFIG[size]} />
  ) : (
    <Layout
      background="gray-25"
      borderRadius={16}
      align="center"
      justify="center"
      style={{ objectFit: 'cover', ...props.style }}
    >
      <Icon name="miscellaneous" size={pixelWidth > 0 ? pixelWidth / 1.4 : 36} color="gray-200" />
    </Layout>
  );
};

export default ProductImg;
