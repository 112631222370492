import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR,
  CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR,
  Clickable,
  Icon,
  Layout,
  StatusDisplay,
  Text
} from 'ds';
import { CUSTOMER_SUPPORT_PATH } from 'routes';
import { titleize } from 'shared';
import { SupportTicket } from 'ux/Customer/Support/types';

interface Props {
  supportTicket: SupportTicket;
}

const ChatbotViewSupportTicket: React.FC<Props> = ({ supportTicket: { title, description, id, status } }) => {
  const history = useHistory();
  return (
    <Clickable
      onClick={() => {
        history.push(`${CUSTOMER_SUPPORT_PATH}/${id}`);
      }}
    >
      <Layout color="gray-25" borderRadius={16} width={320}>
        <Layout flex justify="space-between" align="center" height={44} paddingX={16}>
          <Layout flex direction="row" gap={8} align="center">
            <Layout color="blue-500" borderRadius="circular" width={16} height={16} align="center" justify="center">
              <Icon name="checkmark" size={8} color="white" stroke={2} />
            </Layout>
            <Text size="body-sm">Request created</Text>
          </Layout>
          <Layout flex direction="row" gap={8} align="center">
            <Text size="body-sm" color="gray-400">
              View
            </Text>
            <Icon name="externalLink" size={16} color="gray-400" />
          </Layout>
        </Layout>
        <Layout borderTop paddingX={16} paddingY={12}>
          <Layout flex direction="column">
            <Layout>
              <Text align="left" size="body-md" maxLines={1}>
                {title}
              </Text>
              <Layout marginTop={9} width="100%">
                <Text size="body-xs" color="gray-400" maxLines={1} align="left">
                  {description}
                </Text>
              </Layout>
              <Layout marginTop={14} flex direction="row" justify="space-between">
                <Text size="body-xs" color="gray-400">
                  #{id}
                </Text>
                <StatusDisplay
                  color={CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR[status]}
                  dotColor={CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR[status]}
                  textColor={CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR[status]}
                  text={titleize(status)}
                  size="sm"
                />
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Clickable>
  );
};

export default ChatbotViewSupportTicket;
