import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

const selectSelectedCategoryName = (state: RootState) => state.Consumables.selectedCategoryName;
const selectSelectedTypeName = (state: RootState) => state.Consumables.selectedTypeName;
const selectProducts = (state: RootState) => state.Consumables.products;
const selectCategories = (state: RootState) => state.Consumables.categories;
const selectTypes = (state: RootState) => state.Consumables.types;
const selectConsumablesStateLoaded = (state: RootState) => state.Consumables.consumablesStateLoaded;
const selectProductSubscriptions = (state: RootState) => state.Consumables.consumablesProductSubscriptions;
const selectHasProductSubscriptions = createSelector(
  selectProductSubscriptions,
  subscriptions => !!subscriptions && !!subscriptions.length
);
const selectConsumablesOrders = (state: RootState) => state.Consumables.consumablesOrders;
export {
  selectSelectedCategoryName,
  selectSelectedTypeName,
  selectProducts,
  selectCategories,
  selectTypes,
  selectConsumablesStateLoaded,
  selectProductSubscriptions,
  selectConsumablesOrders,
  selectHasProductSubscriptions
};
