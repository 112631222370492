import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { V2 } from 'shared';

type V2UserState = {
  user: V2.User | undefined | null;
};

const initialState: V2UserState = {
  user: undefined
};

const v2UserSlice = createSlice({
  name: 'v2/User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<V2.User | null>) => {
      const user = action.payload;
      // Update Sentry user context
      Sentry.setUser(
        user
          ? {
              id: user.id.toString(),
              email: user.email,
              username: `${user.firstname} ${user.lastname}`
            }
          : null
      );
      state.user = user;
    },
    updateUser: (state, action: PayloadAction<Partial<V2.User>>) => {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload
        };
      }
    },
    clearUser: state => {
      state.user = null;
      Sentry.setUser(null);
    }
  }
});

export const { actions } = v2UserSlice;
export default v2UserSlice.reducer;
