import { AxiosResponse } from 'axios';

import axios from 'shared/lib/axios';
import { actions as chatbotActions } from 'store/AIAssistant';
import { store } from 'store/store';
import { getCustomerSupportTickets } from 'ux/Customer/Support/requests';
import { SupportTicket } from 'ux/Customer/Support/types';

import {
  ChatbotIntentGroup,
  ChatbotInteractionAPI,
  ChatbotResolutionRequest,
  ChatbotThreadAPI,
  ChatbotUpdateRequest
} from './types';

const CHATBOT_PATH = '/v1/chatbot' as const;

export async function fetchThreads() {
  const [threadsResponse, { data: supportTickets }] = await Promise.all([
    axios.post<ChatbotThreadAPI[]>(`${CHATBOT_PATH}/fetch_threads`, {}),
    getCustomerSupportTickets()
  ]);
  store.dispatch(chatbotActions.setSupportTickets(supportTickets));
  return threadsResponse;
}

export async function startThread() {
  const threadResponse = await axios.post<ChatbotThreadAPI>(`${CHATBOT_PATH}/start_thread`, {});
  return threadResponse;
}

export async function determineIntent({
  chatbotThreadId,
  message,
  intentGroup
}: {
  chatbotThreadId: number;
  message: string;
  intentGroup: ChatbotIntentGroup;
}) {
  const intentResponse = await axios.post<ChatbotInteractionAPI>(`${CHATBOT_PATH}/determine_intent`, {
    chatbot_thread_id: chatbotThreadId,
    intent_group: intentGroup,
    message
  });
  const { data: supportTickets } = await getCustomerSupportTickets();
  store.dispatch(chatbotActions.setSupportTickets(supportTickets));
  return intentResponse;
}

export async function updateIntent(chatbotUpdateRequest: ChatbotUpdateRequest) {
  const payload = chatbotUpdateRequest;
  const updateResponse = await axios.post<ChatbotInteractionAPI>(`${CHATBOT_PATH}/update_intent`, payload);
  const { data: supportTickets } = await getCustomerSupportTickets();
  store.dispatch(chatbotActions.setSupportTickets(supportTickets));
  return updateResponse;
}

interface ResolveIntentResponse extends AxiosResponse<ChatbotInteractionAPI> {
  data: ChatbotInteractionAPI & { supportTicket?: SupportTicket };
}

export async function resolveIntent(
  chatbotResolutionRequest: ChatbotResolutionRequest
): Promise<ResolveIntentResponse> {
  const resolveIntentResponse: ResolveIntentResponse = await axios.post<ChatbotInteractionAPI>(
    `${CHATBOT_PATH}/resolve_intent`,
    chatbotResolutionRequest
  );
  const { data: supportTickets } = await getCustomerSupportTickets();
  store.dispatch(chatbotActions.setSupportTickets(supportTickets));

  // const supportTicket = supportTickets.find(
  //   ({ chatbot_intent_id }) => chatbot_intent_id === resolveIntentResponse.data.id
  // );

  // resolveIntentResponse.data = {
  //   ...resolveIntentResponse.data,
  //   supportTicket
  // };

  return resolveIntentResponse;
}

export async function addFeedbackToLlmMessage(llmMessageId: number, liked: boolean) {
  const payload = {
    liked
  };
  const response = await axios.post(`/v1/llm_messages/${llmMessageId}/feedback`, payload);
  return response;
}
