import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { priceAdjustedForDaysPerWeek } from 'helpers/price';

import Head from 'components/Head';

import { app, media } from 'context';
import { Layout } from 'ds';
import { LISTING_PATH } from 'routes';
import { ListingWorkspace } from 'shared';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import MobileCarousel from 'ux/Public/Listing/MobileCarousel';

import About from './About';
import CtaBox from './CtaBox';
import CustomerSuccess from './CustomerSuccess';
import Divider from './Divider';
import Hero from './Hero';
import Highlights from './Highlights';
import Location from './Location';
import NearbyWorkspaces from './NearbyWorkspaces';
import OptionalAddOns from './OptionalAddOns';
import PhotoCarousel from './PhotoCarousel';
import RealEstateListingSchema from './RealEstateListingSchema';
import TourCTA from './TourCTA';
import WhatsIncluded from './WhatsIncluded';
import { LISTING_CTA_BOX_WIDTH, LISTING_CTA_BOX_WIDTH_SM, LISTING_MAX_CONTENT_WIDTH } from './constants';

interface Props {
  workspace: ListingWorkspace;
}

const ListingUI: React.FC<Props> = ({
  workspace,
  workspace: { short_name, address, name, monthly_price, nearby_workspaces, matterport_id, tour_video }
}) => {
  const [selectedDaysRate, setSelectedDaysRate] = useState<number>();
  const { navBarHeight, setOverflowX } = useContext(app);
  const history = useHistory();
  const location = useLocation();
  const { days_per_week: qsDaysPerWeek } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const daysPerWeek = typeof qsDaysPerWeek === 'string' && qsDaysPerWeek.length ? parseInt(qsDaysPerWeek) : 5;
  const { xs, sm } = useContext(media);
  const { contentWidth } = useContext(app);
  const photos = [...workspace.images];
  const floorPlans = [...workspace.floor_plans];
  const maxContentWidth = Math.min(contentWidth, LISTING_MAX_CONTENT_WIDTH);
  const tourVideoKey = tour_video?.key || null;
  const user = useAppSelector(selectUser);
  const isProspectVersion = location.pathname.includes(LISTING_PATH) && !!user;

  const ctaBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!setOverflowX) return;

    if (xs) {
      setOverflowX('hidden');
    } else {
      setOverflowX('visible');
    }

    return () => {
      setOverflowX && setOverflowX('hidden');
    };
  }, [!!setOverflowX, xs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!monthly_price) return;

    setSelectedDaysRate(
      priceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price, daysPerWeek: Math.min(5, daysPerWeek) })
    );
  }, [monthly_price, daysPerWeek]);

  const setDaysPerWeek = (daysPerWeek: number) => {
    history.replace({ search: generateSearch({ search: location.search, daysPerWeek }) });
  };
  const ctaBoxMarginLeft = xs || sm ? 32 : 40;
  const ctaBoxWidth = sm ? LISTING_CTA_BOX_WIDTH_SM : LISTING_CTA_BOX_WIDTH;
  const HeroCTA = <TourCTA workspace={workspace} textColor="white" showAvailabilityStartDate={false} />;

  return (
    <>
      <Head
        title={`${address.city} Office Space${!!address.neighborhood ? ` in ${address.neighborhood}` : ''} | ${name}`}
        description={workspace.description}
      />
      <RealEstateListingSchema workspace={workspace} />
      {!xs && !!HeroCTA && (
        <Hero workspace={workspace} CTA={HeroCTA} isProspectVersion={isProspectVersion} daysPerWeek={daysPerWeek} />
      )}
      {!xs && !HeroCTA && (
        <Hero
          workspace={workspace}
          handleRequestTour={() => {
            const ctaBox = ctaBoxRef.current;

            if (!ctaBox) return;

            window.scrollTo({ top: ctaBox.offsetTop - 120, behavior: 'smooth' });
          }}
          isProspectVersion={isProspectVersion}
          daysPerWeek={daysPerWeek}
        />
      )}
      {xs && (
        <MobileCarousel
          backLabel={isProspectVersion ? short_name || '' : name}
          images={photos}
          floorPlans={floorPlans}
          matterportId={matterport_id}
          tourVideoKey={tourVideoKey}
          workspace={workspace}
        />
      )}
      {xs && (
        <CtaBox
          workspace={workspace}
          selectedDaysRate={selectedDaysRate}
          daysPerWeek={daysPerWeek}
          setDaysPerWeek={setDaysPerWeek}
        />
      )}
      <Layout width="100%" justify={xs ? undefined : 'center'}>
        <Layout marginTop={xs ? 60 : 32} __style={{ maxWidth: maxContentWidth }} direction="column">
          <Highlights workspace={workspace} />
          {!xs && (
            <>
              <Layout paddingTop={photos.length > 1 ? 44 : undefined} width="100%" justify="center">
                {photos.length > 1 && (
                  <Layout width={Math.min(contentWidth, 1080)}>
                    <PhotoCarousel
                      images={photos}
                      floorPlans={floorPlans}
                      matterportId={matterport_id}
                      tourVideoKey={tourVideoKey}
                      workspace={workspace}
                    ></PhotoCarousel>
                  </Layout>
                )}
              </Layout>
              <Divider />
            </>
          )}
          <Layout direction="row" justify="space-between">
            <Layout marginTop={xs ? 36 : undefined}>
              <About workspace={workspace} />
              <Divider />
              <WhatsIncluded />
              <Divider />
              <OptionalAddOns maxContentWidth={maxContentWidth - ctaBoxWidth - ctaBoxMarginLeft} />
              <Layout marginTop={16} marginBottom={36} borderBottom borderColor="gray-50" />
              <Location workspace={workspace} />
              <Divider />
              <CustomerSuccess name="Justin" srcKey="justin_rE4LLWbTWD.jpeg" />
            </Layout>
            {!xs && (
              <Layout
                marginLeft={ctaBoxMarginLeft}
                __ref={ctaBoxRef}
                position="sticky"
                __style={{ alignSelf: 'flex-start' }}
                top={navBarHeight + 36}
              >
                <CtaBox
                  workspace={workspace}
                  selectedDaysRate={selectedDaysRate}
                  daysPerWeek={daysPerWeek}
                  setDaysPerWeek={setDaysPerWeek}
                />
              </Layout>
            )}
          </Layout>
          {!!nearby_workspaces && !isProspectVersion && (
            <>
              <Divider />
              <NearbyWorkspaces workspaces={nearby_workspaces} />
            </>
          )}
        </Layout>
      </Layout>
    </>
  );
};

const generateSearch = ({ daysPerWeek, search }: { daysPerWeek: number; search: string }) => {
  const searchParams = new URLSearchParams(search);

  searchParams.set('days_per_week', daysPerWeek.toString());

  return searchParams.toString();
};

export default ListingUI;
