import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePreviousValue } from 'beautiful-react-hooks';
import pluralize from 'pluralize';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import Swiper from 'swiper';

import { SUGGESTED_CITIES, validBounds } from 'helpers/map';

import LocationBottomSheet from 'components/LocationBottomSheet';

import { app } from 'context';
import { HEADER_Z_INDEX, Icon, IconButton, Layout, Map, MapWorkspace, Pressable, Spinner, Text } from 'ds';
import { OnLocationChangeParams } from 'ds/inputs/Location/LocationInput';
import { LIGHT_MAP_STYLES, MAX_CLUSTER_ZOOM } from 'ds/map/constants';
import { boundsToLiteral, latLngToPixelXY } from 'ds/map/utils';
import { DEMO_SEARCH_WORKSPACE } from 'ds/workspace-data';
import { apiTrack } from 'lib/analytics';
import { getLocations, getPlaceDetails } from 'lib/location';
import { Bounds, DEFAULT_LAT_LNG, LatLng, Listing, getDistance, searchWorkspaces } from 'shared';
import { actions } from 'store/Search';
import { selectSearchWorkspaces } from 'store/Search/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';
import { Result } from 'ux/Public/Home/types';

import FiltersBottomSheet from './FiltersBottomSheet';
import MapCarousel from './MapCarousel';
import MobileTopBar from './MobileTopBar';
import NoResults from './NoResults';
import SortBottomSheet from './SortBottomSheet';
import WorkspaceListCard from './WorkspaceListCard';
import { SearchFilters, SearchSortLabel } from './types';
import { filterWorkspaces, generateSearch, sortWorkspaces } from './utils';

interface Props extends SearchFilters {
  center: LatLng;
  zoom: number;
  location: string;
  bounds?: Bounds;
  selectedWorkspaceId: number | null;
  backPathname: string;
  sortLabel: SearchSortLabel;
}

const CAROUSEL_HEIGHT = 100;
const WIDGET_BOTTOM = 28;
const TOP_BAR_TOP = 16;

const MobileUI: React.FC<Props> = ({
  center,
  zoom,
  bounds,
  location,
  selectedWorkspaceId,
  backPathname,
  daysPerWeek = 5,
  minPrice,
  maxPrice,
  minSqft,
  maxSqft,
  offsitesOnly,
  numMeetingRooms,
  sortLabel
}) => {
  const [requestState, setRequestState] = useState<'pending' | 'in_progress' | 'success' | 'failure'>('pending');
  const allWorkspaces = useAppSelector(selectSearchWorkspaces);
  const filteredWorkspaces = filterWorkspaces({
    workspaces: allWorkspaces,
    filters: { daysPerWeek, minPrice, maxPrice, minSqft, maxSqft, offsitesOnly, numMeetingRooms }
  });
  const workspaces = sortWorkspaces({ workspaces: filteredWorkspaces, sortLabel });
  const { contentWidth, windowHeight, windowWidth, ipLocation, contentPaddingX, navBarHeight } = useContext(app);
  const [locationSheetIsVisible, setLocationSheetIsVisible] = useState<boolean>(false);
  const [daysFilterIsVisible, setFilterIsVisible] = useState<boolean>(false);
  const [sortSheetIsVisible, setSortSheetIsVisible] = useState<boolean>(false);
  const [listIsVisible, setListIsVisible] = useState<boolean>(false);
  const origin = ipLocation || undefined;
  const [searchValue, setSearchValue] = useState<string>('');
  const [_results, setResults] = useState<Result[]>([]);
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [locationSearchType, setLocationSearchType] = useState<'place' | 'street address'>('place');
  const [displayValue, setDisplayValue] = useState<string>('');
  const history = useHistory();
  const { search } = useLocation();
  const swiperRef = useRef<Swiper>();
  const mapRef = useRef<google.maps.Map | null>(null);
  const [cardRect, setCardRect] = useState<DOMRect>();
  const searchChangeSourceRef = useRef<
    'swipe' | 'price_click' | 'cluster_click' | 'zoom' | 'drag' | 'location_search'
  >();
  const listRef = useRef<VariableSizeList>(null);
  const isLoggedIn = !!useAppSelector(selectUser);

  const { lat: centerLat, lng: centerLng } = center;
  const {
    ne: { lat: neLat, lng: neLng },
    sw: { lat: swLat, lng: swLng }
  } = bounds || { ne: {}, sw: {} };
  const dispatch = useAppDispatch();

  const selectedIndex = workspaces.findIndex(ws => ws.id === selectedWorkspaceId);

  const previousWorkspaceIds = (usePreviousValue(workspaces.map(ws => ws.id)) as number[]) || [];

  const setSelectedWorkspaceId = (id: number | null) => {
    history.replace({ search: generateSearch({ search: history.location.search, selectedWorkspaceId: id }) });
  };

  useEffect(() => {
    const bounds =
      neLat && neLng && swLat && swLng ? { ne: { lat: neLat, lng: neLng }, sw: { lat: swLat, lng: swLng } } : undefined;

    if (!centerLat || !centerLng || !bounds) return;

    setRequestState('in_progress');

    searchWorkspaces({
      bounds:
        neLat && neLng && swLat && swLng
          ? { ne: { lat: neLat, lng: neLng }, sw: { lat: swLat, lng: swLng } }
          : undefined,
      center: { lat: centerLat, lng: centerLng }
    })
      .then(({ data }) => {
        apiTrack('Location Searched', {
          city,
          region,
          country,
          type: locationSearchType,
          value: displayValue,
          results: data.length
        });
        let workspaces = [...data];

        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }

        const firstWorkspace = workspaces[0];

        const swiper = swiperRef.current;
        const updatedSelectedIndex = workspaces.findIndex(ws => ws.id === selectedWorkspaceId);
        const selectedWorkspace = workspaces[updatedSelectedIndex];

        if (workspaces.length === 1 && zoom > MAX_CLUSTER_ZOOM && source === 'cluster_click') {
          setSelectedWorkspaceId(workspaces[0].id);
        }
        if (
          selectedWorkspaceId &&
          workspaces.length > 1 &&
          workspaces
            .map(ws => ws.id)
            .sort((id1, id2) => id1 - id2)
            .join(',') === previousWorkspaceIds.sort((id1, id2) => id1 - id2).join(',')
        ) {
          workspaces = workspaces.sort(
            (ws1, ws2) => previousWorkspaceIds.indexOf(ws1.id) - previousWorkspaceIds.indexOf(ws2.id)
          );
        } else if (swiper && selectedWorkspace && updatedSelectedIndex !== selectedIndex && zoom > MAX_CLUSTER_ZOOM) {
          const activeSwiperIndex = swiper.activeIndex;

          const swap = workspaces[activeSwiperIndex];

          workspaces[activeSwiperIndex] = selectedWorkspace;
          workspaces[Math.min(updatedSelectedIndex, workspaces.length - 1)] = swap;
        } else if (selectedWorkspaceId && firstWorkspace) {
          setSelectedWorkspaceId(firstWorkspace.id);
        } else if (!workspaces.length) {
          setSelectedWorkspaceId(null);
        }

        const map = mapRef.current;

        const filteredWorkspaces = map
          ? filterWorkspacesByViewport({ workspaces, map, windowHeight, showCarousel, windowWidth })
          : workspaces.filter(Boolean);

        // compact workspaces in case swaping made undefineds in array
        dispatch(actions.setWorkspaces(filteredWorkspaces));

        searchChangeSourceRef.current = undefined;
        setRequestState('success');
      })
      .catch(() => {
        dispatch(actions.setWorkspaces([]));
        setRequestState('failure');
      });
  }, [centerLat, centerLng, dispatch, neLat, neLng, swLat, swLng]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onLocationSelect({ city, region, country, place_id }: Result) {
    getPlaceDetails({
      place_id: place_id,
      callback: place => {
        if (!place) return;

        const displayValue = place.adr_address || [city, region, country].filter(Boolean).join(', ');
        setSearchValue(displayValue);
        setRequestState('in_progress');
        setCity(city);
        setRegion(region);
        setCountry(country);
        setLocationSearchType(place ? 'street address' : 'place');
        setDisplayValue(displayValue);
        onLocationChange({ place, location: displayValue });
        setLocationSheetIsVisible(false);
      }
    });
  }

  const onLocationChange = ({ location, place }: OnLocationChangeParams) => {
    if (!place) return;

    const map = mapRef.current;

    if (!map) return;

    const { geometry, address_components } = place;
    const viewPort = geometry?.viewport;
    if (!viewPort) return;

    searchChangeSourceRef.current = 'location_search';

    const bounds = geometry
      ? { ne: viewPort.getNorthEast().toJSON(), sw: viewPort.getSouthWest().toJSON() }
      : undefined;

    const hasStreetComponent = !!(address_components && address_components.find(c => c.types.includes('route')));
    const zoom = hasStreetComponent ? 14 : undefined;

    if (hasStreetComponent && geometry) {
      map.setValues({ center: geometry.location, zoom });
    } else if (!!bounds) {
      map.fitBounds({
        east: bounds.ne.lng,
        west: bounds.sw.lng,
        north: bounds.ne.lat,
        south: bounds.sw.lat
      });
    }

    history.push({ search: generateSearch({ search: history.location.search, location }) });
  };

  const userLoc = ipLocation ? { lat: ipLocation.lat, lng: ipLocation.lng } : DEFAULT_LAT_LNG;

  const results = searchValue
    ? _results
    : [...SUGGESTED_CITIES].sort((c1, c2) => {
        const dist1 = getDistance(userLoc, c1.loc);
        const dist2 = getDistance(userLoc, c2.loc);

        return dist1 - dist2;
      });

  const handleChange = (value: string) => {
    getLocations({
      input: value,
      origin,
      callback: results => {
        setResults(
          results
            .map(r => ({
              city: r.terms[0]?.value,
              region: r.terms[1]?.value,
              country: r.terms[2]?.value,
              place_id: r.place_id
            }))
            .slice(0, 5)
        );
      }
    });
    setSearchValue(value);
  };

  const onLocationClick = () => {
    setLocationSheetIsVisible(true);
  };

  const fitBounds = (map: google.maps.Map, workspaces: MapWorkspace[]) => {
    const bounds = new google.maps.LatLngBounds();

    workspaces.forEach(({ address: { lat, lon: lng } }) => {
      bounds.extend({ lat, lng });
    });

    map.fitBounds(bounds, { bottom: 160, top: 84, right: 64, left: 64 });
  };

  const isLoading = ['pending', 'in_progress'].includes(requestState);
  const source = searchChangeSourceRef.current;
  const updateUrlOnMapChange =
    listIsVisible || !bounds || (source && ['location_search', 'cluster_click'].includes(source));
  const showCarousel = workspaces.length > 0 && !!selectedWorkspaceId;

  return (
    <>
      <Layout visibility="hidden" position="absolute">
        <WorkspaceListCard
          workspace={{ ...DEMO_SEARCH_WORKSPACE }}
          daysPerWeek={daysPerWeek}
          onMeasure={rect => {
            setCardRect(rect);
          }}
          offsitesOnly={!!offsitesOnly}
        />
      </Layout>
      <LocationBottomSheet
        isVisible={locationSheetIsVisible}
        results={results}
        onClose={() => setLocationSheetIsVisible(false)}
        handleChange={handleChange}
        onLocationSelect={onLocationSelect}
        searchValue={searchValue}
        animate={false}
        isFullScreen
      />
      <FiltersBottomSheet
        isVisible={daysFilterIsVisible}
        onClose={() => setFilterIsVisible(false)}
        daysPerWeek={daysPerWeek}
        offsitesOnly={offsitesOnly}
        allWorkspaces={allWorkspaces}
        sortLabel={sortLabel}
        clearFilters={() => {
          dispatch(
            actions.updateFilters({
              daysPerWeek: 5
            })
          );

          history.replace({
            search: generateSearch({
              search,
              minPrice: null,
              maxPrice: null,
              minSqft: null,
              maxSqft: null,
              daysPerWeek: 5
            })
          });
        }}
        onSubmit={() => {
          const map = mapRef.current;

          if (map) {
            fitBounds(map, workspaces);
          }

          setFilterIsVisible(false);
        }}
        minPrice={minPrice}
        maxPrice={maxPrice}
        minSqft={minSqft}
        maxSqft={maxSqft}
        numMeetingRooms={numMeetingRooms}
        showOffsitesToggle
      />
      <SortBottomSheet
        isVisible={sortSheetIsVisible}
        handleClose={() => setSortSheetIsVisible(false)}
        sortLabel={sortLabel}
      />
      {listIsVisible && (
        <Layout position="relative">
          <StickyHeader top={0} zIndex={HEADER_Z_INDEX - 1}>
            <Layout color="white" paddingY={16} paddingX={contentPaddingX}>
              <MobileTopBar
                offsitesOnly={offsitesOnly}
                onLocationClick={onLocationClick}
                location={location}
                isLoading={isLoading}
                onFilterClick={() => setFilterIsVisible(true)}
                daysPerWeek={daysPerWeek}
                backPathname={backPathname}
              />
            </Layout>
          </StickyHeader>
          {isLoading ? (
            <Layout paddingTop={64} justify="center">
              <Spinner size="md" />
            </Layout>
          ) : workspaces.length > 0 && cardRect?.height ? (
            <>
              <VariableSizeList
                ref={listRef}
                height={windowHeight - 80}
                width={windowWidth}
                itemCount={workspaces.length}
                estimatedItemSize={cardRect.height + 24}
                itemSize={index => {
                  return cardRect.height + 40 + (index === 0 ? 40 : 0) + (index === workspaces.length - 1 ? 120 : 0);
                }}
                itemData={{
                  daysPerWeek,
                  offsitesOnly,
                  workspaces,
                  paddingX: contentPaddingX,
                  requestState,
                  handleSortPress: () => setSortSheetIsVisible(true)
                }}
              >
                {WorkspaceListCell}
              </VariableSizeList>
            </>
          ) : (
            <Layout justify="center" marginTop={90}>
              <NoResults offsitesOnly={offsitesOnly === 1} />
            </Layout>
          )}
          <Layout top={windowHeight - 60} width={windowWidth} justify="center" height={0} position="fixed">
            <Pressable
              onPress={() => setListIsVisible(false)}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                height: 48,
                paddingLeft: 24,
                paddingRight: 24,
                borderRadius: 100,
                boxShadow: '0px 2px 4px 0px #00000021'
              }}
              color="white"
              activeColor="gray-50"
            >
              <Text size="body2">Show map</Text>
              <Layout marginLeft={8} display="inline-flex">
                <Icon size="sm" name="map" color="gray-900" />
              </Layout>
            </Pressable>
          </Layout>
        </Layout>
      )}
      <Layout
        width={windowWidth}
        height={windowHeight}
        {...(listIsVisible
          ? { zIndex: -1, opacity: 0, top: 0, left: windowWidth, position: 'fixed', userSelect: 'none' }
          : { position: 'relative' })}
      >
        <Map
          offsitesOnly={!!offsitesOnly}
          zoom={zoom}
          onDragEnd={map => {
            const bounds = map.getBounds();

            if (!bounds) return;

            searchChangeSourceRef.current = 'drag';

            const updatedSearch = generateSearch({
              search,
              bounds: boundsToLiteral(bounds),
              location: 'Map Area',
              center: map.getCenter()?.toJSON()
            });

            history.replace({ search: updatedSearch });
          }}
          center={center}
          bounds={bounds}
          workspaces={workspaces}
          selectedWorkspaceId={selectedWorkspaceId}
          setSelectedWorkspaceId={setSelectedWorkspaceId}
          mapRef={mapRef}
          daysPerWeek={daysPerWeek}
          onMarkerClick={({ workspace }) => {
            searchChangeSourceRef.current = 'price_click';

            const swiper = swiperRef.current;

            if (!swiper) return;

            const index = workspaces.findIndex(ws => ws.id === workspace.id);

            swiper.slideTo(index);

            setSelectedWorkspaceId(workspace.id);

            // This handles the case of resetting searchChangeSourceRef when
            // the active swiper index did not change and slideTo was not called.
            if (searchChangeSourceRef.current === 'price_click') {
              searchChangeSourceRef.current = undefined;
            }
          }}
          onClusterClick={({ map, workspaces }) => {
            searchChangeSourceRef.current = 'cluster_click';

            if (workspaces.length === 1) {
              const workspace = workspaces[0];
              const { lat, lon: lng } = workspace.address;
              setSelectedWorkspaceId(workspace.id);
              map.setValues({ center: { lat, lng }, zoom: 14 });
              return;
            }
            fitBounds(map, workspaces);
          }}
          showActiveWorkspaceCard={false}
          useClusters={zoom < 14}
          workspaceCardType="search"
          markerType={isLoggedIn ? 'price' : undefined}
          onZoomAnimationEnd={zoom => {
            const updatedSearch = generateSearch({ search, zoom });

            history.replace({ search: updatedSearch });

            if (zoom <= MAX_CLUSTER_ZOOM) {
              setSelectedWorkspaceId(null);
            }
          }}
          onChange={
            updateUrlOnMapChange
              ? ({ center, zoom: updatedZoom, ...params }) => {
                  const newBounds = { ne: params.bounds.ne, sw: params.bounds.sw };

                  const updatedSearch = validBounds(newBounds)
                    ? generateSearch({ search, bounds: newBounds, center, zoom: updatedZoom })
                    : generateSearch({ search, center, zoom: updatedZoom });

                  history.replace({ search: updatedSearch });
                }
              : undefined
          }
          {...(listIsVisible
            ? { options: { disableDefaultUI: true } }
            : {
                options: { fullscreenControl: false, zoomControl: false, styles: LIGHT_MAP_STYLES, maxZoom: 18 }
              })}
        />
        <Layout position="fixed" top={navBarHeight + TOP_BAR_TOP} left={contentPaddingX}>
          <MobileTopBar
            onLocationClick={onLocationClick}
            location={location}
            isLoading={isLoading}
            onFilterClick={() => setFilterIsVisible(true)}
            daysPerWeek={daysPerWeek}
            backPathname={backPathname}
            offsitesOnly={offsitesOnly}
            showPopover
          />
        </Layout>
        <Layout
          position="fixed"
          left={contentPaddingX}
          bottom={showCarousel ? 140 : WIDGET_BOTTOM}
          display="inline-flex"
        >
          <IconButton type="white" size="lg" stroke={2} name="list" onClick={() => setListIsVisible(true)} />
        </Layout>
        <Layout
          position="fixed"
          {...(showCarousel ? { bottom: WIDGET_BOTTOM, left: 24 } : { bottom: -140, opacity: 0 })}
          width={contentWidth}
        >
          <MapCarousel
            swiperRef={swiperRef}
            onSlideChange={workspaceId => {
              const workspace = workspaces.find(ws => ws.id === workspaceId);
              const map = mapRef.current;

              if (!workspace || !map) return;

              const source = searchChangeSourceRef.current;

              if (source === 'price_click') {
                searchChangeSourceRef.current = 'swipe';
              } else {
                setSelectedWorkspaceId(workspace.id);
              }
            }}
            workspaces={workspaces}
            daysPerWeek={daysPerWeek}
            offsitesOnly={!!offsitesOnly}
          />
        </Layout>
      </Layout>
    </>
  );
};

const WorkspaceListCell: React.FC<ListChildComponentProps> = ({
  style,
  index,
  data: { requestState, workspaces, daysPerWeek, offsitesOnly, paddingX, handleSortPress }
}) => {
  const workspace = workspaces[index];

  if (!workspace) return null;

  return (
    <Layout paddingBottom={40} __style={{ ...style }} paddingX={paddingX}>
      {requestState === 'success' && workspaces.length > 0 && index === 0 && (
        <Layout paddingBottom={24} width="100%" align="center" justify="space-between" direction="row">
          <Text size="body2" color="gray-700">
            {pluralize('workspace', workspaces.length, true)}
          </Text>
          <Pressable onPress={handleSortPress} style={{ display: 'flex' }}>
            <Text size="body3" semibold>
              Sort
            </Text>
            <Layout display="inline-flex" marginLeft={8}>
              <Icon name="sort" size="xs" color="gray-900" />
            </Layout>
          </Pressable>
        </Layout>
      )}
      <WorkspaceListCard workspace={workspace} daysPerWeek={daysPerWeek} offsitesOnly={!!offsitesOnly} />
    </Layout>
  );
};

function filterWorkspacesByViewport({
  workspaces,
  map,
  windowHeight,
  windowWidth,
  showCarousel
}: {
  workspaces: Listing[];
  map: google.maps.Map;
  windowHeight: number;
  windowWidth: number;
  showCarousel: boolean;
}) {
  return workspaces.filter(ws => {
    if (!ws) return false;

    const { lat, lon: lng } = ws.address;

    const googleLatLng = new google.maps.LatLng(lat, lng);
    const pixelXy = latLngToPixelXY(googleLatLng, map);

    if (!pixelXy) return true;
    const { x, y } = pixelXy;

    return (
      x > 24 &&
      x < windowWidth - 24 &&
      y > 48 + TOP_BAR_TOP &&
      (showCarousel ? y < windowHeight - (CAROUSEL_HEIGHT + WIDGET_BOTTOM) : true)
    );
  });
}

export default MobileUI;
