import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import Layout, { Props as LayoutProps } from 'ds/Layout';

interface Props extends LayoutProps {}

export const StickyContainer: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const [stickyTop, setStickyTop] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setStickyTop(ref.current.getBoundingClientRect().top);
    }
  }, []);

  return (
    <Layout position="sticky" top={stickyTop} __ref={ref} {...props}>
      {children}
    </Layout>
  );
};
