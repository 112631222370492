import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { logout } from 'helpers/auth';

import { Avatar, Button, CONTENT_PADDING_X_XS, Layout, Text } from 'ds';
import { ADMIN_PATH } from 'routes';
import { User } from 'shared';

import MenuItems from './MenuItems';

interface Props {
  user: User;
  closeMenu?: VoidFunction;
}

const MobileAccount: React.FC<Props> = ({ user, closeMenu }) => {
  const { pathname } = useLocation();
  const isAdminPath = pathname.startsWith(ADMIN_PATH);
  const history = useHistory();

  return (
    <Layout flexGrow={1}>
      <Layout paddingX={CONTENT_PADDING_X_XS} align="center" justify="space-between" paddingTop={8} paddingBottom={32}>
        <Layout>
          <Text size="h3" color="gray-900" scale semibold>
            {user.firstname} {user.lastname}
          </Text>
          <Layout marginTop={4}>
            <Text size="h6" color="gray-500" bold={false} scale>
              {user.email}
            </Text>
          </Layout>
        </Layout>
        <Avatar avatarUrl={user.avatar_url} size={64} />
      </Layout>
      <MenuItems />
      <Layout marginTop={isAdminPath ? 60 : 12} paddingX={CONTENT_PADDING_X_XS}>
        <Button
          type="secondaryOutline"
          size="sm"
          fullWidth
          text="Sign Out"
          onClick={() => {
            logout({ history });
            closeMenu && closeMenu();
          }}
        />
      </Layout>
    </Layout>
  );
};

export default MobileAccount;
