import React, { useCallback, useContext, useState } from 'react';

import { app } from 'context';
import { SwiperSlide } from 'swiper/react';

import PromoCard from './PromoCard';
import { PromoWorkspace } from './types';
import Carousel from '../Carousel';

export type Props = {
  workspaces: PromoWorkspace[];
  paginationEnabled?: boolean;
  isStb?: boolean;
  showSeePricing?: boolean;
};

const WorkspaceCarousel: React.FC<Props> = ({ workspaces, isStb = false, showSeePricing = true }) => {
  const { width } = useContext(app);
  const [imageRect, setImageRect] = useState<DOMRect>();

  const onImageMeasure = useCallback(
    (rect: DOMRect) => {
      setImageRect(rect);
    },
    [width] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Carousel imageRect={imageRect}>
        {workspaces.map(workspace => {
          return (
            <SwiperSlide key={workspace.id}>
              <PromoCard
                workspace={workspace}
                onImageMeasure={onImageMeasure}
                isStb={isStb}
                showSeePricing={showSeePricing}
              />
            </SwiperSlide>
          );
        })}
      </Carousel>
    </>
  );
};

export default WorkspaceCarousel;
