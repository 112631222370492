import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import qs from 'qs';

import { setToken } from 'helpers/auth';
import { getBaseUrl } from 'helpers/environment';
import { getItem, setItem } from 'helpers/storage';

import { app, media } from 'context';
import { FullWidthBackground, Layout, Link, Logo } from 'ds';
import { RequestState } from 'ds/types';
import { apiTrack, identify } from 'lib/analytics';
import { createOfficeLead, createOfficeLeadTour, transformOfficeRequestStateToOfficeLead } from 'requests/office_leads';
import {
  ADMIN_PATH,
  CUSTOMER_OFFICE_REQUEST_PATH,
  CUSTOMER_OFFSITES_PATH,
  HOME_PATH,
  PROPERTIES_PATH,
  VENDOR_TASKS_PATH
} from 'routes';
import {
  ApiErrorResponse,
  AuthResponseData,
  CreateUserParams,
  CreateUserResponse,
  GoogleProfileData,
  User,
  castToDateTime,
  createUser,
  createWorkspace,
  getUser,
  updateUser
} from 'shared';
import { getUserV2 } from 'shared/requests/v2/user';
import { actions as addPropertyActions } from 'store/AddProperty';
import { selectAddPropertyUser, selectAddPropertyWorkspace } from 'store/AddProperty/selectors';
import { actions as authActions } from 'store/Auth';
import { selectAuthFlowType } from 'store/Auth/selectors';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import {
  selectOfficeRequest,
  selectOfficeRequestTour,
  selectOfficeRequestUser,
  selectTourRequestWorkspaceId
} from 'store/OfficeRequest/selectors';
import { actions as stbRequestActions } from 'store/STBRequest';
import { selectStbRequest } from 'store/STBRequest/selectors';
import { actions as tenancyActions } from 'store/Tenancy';
import { actions as userActions } from 'store/User';
import { selectUserId } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { actions as v2UserActions } from 'store/v2/User';
import { createTourDestinationRequest } from 'ux/Customer/OfficeRequest/requests';
import { getNextUpcomingTour } from 'ux/Customer/OfficeRequest/utils';
import { isCreatableProperty } from 'ux/Public/AddProperty/utils';
import { createShortTermBooking, isCreatableShortTermBooking } from 'ux/Public/Listing/STB/utils';
import { isCreatableOfficeRequest } from 'ux/Public/OfficeRequest/utils';

import GoBack from './GoBack';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Verify from './Verify';

interface Props {}

const Auth: React.FC<Props> = () => {
  const { xs, sm, md } = useContext(media);
  const isSmall = xs || sm || md;
  const { xsContentWidth, windowWidth } = useContext(app);
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const [viewState, setViewState] = useState<'signin' | 'verify' | 'signup' | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [skipSignupForm, setSkipSignupForm] = useState<boolean>(false);
  const history = useHistory();
  const authFlowType = useAppSelector(selectAuthFlowType);
  const userId = useAppSelector(selectUserId);

  // add property data
  const addPropertyUser = useAppSelector(selectAddPropertyUser);
  const addPropertyWorkspace = useAppSelector(selectAddPropertyWorkspace);

  // office request data
  const officeRequestUser = useAppSelector(selectOfficeRequestUser);
  const officeRequest = useAppSelector(selectOfficeRequest);
  const tourRequestWorkspaceId = useAppSelector(selectTourRequestWorkspaceId);
  const officeRequestTour = useAppSelector(selectOfficeRequestTour);

  // stb data
  const stbRequest = useAppSelector(selectStbRequest);
  const stbRequestUser = { email: stbRequest.email, firstName: stbRequest.first_name, lastName: stbRequest.last_name };

  const reduxUser =
    authFlowType === 'addProperty'
      ? { email: addPropertyUser.email, firstName: addPropertyUser.firstname, lastName: addPropertyUser.lastname }
      : authFlowType === 'officeRequest'
      ? officeRequestUser
      : authFlowType === 'stbRequest'
      ? stbRequestUser
      : undefined;

  const { email: qsEmail } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const [email, setEmail] = useState<string>(reduxUser?.email || (qsEmail as string) || '');
  const [firstname, setFirstname] = useState<string>(reduxUser?.firstName || '');
  const [lastname, setLastname] = useState<string>(reduxUser?.lastName || '');
  const [profileData, setProfileData] = useState<GoogleProfileData | undefined>(undefined);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { redirect } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const lastCustomerId = getItem('lastCustomerId');
  const imageUrl = 'https://assets.codi.com/sign-in-background_AK8KqtJRv.jpeg';

  useEffect(() => {
    if (!userId) return;

    // If mounting with a logged in user, redirect to home
    history.replace(HOME_PATH);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if we're in an auth flow but don't have the right data, switch back to default
    if (
      (authFlowType === 'officeRequest' && !officeRequest) ||
      (authFlowType === 'addProperty' && !addPropertyWorkspace) ||
      (authFlowType === 'stbRequest' && !stbRequest)
    ) {
      dispatch(authActions.setAuthFlowType('default'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewState === null) {
      setViewState('signin');
    }
  }, [lastCustomerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // handle google signup
    if (!!profileData?.email && !!email && firstname !== undefined && lastname !== undefined) {
      doSignup();
    }
  }, [email, profileData, firstname, lastname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // handle skip signup form
    if (skipSignupForm && !!email && firstname !== undefined && lastname !== undefined) {
      doSignup();
    }
  }, [skipSignupForm, email, firstname, lastname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = async ({
    user,
    token,
    authType
  }: AuthResponseData & { authType?: 'google' | 'email'; isSignup: boolean }) => {
    if (!user) return;

    setRequestState('in_progress');

    // Log in
    setToken(token);
    identify(user);

    if (authType) {
      apiTrack('User Login', { authType });
    }

    const updatedUser = await doAuthFlowActions({ user });

    const oneMinuteAgo = DateTime.now().minus({ minutes: 1 });
    const recentlyCreatedOfficeLead = updatedUser.office_leads.filter(
      ol => castToDateTime(ol.created_at) > oneMinuteAgo
    )[0];

    let search = '';

    if (recentlyCreatedOfficeLead) {
      redirect = `${CUSTOMER_OFFICE_REQUEST_PATH}/${recentlyCreatedOfficeLead.id}`;
      search = '?search=1';
    } else if (authFlowType === 'stbRequest') {
      const lastCreatedOffsite = updatedUser.offsites.slice(-1)[0];
      redirect = `${CUSTOMER_OFFSITES_PATH}/${lastCreatedOffsite.id}`;
    }

    setSubmitting(false);
    setRequestState('ready');
    setItem('lastUserId', user.id);
    const customerId = user.customer?.id;

    if (customerId) {
      setItem('lastCustomerId', customerId);
    }

    if (redirect) {
      const baseUrl = getBaseUrl();
      const newUrl = new URL(baseUrl + redirect);
      history.replace({ pathname: newUrl.pathname, search: search || newUrl.search });
    } else if (user.is_admin) {
      history.replace(ADMIN_PATH);
    } else if (user.user_types.some(ut => ['vendor_admin', 'vendor_employee'].includes(ut.name))) {
      history.replace(VENDOR_TASKS_PATH);
    } else if (authFlowType === 'addProperty') {
      history.replace(PROPERTIES_PATH);
    } else {
      history.replace(HOME_PATH);
    }
  };

  const onSigninSuccess = (data: AuthResponseData) => {
    const { user, token, profile_data } = data;

    if (!!user) {
      handleLogin({ user, token, authType: 'google', isSignup: false });
    } else {
      // Token is short-lived "user creation" token
      setToken(token);
      if (profile_data && profile_data.email) {
        // Google sign up
        setEmail(profile_data.email);
        setFirstname(profile_data.firstname || reduxUser?.firstName || '');
        setLastname(profile_data.lastname || reduxUser?.lastName || '');
        setProfileData(profile_data as GoogleProfileData);
      } else if (!!reduxUser?.email && !!reduxUser.firstName && !!reduxUser.lastName) {
        setEmail(reduxUser.email);
        setFirstname(reduxUser.firstName);
        setLastname(reduxUser.lastName);
        setSkipSignupForm(true);
      } else {
        // Sign up
        setSubmitting(false);
        setViewState('signup');
      }
    }
  };

  const handleVerificationSuccess = ({ user, token }: AuthResponseData) => {
    setRequestState('in_progress');

    if (!!user) {
      handleLogin({ user, token, authType: 'email', isSignup: false });
    } else {
      setToken(token);
      if (!!email && !!firstname && !!lastname) {
        setSkipSignupForm(true);
      } else {
        setViewState('signup');
        window.scrollTo(0, 0);
      }
      setRequestState('ready');
    }
  };

  const doSignup = () => {
    setSubmitting(true);

    if (!email || firstname === undefined || lastname === undefined) {
      setSubmitting(false);
      return;
    }

    const isGoogleAuth = !!profileData?.email;

    const userParams: CreateUserParams = {
      image_url: isGoogleAuth ? (profileData as GoogleProfileData)?.image_url : undefined,
      user: {
        firstname,
        lastname,
        email,
        google_id: isGoogleAuth ? (profileData as GoogleProfileData)?.google_id : undefined,
        google_data: isGoogleAuth ? (profileData as GoogleProfileData) : undefined
      },
      pathname
    };

    createUser(userParams)
      .then(({ data }) => {
        onSignupSuccess(data);
      })
      .catch((_error: ApiErrorResponse) => {
        setSubmitting(false);
      });
  };

  const onSignupSuccess = async ({ user, token }: CreateUserResponse) => {
    setTimeout(() => {
      apiTrack('User Created', {
        authType: user.google_id ? 'google' : 'email'
      });
    }, 500);

    await handleLogin({ user, token, isSignup: true });
  };

  const doAuthFlowActions = async ({ user }: { user: User }) => {
    if (authFlowType === 'addProperty') {
      await updateUser({ id: user.id, is_workspace_creator: true });

      if (!isCreatableProperty(addPropertyWorkspace)) {
        dispatch(addPropertyActions.reset());
        return user;
      }
      setRequestState('in_progress');

      await createWorkspace({
        workspace: addPropertyWorkspace
      });

      dispatch(addPropertyActions.reset());

      // refetch user to get updated workspace roles
      const [{ data: updatedUser }, { data: updatedUserV2 }] = await Promise.all([
        getUser({ id: user.id }),
        getUserV2({ userId: user.id })
      ]);
      dispatch(userActions.setUser(updatedUser));
      dispatch(v2UserActions.setUser(updatedUserV2));
      dispatch(tenancyActions.setSalesTaxRate(updatedUserV2.sales_tax_rate));
      setRequestState('ready');

      return updatedUser;
    } else if (authFlowType === 'officeRequest') {
      if (!isCreatableOfficeRequest(officeRequest)) {
        dispatch(officeRequestActions.reset());
        return user;
      }
      setRequestState('in_progress');

      const { data } = await createOfficeLead({
        userId: user.id,
        officeLead: transformOfficeRequestStateToOfficeLead(officeRequest)
      });

      let officeLead = data;

      if (officeRequestTour) {
        const { data } = await createOfficeLeadTour({
          userId: user.id,
          officeLeadId: officeLead.id,
          officeRequestTour: officeRequestTour,
          source: 'request'
        });

        officeLead = data;
      }

      const upcomingTour = getNextUpcomingTour(officeLead.tours);

      if (upcomingTour && tourRequestWorkspaceId) {
        const { data } = await createTourDestinationRequest({
          workspaceId: tourRequestWorkspaceId,
          officeLeadId: officeLead.id,
          userId: user.id,
          tourId: upcomingTour.id
        });

        officeLead = data;
      }

      dispatch(officeRequestActions.reset());

      const [{ data: updatedUser }, { data: updatedUserV2 }] = await Promise.all([
        getUser({ id: user.id }),
        getUserV2({ userId: user.id })
      ]);
      dispatch(userActions.setUser(updatedUser));
      dispatch(v2UserActions.setUser(updatedUserV2));
      dispatch(tenancyActions.setSalesTaxRate(updatedUserV2.sales_tax_rate));
      dispatch(userActions.setUser(updatedUser));
      setRequestState('ready');

      return updatedUser;
    } else if (authFlowType === 'stbRequest') {
      if (!isCreatableShortTermBooking(stbRequest)) {
        dispatch(stbRequestActions.reset());
        return user;
      }
      setRequestState('in_progress');

      await createShortTermBooking({ ...stbRequest, contact_id: user.id });

      dispatch(stbRequestActions.reset());

      const [{ data: updatedUser }, { data: updatedUserV2 }] = await Promise.all([
        getUser({ id: user.id }),
        getUserV2({ userId: user.id })
      ]);
      dispatch(userActions.setUser(updatedUser));
      dispatch(v2UserActions.setUser(updatedUserV2));
      dispatch(tenancyActions.setSalesTaxRate(updatedUserV2.sales_tax_rate));
      setRequestState('ready');

      return updatedUser;
    } else {
      const [{ data: updatedUser }, { data: updatedUserV2 }] = await Promise.all([
        getUser({ id: user.id }),
        getUserV2({ userId: user.id })
      ]);
      dispatch(userActions.setUser(updatedUser));
      dispatch(v2UserActions.setUser(updatedUserV2));
      dispatch(tenancyActions.setSalesTaxRate(updatedUserV2.sales_tax_rate));
    }

    return user;
  };

  if (viewState === null) return null;

  const content = (
    <Layout direction="row" width="100%" height={isSmall ? '100vh' : undefined} marginTop={xs ? 120 : undefined}>
      <Layout
        width={isSmall ? '100%' : 720}
        minWidth={xs ? undefined : 720}
        maxWidth={xs ? undefined : 720}
        justify="center"
        align={xs ? undefined : 'center'}
        paddingX={xs ? undefined : 130}
        margin={xs ? undefined : '0 auto'}
      >
        {viewState !== 'signin' && <GoBack viewState={viewState} setViewState={setViewState} />}

        <Layout width="100%" direction="column" align={xs ? 'center' : undefined} paddingY={xs ? undefined : 8}>
          <Layout>
            <Link href="/" tabIndex={-1}>
              <Layout>
                <Logo color="blue-500" />
              </Layout>
            </Link>
          </Layout>

          {viewState === 'signin' && (
            <SignIn
              email={email}
              setEmail={setEmail}
              handleSuccess={onSigninSuccess}
              submitting={submitting || requestState !== 'ready'}
              setSubmitting={setSubmitting}
              setViewState={setViewState}
              authFlowType={authFlowType}
            />
          )}
          {viewState === 'verify' && (
            <Layout marginTop={xs ? 32 : 24}>
              <Verify
                handleVerificationSuccess={handleVerificationSuccess}
                email={email}
                disabled={submitting || requestState !== 'ready'}
              />
            </Layout>
          )}
          {viewState === 'signup' && (
            <SignUp
              email={email}
              firstname={firstname}
              lastname={lastname}
              submitting={submitting || requestState !== 'ready'}
              onSignupSuccess={onSignupSuccess}
              setFirstname={setFirstname}
              setLastname={setLastname}
            />
          )}
        </Layout>
      </Layout>
      {!isSmall && (
        <Layout
          width="60%"
          height="100vh"
          position="relative"
          __style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundAttachment: undefined,
            backgroundPosition: 'center',
            backgroundRepeat: 'norepeat',
            backgroundSize: 'cover'
          }}
        ></Layout>
      )}
    </Layout>
  );

  return xs ? (
    <Layout width="100%" justify="center">
      <Layout
        __style={{
          width: xsContentWidth,
          minHeight: 'calc(100vh)'
        }}
      >
        {content}
      </Layout>
    </Layout>
  ) : (
    <Layout width={windowWidth} overflow="hidden">
      <FullWidthBackground>{content}</FullWidthBackground>
    </Layout>
  );
};

export default Auth;
