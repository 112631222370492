import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_BANNER_HEIGHT, HEADER_Z_INDEX, Icon, Layout, Link, Text } from 'ds';
import { TENANT_ADMIN_PLATFORM_FEE_PROPOSALS_PATH } from 'routes';
import { actions as tenancyActions } from 'store/Tenancy';
import { selectPlatformFees } from 'store/Tenancy/selectors';
import { actions } from 'store/UI';
import { selectUIState } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserV2 } from 'store/v2/User/selectors';
import { isTenantAdminUser } from 'store/v2/User/utils';
import { getPlatformFees } from 'ux/Tenant/TenantAdmin/Subscription/requests';

interface Props {}

const TenantBanner: React.FC<Props> = () => {
  const platformFees = useAppSelector(selectPlatformFees);
  const user = useAppSelector(selectUserV2);
  const isTenantAdmin = !!(user && isTenantAdminUser(user));
  const { appBannerType } = useAppSelector(selectUIState);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const platformFeeProposalPath = TENANT_ADMIN_PLATFORM_FEE_PROPOSALS_PATH;
  const isPlatformFeeProposalPage = location.pathname.includes(platformFeeProposalPath);

  useEffect(() => {
    if (!user?.tenancy_id) return;

    getPlatformFees({ tenancyId: user.tenancy_id }).then(({ data: platformFees }) => {
      dispatch(tenancyActions.setPlatformFees(platformFees));
    });
  }, [dispatch, user?.tenancy_id]);

  const getBannerAttributes = () => {
    if (!user) return;

    if (!isTenantAdmin || !platformFees?.length || isPlatformFeeProposalPage) return;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const platformFeeProposal = platformFees.find(pf => {
      const startDate = new Date(pf.start_date + 'T00:00:00');
      startDate.setHours(0, 0, 0, 0);
      return pf.approver?.id === user.id && !pf.approved_at && startDate >= tomorrow;
    });

    if (!platformFeeProposal) return;

    return {
      type: 'platform_fee_proposal',
      platformFeeProposal
    } as const;
  };

  const bannerAttributes = getBannerAttributes();

  useEffect(() => {
    if (!bannerAttributes) {
      dispatch(actions.updateUIState({ appBannerType: null }));
    } else {
      dispatch(actions.updateUIState({ appBannerType: bannerAttributes.type }));
    }
  }, [bannerAttributes?.type]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!appBannerType) return null;

  if (!bannerAttributes) return null;

  let content: JSX.Element | null = null;

  if (bannerAttributes.type === 'platform_fee_proposal') {
    content = (
      <Link
        color="black"
        href={`${TENANT_ADMIN_PLATFORM_FEE_PROPOSALS_PATH}/${bannerAttributes.platformFeeProposal.id}`}
        opacity={false}
        fullWidth
      >
        <Layout color="gold-200" height={APP_BANNER_HEIGHT} align="center" justify="center">
          <Icon name="clock" stroke={2} color="black" size="xs" />
          <Layout marginLeft={8}>
            <Text size="body-xs" semibold>
              Your proposal is ready for review
            </Text>
          </Layout>
          <Layout marginLeft={20}>
            <Text size="body-xs" semibold>
              Review now
            </Text>
          </Layout>
          <Layout marginLeft={4} display="inline-flex">
            <Icon name="rightChevron" size="xs" color="black" />
          </Layout>
        </Layout>
      </Link>
    );
  }

  if (!content) return null;

  return (
    <Layout position="sticky" top={0} zIndex={HEADER_Z_INDEX}>
      {content}
    </Layout>
  );
};

export default TenantBanner;
