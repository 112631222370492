import { RootState } from 'store/store';

const selectProductSets = (state: RootState) => state.FurnitureAndEquipment.productSets;
const selectProductRentalCart = (state: RootState) => state.FurnitureAndEquipment.productRentalCart;
const selectProductRentalCartItems = (state: RootState) => state.FurnitureAndEquipment.productRentalCart?.items;
const selectProductSetRentalOrders = (state: RootState) => state.FurnitureAndEquipment.productSetRentalOrders;
const selectProductSetRentalInventory = (state: RootState) => state.FurnitureAndEquipment.productSetRentalInventory;
const selectHasFurnitureAndEquipment = (state: RootState) => {
  const inventory = state.FurnitureAndEquipment.productSetRentalInventory;
  return !!inventory && Object.keys(inventory).length > 0;
};
const selectSalesTaxRate = (state: RootState) => state.FurnitureAndEquipment.productRentalCart?.sales_tax_rate;
export {
  selectProductSets,
  selectProductRentalCart,
  selectProductRentalCartItems,
  selectProductSetRentalOrders,
  selectProductSetRentalInventory,
  selectHasFurnitureAndEquipment,
  selectSalesTaxRate
};
