import { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { media } from 'context';
import { Layout } from 'ds';
import { AI_ASSISTANT_PATH } from 'routes';
import { createUserAction } from 'shared/requests/user';
import { actions } from 'store/AIAssistant';
import { actions as userActions } from 'store/User';
import { selectLoggedInUserId, selectUser, selectUserActions } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import NuxPromptCard from './NuxPromptCard';
import { determineIntent, startThread } from '../AIAssistant/requests';
import MobileComingSoon from '../MobileComingSoon';
import { TENANT_WELCOME_NUX_ACTION } from '../constants';

import './nux.scss';

const TenantWelcomePage = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useContext(media);
  const existingUserActions = useAppSelector(selectUserActions);
  const userId = useAppSelector(selectLoggedInUserId);
  const history = useHistory();
  const user = useAppSelector(selectUser);
  const tenantWelcomeNUXFinished = !!existingUserActions.find(a => a.value === TENANT_WELCOME_NUX_ACTION);

  if (tenantWelcomeNUXFinished) {
    return <Redirect to={AI_ASSISTANT_PATH} />;
  }

  const handlePromptCardClick = async (input: string) => {
    const { data } = await startThread();
    dispatch(actions.addThread(data));
    dispatch(actions.addPendingThreadInteraction({ threadId: data.id, input }));
    history.push(`${AI_ASSISTANT_PATH}/${data.id}`);
    const { data: interaction } = await determineIntent({
      chatbotThreadId: data.id,
      message: input,
      intentGroup: 'tenancy_customers'
    });
    dispatch(actions.removePendingThreadInteraction({ threadId: data.id }));
    dispatch(actions.addInteraction({ interaction, threadId: data.id }));
    createUserAction({ userId, value: TENANT_WELCOME_NUX_ACTION }).then(({ data }) => {
      dispatch(userActions.updateUser({ user_actions: data }));
    });
  };

  if (isMobile) {
    return <MobileComingSoon />;
  }

  const nuxCards = (
    <Layout width="fit-content" height="100%" flex justify="center" align="center" gap={12} paddingTop={36}>
      <NuxPromptCard
        id="nux-card-1"
        description="How does Codi help me?"
        type="how_can_we_help"
        onClick={async () => {
          handlePromptCardClick('How does Codi help me?');
        }}
      />
      <NuxPromptCard
        id="nux-card-2"
        description="Explore pantry and supplies."
        type="pantry_and_supplies"
        onClick={async () => {
          handlePromptCardClick('Explore pantry and supplies.');
        }}
      />
      <NuxPromptCard
        id="nux-card-3"
        description="Tell me about office cleaning."
        type="office_cleaning"
        onClick={async () => {
          handlePromptCardClick('Tell me about office cleaning.');
        }}
      />
    </Layout>
  );

  if (!user) {
    return null;
  }

  return (
    <Layout width="100vw" height="100vh" flex justify="center" align="center">
      <div className="nux-animation" style={{ marginTop: '126px' }}>
        <span
          id="hi-user-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '8px'
          }}
        >
          <div
            id="hi-user"
            style={{
              fontSize: '48.76px',
              fontWeight: 700,
              lineHeight: '64px',
              background: 'linear-gradient(90deg, #0078FF 0%, #8E5FF4 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Hi, {user.firstname}.
          </div>
        </span>
        <div
          id="im-your-new-virtual-office-assistant"
          style={{
            opacity: 0,
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '64px',
            background: 'linear-gradient(90deg, #0078FF 0%, #8E5FF4 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          I'm your new virtual office assistant.
        </div>
        <div
          id="explore-a-topic-to-get-started"
          style={{
            opacity: 0,
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '64px',
            background: 'linear-gradient(90deg, #0078FF 0%, #8E5FF4 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          Explore a topic to get started.
        </div>
        <div>{nuxCards}</div>
      </div>
    </Layout>
  );
};

export default TenantWelcomePage;
