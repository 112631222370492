import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentMethod } from '@stripe/stripe-js';

import { BuildingUnit, Member, Payment, PlatformFee } from './types';

type TenancyState = {
  billing_email: string | null | undefined;
  default_payment_method: PaymentMethod | null | undefined;
  building_units: BuildingUnit[];
  members: Member[];
  payments: Payment[];
  platform_fees: PlatformFee[] | null;
  sales_tax_rate: number | null;
};

const initialState: TenancyState = {
  billing_email: undefined,
  default_payment_method: undefined,
  building_units: [],
  members: [],
  payments: [],
  platform_fees: null,
  sales_tax_rate: null
};

const tenancySlice = createSlice({
  name: 'tenancy',
  initialState,
  reducers: {
    setBillingEmail: (state, action: PayloadAction<string | null | undefined>) => {
      const billing_email = action.payload;
      state.billing_email = billing_email;
    },
    updateBillingEmail: (state, action: PayloadAction<Partial<string>>) => {
      if (state.billing_email) {
        state.billing_email = action.payload;
      }
    },
    setDefaultPaymentMethod: (state, action: PayloadAction<PaymentMethod | null | undefined>) => {
      const default_payment_method = action.payload;
      state.default_payment_method = default_payment_method;
    },
    updateDefaultPaymentMethod: (state, action: PayloadAction<Partial<PaymentMethod>>) => {
      if (state.default_payment_method) {
        state.default_payment_method = {
          ...state.default_payment_method,
          ...action.payload
        };
      }
    },
    setBuildingUnits: (state, action: PayloadAction<BuildingUnit[]>) => {
      const building_units = action.payload;
      state.building_units = building_units;
    },
    setMembers: (state, action: PayloadAction<Member[]>) => {
      state.members = action.payload;
    },
    addMember: (state, action: PayloadAction<Member>) => {
      state.members.push(action.payload);
    },
    updateMember: (state, action: PayloadAction<Partial<Member> & { id: number }>) => {
      const index = state.members.findIndex(member => member.id === action.payload.id);
      if (index !== -1) {
        state.members[index] = { ...state.members[index], ...action.payload };
      }
    },
    removeMember: (state, action: PayloadAction<{ id: number }>) => {
      state.members = state.members.filter(member => member.id !== action.payload.id);
    },
    setPayments: (state, action: PayloadAction<Payment[]>) => {
      const payments = action.payload;
      state.payments = payments;
    },
    setPlatformFees: (state, action: PayloadAction<PlatformFee[]>) => {
      const platform_fees = action.payload;
      state.platform_fees = platform_fees;
    },
    updatePlatformFee: (state, action: PayloadAction<Partial<PlatformFee> & { id: number }>) => {
      if (!state.platform_fees) {
        return;
      }

      const index = state.platform_fees.findIndex(platform_fee => platform_fee.id === action.payload.id);
      if (index !== -1) {
        state.platform_fees[index] = { ...state.platform_fees[index], ...action.payload };
      }
    },
    setSalesTaxRate: (state, action: PayloadAction<number | null>) => {
      state.sales_tax_rate = action.payload;
    }
  }
});

export const { actions } = tenancySlice;
export default tenancySlice.reducer;
