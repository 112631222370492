import React from 'react';
import classNames from 'classnames';

import { Color } from 'shared';

import { ReactComponent as LogoSvg } from 'shared/assets/logo.svg';

interface Props {
  color?: Color;
  size?: 'sm' | 'lg';
  width?: number;
  height?: number;
}

const Logo: React.FC<Props> = ({ size = 'lg', color = 'blue-500', width, height }) => {
  const sm = size === 'sm';

  const logoWidth = width || (sm ? 60 : undefined);
  const logoHeight = height || (sm ? 24 : undefined);

  return (
    <span
      style={{ paddingTop: sm ? 14 : undefined, paddingBottom: sm ? 20 : undefined }}
      className={classNames('Logo', `is-color-${color}`)}
    >
      <LogoSvg style={{ height: logoHeight, width: logoWidth }} />
    </span>
  );
};

export default Logo;
