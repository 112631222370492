import React, { useContext } from 'react';

import { getCityString } from 'helpers/bookings';
import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import HeroImage from 'components/HeroImage';
import HeroVideo from 'components/HeroVideo';

import { app } from 'context';
import { Icon, Layout, Link, Text } from 'ds';
import { formatUsd } from 'helpers';
import { ListingWorkspace } from 'shared';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

type Props = {
  workspace: ListingWorkspace;
  isSTB?: boolean;
  isProspectVersion: boolean;
  daysPerWeek?: number;
} & (
  | {
      handleRequestTour: VoidFunction;
      CTA?: never;
    }
  | { handleRequestTour?: never; CTA: JSX.Element }
);

const Hero: React.FC<Props> = ({
  workspace: {
    location_info: { neighborhood, submarket, market },
    address,
    name,
    short_name,
    monthly_price,
    daily_rate,
    cover_video,
    images
  },
  isSTB,
  CTA,
  isProspectVersion,

  daysPerWeek
}) => {
  const { contentWidth } = useContext(app);
  const photos = [...images];
  const isLoggedIn = !!useAppSelector(selectUser);

  if (!photos.length) return null;

  const content = (
    <Layout height="100%" direction="column" justify="flex-end" align="center" paddingBottom={24}>
      <Layout __style={{ maxWidth: Math.min(1120, contentWidth) }} width="100%" justify="center">
        <Layout
          __style={{
            background: 'linear-gradient(145.77deg, rgba(105, 105, 105, 0.7) 26.23%, rgba(99, 99, 99, 0.2) 77.87%)',
            backdropFilter: 'blur(40px)',
            WebkitBackdropFilter: 'blur(40px)',
            maxWidth: Math.min(1120, contentWidth),
            borderRadius: 32
          }}
          height={116}
          paddingY={24}
          align="center"
          paddingX={44}
          flexGrow={1}
        >
          <Layout direction="row" justify="space-between" align="center" minWidth={0} flexGrow={1}>
            <Layout __style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Layout direction="row" align="center">
                {!market && !submarket && !neighborhood && (
                  <Text size="body-sm" color="gray-100" scale>
                    {getCityString(address)}
                  </Text>
                )}
                {!!market && (
                  <Layout direction="row" align="center">
                    {!!market.url ? (
                      <Link href={market.url} target="_blank">
                        <Text size="body-sm" color="gray-100" scale>
                          {market.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text size="body-sm" color="gray-100" scale>
                        {market.name}
                      </Text>
                    )}
                  </Layout>
                )}
                {!!submarket && (
                  <Layout direction="row" align="center">
                    {!!market && (
                      <Layout paddingX={8} align="center">
                        <Icon name="rightChevron" size="xs" color="gray-100" />
                      </Layout>
                    )}
                    {!!submarket.url ? (
                      <Link href={submarket.url} target="_blank">
                        <Text size="body-sm" color="gray-100" scale>
                          {submarket.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text size="body-sm" color="gray-100" scale>
                        {submarket.name}
                      </Text>
                    )}
                  </Layout>
                )}
                {!!neighborhood && (
                  <Layout direction="row" align="center">
                    {(!!market || !!submarket) && (
                      <Layout paddingX={8} align="center">
                        <Icon name="rightChevron" size="xs" color="gray-100" />
                      </Layout>
                    )}
                    {!!neighborhood.url ? (
                      <Link href={neighborhood.url} target="_blank">
                        <Text size="body-sm" color="gray-100" scale>
                          {neighborhood.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text size="body-sm" color="gray-100" scale>
                        {neighborhood.name}
                      </Text>
                    )}
                  </Layout>
                )}
              </Layout>
              <Text size="headline-lg" color="white" ellipsis>
                {isProspectVersion ? short_name : name}
              </Text>
            </Layout>
            <Layout
              marginLeft={80}
              direction="row"
              justify="flex-end"
              align="center"
              __style={{ whiteSpace: 'nowrap' }}
            >
              {isSTB && daily_rate ? (
                <Layout direction="column" align="center" padding={12}>
                  <Text size="h5" color="white">
                    {formatUsd(daily_rate, false)} /day
                  </Text>
                </Layout>
              ) : (
                isLoggedIn &&
                !!monthly_price && (
                  <Text size="h6" semibold color="white">
                    {formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price, daysPerWeek })} /month
                  </Text>
                )
              )}
              <Layout marginLeft={24}>{CTA}</Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );

  if (cover_video) {
    return (
      <HeroVideo srcKey={cover_video.key} height={700}>
        {content}
      </HeroVideo>
    );
  }

  return (
    <HeroImage imageKey={photos[0].key} height={700}>
      {content}
    </HeroImage>
  );
};

export default Hero;
