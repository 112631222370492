import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { app, media } from 'context';
import { SnackbarMessage, actions } from 'store/UI';
import { getSnackbarMessages } from 'store/UI/selectors';

import Clickable from './Clickable';
import Icon from './Icon';
import Img from './Img';
import Layout from './Layout';
import Link from './Link';
import Text from './Text';
import TextButton from './TextButton';
import WorkspaceImg from './WorkspaceImg';
import { DS_CLASS_NAME, HEADER_Z_INDEX } from './constants';

export interface Props extends SnackbarMessage {
  index: number;
  type?: SnackbarType;
}

const SNACKBAR_DURATION = 5000; // 5 seconds
const PADDING_Y = 12;
const CONTENT_HEIGHT = 20;
const INTER_SNACKBAR_SPACE_Y = 16;
const IMG_SIZE = 48;

type SnackbarType = 'light' | 'dark';

const Snackbar: React.FC<Props> = ({
  index,
  value,
  id,
  duration = SNACKBAR_DURATION,
  negative = false,
  img,
  link,
  CTA,
  position = 'center',
  type = 'dark'
}) => {
  const [isExiting, setIsExiting] = useState<boolean>(false);
  const animatedDivRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { xs } = useContext(media);
  const { contentWidth, contentPaddingX } = useContext(app);
  const timeout1 = useRef<number>();
  const timeout2 = useRef<number>();
  const snackbarMessages = useSelector(getSnackbarMessages);
  const messageHeights = snackbarMessages.map(({ img }) => {
    return PADDING_Y * 2 + (img ? IMG_SIZE : CONTENT_HEIGHT);
  });

  const bottom = (xs ? 24 : 40) + messageHeights.slice(0, index).reduce((a, b) => a + b + INTER_SNACKBAR_SPACE_Y, 0);

  const animationEndHandler = () => {
    if (!isExiting) {
      hideSnackbar(duration);
    }
  };

  const hideSnackbar = (duration: number) => {
    timeout1.current = window.setTimeout(() => {
      setIsExiting(true);
      timeout2.current = window.setTimeout(() => {
        dispatch(actions.clearSnackbarMessage({ id }));
      }, 100);
    }, duration);
  };

  useEffect(() => {
    let animatedDivRefCopy = animatedDivRef.current;

    if (animatedDivRef.current) {
      animatedDivRefCopy?.addEventListener('animationend', animationEndHandler);
    }

    return () => {
      if (timeout1) {
        clearTimeout(timeout1.current);
      }
      if (timeout2) {
        clearTimeout(timeout2.current);
      }

      animatedDivRefCopy?.removeEventListener('animationend', animationEndHandler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      __className={classNames(DS_CLASS_NAME, 'Snackbar', isExiting ? 'exit' : 'enter')}
      position="fixed"
      borderRadius={8}
      borderWidth={type === 'light' ? 1 : undefined}
      borderColor={type === 'light' ? 'gray-50' : undefined}
      boxShadow={type === 'light' ? '0px 8px 24px 0px #0000001F' : undefined}
      bottom={bottom}
      {...(xs
        ? { __style: { width: contentWidth, left: contentPaddingX } }
        : position === 'center'
        ? { left: '50vw', minWidth: 348, transform: 'translateX(-50%)' }
        : { left: 24, minWidth: 348 })}
      zIndex={HEADER_Z_INDEX + 1_000}
      color={type === 'light' ? 'white' : 'black'}
      direction="row"
      justify="space-between"
      align="center"
      paddingY={PADDING_Y}
      paddingRight={24}
      paddingLeft={12}
      __ref={animatedDivRef}
    >
      <Layout align="center">
        {img &&
          (img.type === 'workspace' ? (
            <WorkspaceImg
              size="thumbnail"
              srcKey={img.srcKey}
              style={{ width: IMG_SIZE, height: IMG_SIZE, objectFit: 'cover', borderRadius: 8 }}
            />
          ) : (
            <Img
              srcKey={img.srcKey}
              alt=""
              width={96}
              style={{ width: IMG_SIZE, height: IMG_SIZE, objectFit: 'cover', borderRadius: 8 }}
            />
          ))}
        <Layout height={CONTENT_HEIGHT} align="center" marginLeft={img ? 8 : undefined}>
          {!img && (
            <Layout
              width={CONTENT_HEIGHT}
              height={CONTENT_HEIGHT}
              color={negative ? 'red-600' : type === 'light' ? 'purple-gradient' : 'green-500'}
              borderRadius="circular"
              justify="center"
              align="center"
              display="inline-flex"
            >
              <Icon
                size={negative ? 16 : 14}
                name={negative ? 'exclamation' : 'checkmark'}
                color={'white'}
                stroke={2}
              />
            </Layout>
          )}
          <Layout justify="flex-start" align="center" marginLeft={12} flexGrow={1}>
            <Text size="body-sm" color={type === 'light' ? 'black' : 'white'}>
              {value}
            </Text>
          </Layout>
        </Layout>
      </Layout>
      {link && (
        <Layout marginLeft={12}>
          <Link
            href={link.href}
            onClick={() => {
              window.setTimeout(() => dispatch(actions.clearSnackbarMessage({ id })), 300);
            }}
          >
            <Text size="body-xs" color="white" semibold>
              {link.text}
            </Text>
          </Link>
        </Layout>
      )}
      {CTA ? (
        <TextButton
          color={type === 'light' ? 'black' : 'white'}
          text={CTA.text}
          onClick={() => {
            CTA.onClick();
            window.setTimeout(() => dispatch(actions.clearSnackbarMessage({ id })), 300);
          }}
          textSize="body-xs"
          semibold
        />
      ) : (
        <Clickable name="snackbar-close-button" onClick={() => hideSnackbar(0)} style={{ display: 'inline-flex' }}>
          <Icon name="close" size={CONTENT_HEIGHT} color="gray-200" />
        </Clickable>
      )}
    </Layout>
  );
};

export default Snackbar;
