import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

export const selectUserV2 = (state: RootState) => state.v2.User.user;

export const selectUserTypesNames = createSelector(
  selectUserV2,
  user => user?.user_types?.map(userType => userType.name) || []
);
export const selectHasVendorUserTypes = createSelector(selectUserTypesNames, userTypes =>
  userTypes.some(type => ['vendor_employee', 'vendor_admin'].includes(type))
);
export const selectHasVendorAdminType = createSelector(selectUserTypesNames, userTypes =>
  userTypes.includes('vendor_admin')
);
export const selectHasPlanUserTypes = createSelector(selectUserTypesNames, userTypes =>
  userTypes.some(type => ['plan_admin', 'plan_employee'].includes(type))
);
export const selectHasPlanAdminType = createSelector(selectUserTypesNames, userTypes =>
  userTypes.includes('plan_admin')
);
export const selectHasTenantUserTypes = createSelector(selectUserTypesNames, userTypes =>
  userTypes.some(type => ['tenant_admin', 'tenant_employee'].includes(type))
);
export const selectHasTenantAdminType = createSelector(selectUserTypesNames, userTypes =>
  userTypes.includes('tenant_admin')
);
export const selectTenancyId = createSelector(selectUserV2, user => user?.tenancy_id);
