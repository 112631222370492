import { TASK_TYPES } from 'shared/constants';
// TODO: Do all serializer responses actually have optional keys or should | null be used instead of ?:?
// Add serializer name to API response types?

// Includes all of the possible properties of a serialized workspace.
// TODO: make more variations or correct properties to account for instance options that are set in the serializers.

export interface Building {
  id: number;
  address: BuildingAddress;
  floors: BuildingFloor[];
  people: BuildingPerson[];
}

export interface BuildingFloor {
  id: number;
  name: string;
  level: number;
  building_units: BuildingUnit[];
}

export interface BuildingUnit {
  id: number;
  building_id: number;
  name: string;
  address: BuildingUnitAddress;
  rentable_square_feet: number;
  usable_square_feet: number;
  floor_name: string;
  building_unit_tenancy: BuildingUnitTenancy | null;
  // TODO: sort this out
  building_unit_tenancies: BuildingUnitTenancy[];
}

export interface BuildingUnitTenancy {
  tenancy_id: number;
  customer: { id: number; name: string };
  start_date: string;
  end_date: string | null;
}

export interface BuildingPerson {
  id: number;
  user_id: number;
  avatar_url: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string | null;
  verified: boolean;
  type: BuildingUserType | null;
}

export type BuildingUserType = 'Landlord' | 'Broker' | 'Property Manager' | 'Super';

export interface BuildingUnitAddress {
  id: number;
  line1: string;
  line2: string | null;
  neighborhood: string | null;
  city: string;
  region: string;
  postal_code: string;
  country: string;
  lat: number;
  lon: number;
  time_zone_id: string;
  walk_score: null | WalkScore;
}

export interface BuildingAddress extends Omit<BuildingUnitAddress, 'line2'> {}

// All properties without nullability
export interface BaseWorkspace {
  id: number;
  name: string;
  vanity_name: string;
  short_name: string;
  slug: string;
  square_feet: number;
  num_meeting_rooms: number;
  capacity: number;
  restrooms: number;
  description: string;
  published: boolean;
  listing_status: ListingStatus;
  property_type: PropertyType;
  workspace_features: WorkspaceFeature[];
  address: WorkspaceAddress;
  cover_video: Image;
  tour_video: Image;
  images: Image[];
  floor_plans: Image[];
  plan_id: number;
  first_hosting_availability: string;
  access_info: AccessInfo;
  distance: number;
  matterport_id: string;
  workspace_price: WorkspacePrice;
  workspace_cleaning_config: WorkspaceCleaningConfig;
  neighborhood_avg_per_sq_ft: number;
  monthly_price: number;
  building_class: BuildingClassType;
  notes: string;
  has_storage: boolean;
  spaces: Space[];
  first_listed_at: string;
  last_listed_at: string;
  is_recommended: boolean;
  is_hot: boolean;
  is_new: boolean;
  availability_status: AvailabilityStatus;
  availability_start_date: string;
  reservations: Reservation[];
  people: WorkspacePerson[];
  line_items: LineItem[];
  wifis: Wifi[];
  available_long_term: boolean;
  available_short_term: boolean;
  daily_rate: number;
  payout_account: {
    id: number;
    user_id: number;
    default_rev_share_percentage: number;
  };
  external_listing_url: string;
  breather_url: string;
  peerspace_url: string;
  liquidspace_url: string;
  is_reserved: boolean;
  instructions: WorkspaceInstruction[];
  access_type: string;
  business_hours: string;
  vendor_access_code: string | null;
  licensor_id: number | null;
  licensor_name: string | null;
  created_at: string;
  creator_id: number | null;
  hubspot_link: string | null;
  monthly_once_per_week_cleaning_price: number | null;
  monthly_once_per_week_touch_up_price: number | null;
}

// A saved workspace from WorkspaceSerializer
// TODO: remove
export interface Workspace
  extends Pick<
      BaseWorkspace,
      | 'access_info'
      | 'address'
      | 'square_feet'
      | 'capacity'
      | 'cover_video'
      | 'tour_video'
      | 'images'
      | 'floor_plans'
      | 'id'
      | 'listing_status'
      | 'property_type'
      | 'published'
      | 'restrooms'
      | 'has_storage'
      | 'workspace_features'
      | 'monthly_price'
      | 'spaces'
      | 'people'
      | 'line_items'
      | 'wifis'
      | 'workspace_price'
      | 'workspace_cleaning_config'
      | 'neighborhood_avg_per_sq_ft'
      | 'available_long_term'
      | 'available_short_term'
      | 'daily_rate'
      | 'external_listing_url'
      | 'instructions'
      | 'is_recommended'
      | 'created_at'
      | 'creator_id'
    >,
    NullablePick<
      BaseWorkspace,
      | 'description'
      | 'first_hosting_availability'
      | 'building_class'
      | 'name'
      | 'vanity_name'
      | 'short_name'
      | 'slug'
      | 'matterport_id'
      | 'notes'
      | 'first_listed_at'
      | 'last_listed_at'
      | 'reservations'
      | 'payout_account'
      | 'breather_url'
      | 'peerspace_url'
      | 'liquidspace_url'
      | 'access_type'
      | 'business_hours'
      | 'vendor_access_code'
      | 'licensor_id'
      | 'licensor_name'
      | 'hubspot_link'
      | 'monthly_once_per_week_cleaning_price'
      | 'monthly_once_per_week_touch_up_price'
    > {}

export interface Property
  extends Pick<
      BaseWorkspace,
      | 'access_info'
      | 'address'
      | 'square_feet'
      | 'capacity'
      | 'images'
      | 'id'
      | 'listing_status'
      | 'property_type'
      | 'published'
      | 'restrooms'
      | 'has_storage'
      | 'workspace_features'
      | 'monthly_price'
      | 'spaces'
      | 'people'
    >,
    NullablePick<
      BaseWorkspace,
      | 'description'
      | 'first_hosting_availability'
      | 'name'
      | 'slug'
      | 'notes'
      | 'first_listed_at'
      | 'last_listed_at'
      | 'reservations'
    > {}

export interface Listing
  extends Pick<
      BaseWorkspace,
      | 'capacity'
      | 'distance'
      | 'id'
      | 'images'
      | 'name'
      | 'slug'
      | 'listing_status'
      | 'daily_rate'
      | 'workspace_features'
      | 'availability_status'
      | 'available_long_term'
      | 'available_short_term'
      | 'is_new'
      | 'is_hot'
      | 'is_reserved'
      | 'is_recommended'
    >,
    NullablePick<
      BaseWorkspace,
      | 'short_name'
      | 'square_feet'
      | 'first_listed_at'
      | 'num_meeting_rooms'
      | 'monthly_price'
      | 'availability_start_date'
    > {
  bookings: { id: number; user_id: number; avatar_url: string | null }[];
  address: WorkspaceAddressPublic;
  is_new: boolean;
}

export interface WorkspacePerson {
  id: number; // this is the user id
  avatar_url: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string | null;
  verified: boolean;
  creator: boolean;
  workspace_user_id: number | null;
  licensor_user_id: number | null;
  type: WorkspaceUserType | null;
  roles: (WorkspaceUserRole & LicensorUserRole)[];
}

export type WorkspaceUserRole = 'listing_admin' | 'tour_contact';
export type WorkspaceUserType = 'Landlord' | 'Broker' | 'Property Manager' | 'Super' | 'Sublessor';

export interface CustomerUserWorkspace
  extends Pick<BaseWorkspace, 'id' | 'address' | 'capacity' | 'images' | 'name' | 'short_name'> {
  checkin_instructions: CheckinInstruction[];
  wifis: Wifi[];
  reservable_type: null | 'offsite' | 'reservation';
}

export interface ListingWorkspace
  extends Pick<
      BaseWorkspace,
      | 'access_info'
      | 'square_feet'
      | 'capacity'
      | 'plan_id'
      | 'description'
      | 'distance'
      | 'first_hosting_availability'
      | 'id'
      | 'cover_video'
      | 'tour_video'
      | 'images'
      | 'floor_plans'
      | 'listing_status'
      | 'property_type'
      | 'name'
      | 'published'
      | 'restrooms'
      | 'slug'
      | 'workspace_features'
      | 'matterport_id'
      | 'spaces'
      | 'has_storage'
      | 'first_listed_at'
      | 'last_listed_at'
      | 'availability_status'
    >,
    NullablePick<BaseWorkspace, 'monthly_price' | 'availability_start_date'> {
  address: WorkspaceAddressPublic;
  nearby_workspaces: ListingWorkspace[] | null;
  daily_rate: number | null;
  short_name: string | null;
  location_info: {
    neighborhood: ListingLocationInfo | null;
    submarket: ListingLocationInfo | null;
    market: ListingLocationInfo | null;
  };
}

export interface ListingLocationInfo {
  name: string;
  url: string | null;
}

export type BuildingClassType = 'A' | 'B' | 'C';

export interface Space {
  id: number;
  type: string;
  square_feet: number | null;
  capacity: number;
  furniture_status: FurnitureStatus;
  is_outside: boolean;
  is_private: boolean;
  workstations: Workstation[];
}

export type FurnitureStatus = 'furnished' | 'changeable' | 'unfurnished';

export interface Workstation {
  id: number;
  type: string;
  description: string | null;
  capacity: number;
}

export interface CustomerUser {
  id: number;
  user_id: number;
  name: string;
  phone: string;
  calling_code: string;
  email: string;
  plans: Pick<Plan, 'id'>[];
  is_admin: boolean;
}

export interface PlanTerms {
  id: number;
  initial_amount_monthly: number;
  initial_term_length_months: number;
  payment_interval_months: number;
  renews_automatically: boolean;
  notice_period_days: number;
  percent_renewal_increase: number;
  renewal_term_length_months: number;
  contract_sent_date: string | null;
  contract_signed_date: string | null;
  retainer: Retainer | null;
  security_deposit: SecurityDeposit | null;
  line_items: LineItem[];
  contract_signer: ContractSigner | null;
  contract_addenda: ContractAddendum[];
}

export interface ContractAddendum {
  id: number;
  position: number;
  filename: string;
  service_url: string;
}

export interface ContractSigner {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
}

export interface Retainer {
  amount: number;
  payment_completion_date: string | null;
  payment_status: string | null;
  stripe_invoice_id: string | null;
}

export interface SecurityDeposit {
  amount: number;
  payment_completion_date: string | null;
  payment_status: string | null;
  refund_amount: number | null;
  refund_completion_date: string | null;
  refund_notes: string | null;
  stripe_invoice_id: string | null;
  stripe_refund_id: string | null;
}

export interface Tenancy {
  customer: CompactCustomer;
}

export interface Plan {
  id: number;
  customer_id: number;
  customer_name: string;
  start_date: string;
  end_date: string | null;
  notice_date: string | null;
  next_billing_date: string | null;
  next_renewal_date: string | null;
  active_term_end_date: string | null;
  user_ids: number[];
  reserved_workspaces: Reservation[];
  payment_completion_date: string;
  plan_terms: PlanTerms | null;
  line_items: LineItem[];
  add_on_orders: AddOnOrder[];
  payments: PlanPayment[];
  deep_cleaning_price: number | null;
}

export interface AddOnOrder {
  id: number;
  customer_id: number;
  plan_id: number;
  order_number: string;
  add_ons: AddOn[];
  approver: AddOnOrderApprover | null;
  sent_at: string | null;
  approved_at: string | null;
  customer_url: string | null;
}

export interface AddOn
  extends Pick<
    LineItem,
    | 'id'
    | 'product'
    | 'description'
    | 'quantity'
    | 'amount'
    | 'total_amount'
    | 'recurring'
    | 'start_date'
    | 'end_date'
    | 'task'
    | 'line_item_referenceable_id'
    | 'line_item_referenceable_type'
    | 'bill_to'
    | 'pay_to'
    | 'payout_completion_date'
    | 'payment_completion_date'
    | 'task_id'
    | 'position'
    | 'days_of_week'
    | 'task'
    | 'line_item_consumables'
  > {
  _destroy?: boolean;
}

export interface CustomerAddOnOrder extends Omit<AddOnOrder, 'add_ons' | 'customer_id'> {
  add_ons: CustomerAddOn[];
  status: AddOnOrderStatus;
}

export type AddOnOrderStatus = 'pending' | 'completed';

export interface CustomerAddOn
  extends Pick<
    LineItem,
    'id' | 'quantity' | 'amount' | 'total_amount' | 'description' | 'recurring' | 'start_date' | 'end_date' | 'task_id'
  > {
  product_id: number | null;
}

export interface AddOnOrderApprover {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
}

export interface LineItem {
  id: number;
  line_item_referenceable_id: number;
  line_item_referenceable_type: 'AddOnOrder' | 'Plan' | 'PlanTerms' | 'Workspace' | 'Reservation';
  quantity: number;
  amount: number;
  total_amount: number;
  description: string | null;
  bill_to: 'customer' | 'vendor' | null;
  pay_to: 'customer' | 'vendor' | null;
  recurring: boolean;
  start_date: string | null;
  end_date: string | null;
  payment_completion_date: string | null;
  payout_completion_date: string | null;
  product: SimpleProduct | null;
  task_id: number | null;
  task: VendorTask | null;
  position: number;
  days_of_week: number[] | null;
  line_item_consumables: AdminLineItemConsumable[];
  _destroy?: boolean;
}

export interface AdminLineItemConsumable {
  id: number;
  consumable: Pick<Consumable, 'id' | 'name' | 'unit_type'>;
  monthly_allocation: number;
  position: number;
  _destroy?: boolean;
}

export interface Consumable {
  id: number;
  product_id: number;
  name: string;
  unit_type: string;
  monthly_allocation_per_sqft: number;
  consumable_category_id: number;
  image: Image;
  description: string | null;
}

export interface SimpleProduct {
  id: number;
  name: string;
  description: string | null;
  images: Image[];
  key: ProductKey | null;
  category: ProductCategory;
}

export interface CustomerProduct {
  id: number;
  name: string;
  description: string | null;
  is_published: boolean;
  images: Image[];
  category: ProductCategory;
  display_price: number;
  key: ProductKey | null;
  product_children: ProductChild[];
}

export interface ConsumableCategory {
  id: number;
  name: string;
}

export type ProductCategory = 'workstation' | 'services' | 'kitchen_and_dining' | 'conference' | 'lounge';

export interface ProductChild {
  id: number;
  quantity: number;
  product: CustomerProduct;
}

export type ProductKey =
  | 'deep_cleaning'
  | 'standard_desk'
  | 'electric_standing_desk'
  | 'phone_booth'
  | 'weekly_touch_up'
  | 'weekly_cleaning'
  | 'beverage_service';

export interface PlanPayment {
  id: number;
  period_start: string;
  period_end: string;
  amount: number;
  payment_status: string | null;
  stripe_invoice_id: string;
}

export type ConsumablesProductType = {
  id: number;
  name: string;
  image: string | null;
  consumables_product_subtypes: ConsumablesProductSubType[];
};

export type ConsumablesProductCategory = {
  id: number;
  name: string;
  consumables_product_types: ConsumablesProductType[];
};

export type ConsumablesProductSubType = {
  id: number;
  name: string;
};

export type ProductSet = {
  id: number;
  created_at: string;
  delivery_business_days: number;
  description: string;
  available_service_id: number;
  name: string;
  items_subtotal_cents: number;
  items_subtotal_after_discounts_cents: number;
  items_subtotal_cents_monthly: number;
  items_subtotal_after_discounts_cents_monthly: number;
  updated_at: string;
  image: Image;
  product_set_items: ProductSetItem[];
  one_time_charges_total_amount_cents: number;
  has_given_furniture_purchase_inquiry: boolean;
};

export type ProductRentalCartItem = {
  id: number;
  created_at: string;
  product_set: ProductSet;
  quantity: number;
};

export type DraftProductRentalCartItem = {
  product_set_id: number;
  quantity: number;
};

export type ProductRentalCart = {
  id: number;
  created_at: string;
  items: ProductRentalCartItem[];
  cart_items_subtotal_after_discounts_cents_monthly: number;
  sales_tax_rate: number;
};

export type ProductSetRentalInventory = Record<string, { product_set: ProductSet; quantity: number }>;

export type ProductSetRentalOrder = {
  id: number;
  created_at: string;
  product_set_rental_groups: ProductSetRentalGroup[];
  delivered_date: string | null;
  recurring_monthly_amount_cents: number;
  total_amount_cents_saved: number;
};

export type ProductSetRentalGroup = {
  id: number;
  created_at: string;
  product_set_rentals: ProductSetRental[];
  status: ProductSetRentalGroupStatus;
  delivery_date: string;
  delivered_date: string;
  one_time_charges_total_amount_cents: number;
  has_given_feedback: boolean;
  product_set_rental_order_id: number;
};

export type ProductSetRentalGroupStatus = 'pending' | 'scheduled' | 'completed';
export type ProductSetRentalGroupTask = {
  id: number;
  created_at: string;
  product_set_rental_group_id: number;
};

export type ProductSetRental = {
  id: number;
  start_date: string | null;
  end_date: string | null;
  product_set_rental_items: ProductSetRentalItem[];
  product_set_rental_subtotal_discounts: ProductSetRentalSubtotalDiscount[];
  product_set_rental_one_time_charges: ProductSetRentalOneTimeCharge[];
  product_set: ProductSet;
  monthly_amount_cents: number;
  one_time_amount_cents: number;
};

export type ProductSetRentalItem = {
  id: number;
  product_item_id: number;
  product_set_rental_id: number;
  quantity: number;
  unit_amount_cents: number;
  product_set_rental_item_discounts: ProductSetRentalItemDiscount[];
};

export type ProductSetRentalItemDiscount = {
  id: number;
  name: string;
  unit_discount_amount_cents: number;
  product_set_rental_item_id: number;
};

export type ProductSetRentalSubtotalDiscount = {
  id: number;
  created_at: string;
  amount_cents: number;
  product_set_rental_id: number;
};

export type ProductSetRentalOneTimeCharge = {
  id: number;
  created_at: string;
  amount_cents: number;
  product_set_rental_id: number;
};

export type ProductSetItem = {
  id: number;
  created_at: string;
  name: string;
  unit_amount_cents: number;
  updated_at: string;
  vendor_id: number;
  product_item: ProductItem;
  product_set_one_time_charges: ProductSetOneTimeCharge[];
};

export type ProductSetOneTimeCharge = {
  id: number;
  name: string;
  amount_cents: number;
};

export type ProductItem = {
  id: number;
  name: string;
  category: ConsumablesProductCategory;
  type: ConsumablesProductType;
};

export type ConsumablesProduct = {
  id: number;
  category: ConsumablesProductCategory;
  type: ConsumablesProductType;
  subtype: ConsumablesProductSubType;
  image_key: string | null;
  amount_cents: number;
  quantity: number;
  is_published: boolean;
  brand: string;
  manufacturer_code: string;
  mpn: string;
  detail: string;
  unit_type: string;
  units_per_package: number;
  package_type: string;
  updated_at: string;
  created_at: string;
  vendor_sku: string;
};

export type ConsumablesProductSubscription = {
  id: number;
  consumables_product: ConsumablesProduct;
  order_quantity: number;
  service_id: number;
  updated_at: string;
  created_at: string;
};

export type BulkUpdateConsumablesProductSubscription = {
  consumables_product_id: number;
  quantity: number;
};

export type DraftConsumablesProductSubscription = {
  id?: number;
  consumables_product: ConsumablesProduct;
  order_quantity: number;
  updated_at?: string;
  created_at?: string;
};

export type DraftConsumablesOrderItem = {
  id?: number;
  consumables_product: ConsumablesProduct;
  consumables_order_id?: number;
  quantity: number;
  updated_at?: string;
  created_at?: string;
};

export type ConsumablesOrder = {
  id: number;
  delivery_date: string;
  created_at: string;
  task_id: number | null;
  service_id: number;
  updated_at: string;
  consumables_order_items: ConsumablesOrderItem[];
  has_given_feedback: boolean;
};

export type ConsumablesOrderItem = {
  id: number;
  consumables_product: ConsumablesProduct;
  created_at: string;
  consumables_order_id: number;
  quantity: number;
  updated_at: string;
};

export type LineItemPaymentStatus = 'paid' | 'open' | 'processing' | 'draft';

export interface ReservationLineItem
  extends Omit<LineItem, 'line_item_referenceable_id' | 'line_item_referenceable_type'> {
  stripe_invoice_id: string | null;
  admin_stripe_invoice_url: string | null;
  payment_status: LineItemPaymentStatus | null;
  paid_at: string | null;
  due_date: string | null;
}

export type ReservationStatus = 'pending' | 'active' | 'past' | 'canceled';

export interface Reservation {
  id: number;
  plan_id?: number;
  customer:
    | {
        id: number;
        name: string;
      }
    | undefined;
  workspace: ReservationInfo;
  start_date: string;
  end_date: string | null;
  days_of_week: number[];
  plan_type: 'Reservation' | 'STB';
  status: ReservationStatus;
  plan_amount: number;
  payout_amount: number;
  payee: {
    user_id: number;
    name: string;
    payout_account_id: number | null;
    has_payment_method: boolean;
  } | null;
  payout_completion_date: string | null;
  active_line_items: ReservationLineItem[];
  task_schedules: TaskSchedule[];
}

export interface ReservationInfo {
  id: number;
  name: string;
  short_name: string;
  address: string;
  uses_building_janitorial: boolean;
  cleaning_days: number[];
  square_feet: number;
  building_unit_id: number;
}

export interface BookingWorkspace
  extends Pick<BaseWorkspace, 'id' | 'address' | 'images' | 'listing_status' | 'name' | 'slug' | 'access_info'> {}

export type BookingStatus = 'host_canceled' | 'guest_canceled' | 'inactive' | 'active';

export interface Booking {
  id: number;
  workspace_id: number;
  plan_id: number | null;
  user_id: number;
  date: string;
  status: BookingStatus;
  other_guests: OtherGuest[];
  canceled_at: string | null;
  workspace: BookingWorkspace;
}

export interface Address {
  id: number;
  line1: string | null;
  line2: string | null;
  neighborhood: string | null;
  city: string | null;
  region: string | null;
  postal_code: string | null;
  country: string | null;
  lat: number | null;
  lon: number | null;
  time_zone_id: string | null;
}

export interface WorkspaceAddressPublic
  extends Pick<
    WorkspaceAddress,
    'id' | 'neighborhood' | 'city' | 'region' | 'postal_code' | 'country' | 'lat' | 'lon' | 'walk_score' | 'line1'
  > {}

export interface WorkspaceAddress {
  id: number;
  line1: string;
  line2: string | null;
  neighborhood: string | null;
  city: string;
  region: string;
  postal_code: string;
  country: string;
  lat: number;
  lon: number;
  time_zone_id: string;
  walk_score: null | WalkScore;
}

export interface WalkScore {
  bike: {
    score: number;
    description: string;
  };
  walk: {
    score: number;
    description: string;
  };
  transit: {
    score: number;
    summary: string;
    description: string;
  };
  link: string;
}

export interface Image {
  id: number;
  key: string;
  filename: string;
  position: number | null;
  published: boolean;
  filesize: number;
}

export interface EditableImage {
  url?: string;
  id?: number;
  key?: string;
  file?: File;
  filename?: string;
  fileType?: string;
  position?: number | null;
  published?: boolean;
  filesize?: number;
  _destroy?: boolean;
}

export interface PetInfo {
  dogs: number;
  cats: number;
  other: {
    amount?: number;
    description?: string;
  };
}

export type TaskScheduleType = Extract<TaskType, 'cleaning' | 'touch-up' | 'restocking'>;

export interface TaskSchedule {
  id: number;
  building_unit_id: number;
  customer: {
    id: number;
    name: string;
  } | null;
  building_unit: CompactBuildingUnitWithCustomer;
  start_date: string;
  end_date: string | null;
  workspace: {
    id: number;
    name: string;
    address: WorkspaceAddress;
    loc: LatLng;
  } | null;
  day_of_week: number | null;
  weeks_of_month: TaskScheduleWeeksOfMonth;
  status: 'upcoming' | 'active' | 'deactivated';
  type: TaskScheduleType;
  created_at: string;
  assignees: TaskAssignee[];
  description: string | null;
  has_tasks: boolean;
  customer_edited_at: string | null;
  vendor_id: number;
  plan_id: number | null;
  reservation: {
    id: number;
    start_date: string;
    end_date: string;
  } | null;
  confirmation: TaskScheduleConfirmation | null;
}

export interface TaskScheduleConfirmation {
  user: CompactUser;
  confirmed_at: string;
}

export const WEEKS_OF_MONTH_OPTIONS = [
  { label: 'All', value: '*' },
  { label: '1st', value: '0' },
  { label: '2nd', value: '1' },
  { label: '3rd', value: '2' },
  { label: '4th', value: '3' },
  { label: 'Last', value: 'L' }
] as const;
export type TaskScheduleWeeksOfMonth = typeof WEEKS_OF_MONTH_OPTIONS[number]['value'];

export interface TaskAssignee {
  id: number;
  user_id: number;
  firstname: string;
  lastname: string;
  avatar_url: string;
  email: string;
}

export interface WorkspaceFeature {
  id: number;
  name: string;
}

export interface WorkspacePrice {
  id: number;
  asking_rent: number | null;
  rent: number;
  list_price: number;
  daily_rate: number | null;
}

export interface WorkspaceCleaningConfig {
  id: number;
  codi_cleaning_config: CodiCleaningConfig | null;
  building_cleaning_config: BuildingCleaningConfig | null;
}

export interface CodiCleaningConfig {
  id: number;
  service_provider: string | null;
  vendor_id: number | null;
  cleaning_description: string | null;
  touch_up_description: string | null;
  assignees: TaskAssignee[];
}

export interface BuildingCleaningConfig {
  id: number;
  cost_per_month: number | null;
  name: string | null;
  email: string | null;
  calling_code: string;
  phone: string | null;
  days_of_week: number[];
}

export interface AccessInfo {
  wifi_name: string | null;
  wifi_password: string | null;
  checkin_instructions: CheckinInstruction[];
  vendor_checkin_instructions: CheckinInstruction[];
  vendor_access_code: string | null;
}

export interface CheckinInstruction {
  sequence: number;
  description: string;
  photo_url: string | null;
}

export interface WorkspaceInstruction {
  id: number;
  name: string;
  value: string;
}

export interface Wifi {
  id: number;
  name: string;
  password: string;
}

export interface UpdatableWifi extends Partial<Wifi> {
  _destroy?: boolean;
}

export interface Country {
  code: string;
  name: string;
}

export type CreditCardBrand =
  | 'Visa'
  | 'MasterCard'
  | 'Discover'
  | 'JCB'
  | 'UnionPay'
  | 'American Express'
  | 'Diners Club';

export interface CardDetails {
  brand: CreditCardBrand | null;
  last_four: string | null;
}

export interface AuthErrorResponse {
  error: string;
  message: string;
  code?: string;
  type?: string;
}

export interface GoogleProfileData {
  email: string;
  google_id: string;
  firstname: string;
  lastname: string;
  image_url: string;
  locale: string;
  domain: string;
}

export interface AppleProfileData {
  email: string;
  apple_id: string;
  firstname?: string;
  lastname?: string;
}

export interface StripeData {
  account_id?: string;
  customer_id: string;
  refresh_token?: string;
}

export interface ReferralData {
  code: string;
  id: number;
  firstname: string;
  avatar_url: string;
}

export interface AuthResponseData {
  token: string;
  profile_data?: GoogleProfileData | AppleProfileData;
  user?: User;
  success?: boolean; // this is for when a user is changing their phone number
}

export interface CreateUserResponse {
  user: User;
  token: string;
}

export type NotificationCategory = 'reminders' | 'community' | 'support';
export type SectionLabel = 'reminders' | 'community & tips' | 'support';
export type NotificationType = 'email' | 'sms' | 'push';

export type Reminders = Record<NotificationType, boolean>;
export type Support = Record<NotificationType, boolean>;
export type Community = Record<NotificationType, boolean>;

export interface NotificationPreferences {
  id: number;
  user_id: number;
  reminders: Reminders;
  support: Support;
  community: Community;
  created_at: string;
  updated_at: string;
}

export interface CustomerAdminPlans {
  customer: {
    id: number;
    customer_user_id: number;
    name: string;
    email: string;
  };
  long_term_plans: CustomerAdminPlan[];
  active_workspace_ids: number[];
  payments_due: {
    type: 'retainer' | 'security_deposit';
    amount: number;
    due_date: string;
    invoice_url: string;
  }[];
}

export type CleaningTaskType = Extract<TaskType, 'cleaning' | 'touch-up'>;

export interface Cleaning {
  date: string;
  type: CleaningTaskType;
  frequency: 'weekly' | 'monthly' | 'one-time';
}

export interface DayCleaning {
  value: CleaningTaskType | null;
  price: number;
  day: WeeklyCleaningDay;
  wday: number;
}

export type WeeklyCleaningDay =
  | 'Mondays'
  | 'Tuesdays'
  | 'Wednesdays'
  | 'Thursdays'
  | 'Fridays'
  | 'Saturdays'
  | 'Sundays';

export type WeeklyCleaningSchedule = DayCleaning[];

export interface CustomerAdminPlan {
  id: number;
  start_date: string;
  end_date: string | null;
  contract_signed_date: string | null;
  contract_signer_id: number | null;
  workspace_id: number | null;
  days_of_week: number[] | null;
  uses_building_janitorial: boolean;
  term_length_months: number;
  included_cleaning_count: number;
  weekly_cleaning_and_touch_up_schedule: WeeklyCleaningSchedule;
  add_on_orders: CustomerAddOnOrder[];
  active_workspace_ids: number[];
  active_workspace_id: number | null;
  amount_monthly: number;
  next_billing_date: string | null;
  next_renewal_date: string | null;
  payment_interval_label: string;
  notice_date: string | null;
  feedback_requests: FeedbackRequest[];
  recent_and_upcoming_cleanings: Cleaning[];
  task_schedules: CustomerTaskSchedule[];
  furniture: CustomerAdminFurniture[];
  cart: Cart | null;
  deep_cleaning_price: number | null;
  monthly_once_per_week_cleaning_price: number | null;
  monthly_once_per_week_touch_up_price: number | null;
  line_item_consumables: LineItemConsumable[];
}

export interface LineItemConsumable {
  id: number;
  monthly_allocation: number;
  name: string | null;
  unit_type: string | null;
  line_item: LineItem;
  consumable_id: number;
}

export interface CustomerAdminFurniture {
  id: number;
  quantity: number;
  amount: number;
  description: string;
  product: CustomerProduct;
  included_count: number;
  start_date: string;
  end_date: string | null;
}

export interface CartItem {
  product: CustomerProduct;
  cart_id: number;
  quantity: number;
  unit_price: number;
  date: string | null;
  id: number;
  days_to_remove: number[];
  days_to_add: number[];
  product_to_remove_id: number | null;
}

export interface UnsavedCartItem {
  userId: number;
  cartId: number;
  date?: string;
  days_to_remove: number[];
  days_to_add: number[];
  productId: number;
  product_to_remove_id?: number;
}

export interface Cart {
  id: number;
  user_id: number;
  plan_id: number;
  items: CartItem[];
}

export interface CustomerTaskSchedule {
  id: number;
  day_of_week: number | null;
  weeks_of_month: TaskScheduleWeeksOfMonth;
  type: TaskScheduleType;
  start_date: string;
  end_date: string | null;
}

export type TaskType = typeof TASK_TYPES[number];
export type FeedbackRequestType = Extract<TaskType, 'cleaning' | 'touch-up' | 'restocking'>;

export interface FeedbackRequest {
  task_id: number;
  type: FeedbackRequestType;
  date: string;
  action: 'rated' | 'dismissed' | null;
}

export interface User {
  id: number;
  is_admin: boolean;
  is_workspace_creator: boolean;
  customer: Customer | null;
  phone: string;
  calling_code: string;
  email: string;
  firstname: string;
  lastname: string;
  avatar_url: string;
  google_id: string | null;
  apple_id: string | null;
  notification_preferences: NotificationPreferences;
  user_interaction: UserInteraction | null;
  user_actions: UserAction[];
  // TODO: move
  customer_admin_plans: CustomerAdminPlans | null;
  office_leads: OfficeLead[];
  referral_info: UserReferralInfo | null;
  offsites: Offsite[];
  offices: Office[];
  verified_at: string | null;
  licensor_roles: LicensorRole[];
  workspace_roles: WorkspaceRole[];
  listing_admin_workspaces: Workspace[];
  active_features: string[];
  hubspot_token: string;
  user_types: V2.UserType[];
}

export interface Vendor {
  id: number;
  name: string;
  category: string;
  admin: boolean;
  vendor_payout_account: string | null;
}

export interface CompactVendor extends Pick<Vendor, 'id' | 'name'> {}

export interface LicensorRole {
  id: number;
  name: string;
  roles: LicensorUserRole[];
}

export interface WorkspaceRole {
  id: number;
  type: WorkspaceUserType;
  roles: WorkspaceUserRole[];
  is_creator: boolean;
}

export type LicensorUserRole = 'payout_admin' | 'payout_viewer';

export interface Licensor {
  id: number;
  roles: LicensorUserRole[];
  workspaces: Workspace[];
  payout_account: PayoutAccount;
  payouts: Payout[];
}

export type TaskConfirmationStatus = 'pending' | 'confirmed' | 'rejected';

export interface VendorTaskListRow {
  id: number;
  assignees: VendorTaskAssignee[];
  reporter: VendorTaskReporter;
  title: string;
  type: string;
  min_due_date: string | null;
  max_due_date: string | null;
  min_time: string | null;
  max_time: string | null;
  scheduled_date: string | null;
  intended_scheduled_date: string | null;
  completed_at: string | null;
  canceled_at: string | null;
  status: TaskStatus;
  description: string | null;
  created_at: string;
  task_schedule_id: number | null;
  building_unit: {
    id: number;
    name: string;
    address: WorkspaceAddress;
  };
  confirmation_status: TaskConfirmationStatus;
}

export interface VendorTask {
  id: number;
  building_unit: {
    id: number;
    address: WorkspaceAddress;
    image_key: string;
    square_feet: number;
    building_id: number;
  };
  assignees: VendorTaskAssignee[];
  reporter: VendorTaskReporter;
  reporter_name: string;
  client: VendorTaskClient;
  client_name: string;
  workspace: VendorTaskWorkspace | null;
  type: string;
  min_due_date: string | null;
  max_due_date: string | null;
  min_time: string | null;
  max_time: string | null;
  scheduled_date: string | null;
  completed_at: string | null;
  canceled_at: string | null;
  status: TaskStatus;
  task_schedule_id: number | null;
  title: string;
  description: string | null;
  created_at: string;
  images: Image[];
  messages: Message[];
  watchers: { id: number; user: CompactUser }[];
  consumables_order_items: ConsumablesOrderItem[] | null;
  // TODO: serialize full parent task or parent task scheduled date?
  parent_task_scheduled_date: number | null;
  confirmation_status: TaskConfirmationStatus;
}

export type TaskStatus =
  | 'open'
  | 'unconfirmed'
  | 'scheduled'
  | 'scheduled_completable'
  | 'overdue'
  | 'completed'
  | 'canceled';

export interface VendorTaskAssignee {
  id: number;
  user_id: number;
  firstname: string;
  lastname: string;
  avatar_url: string;
  email: string;
}

export interface VendorTaskReporter {
  id: number;
  firstname: string;
  lastname: string;
  avatar_url: string;
  email: string;
}

export interface VendorTaskClient {
  id: number;
  name: string;
}

export interface VendorTaskWorkspace {
  id: number;
  name: string;
  address: WorkspaceAddress;
  image: Image | null;
  vendor_checkin_instructions: CheckinInstruction[];
  vendor_access_code: string | null;
}

export interface Message {
  id: number;
  author: MessageAuthor | null;
  author_email: string | null;
  liked: boolean | null;
  body: string;
  images: Image[];
  created_at: string;
  updated_at: string;
  emojis: MessageEmoji[];
}

export interface MessageAuthor extends CompactUser {
  customer: CompactCustomer | null;
}

export interface CompactUser {
  id: number;
  first: string;
  last: string;
  email: string;
  avatar_url: string;
  calling_code?: string | null;
  phone?: string | null;
  is_admin: boolean;
  verified_at: string | null;
}

export interface CompactCustomer {
  id: number;
  name: string;
  email: string;
  logo_url: string | null;
}

export interface CompactWorkspace {
  id: number;
  short_name: string;
  address: WorkspaceAddress;
  image: Image | null;
}

export interface CompactBuildingUnit {
  id: number;
  address: WorkspaceAddress;
  name: string;
  building_id: number;
  square_feet: number | null;
  image_key: string | null;
  rentable_square_feet: number | null;
  usable_square_feet: number | null;
}

export interface CompactBuildingUnitWithCustomer extends CompactBuildingUnit {
  customer: CompactCustomer | null;
}

export interface MessageEmoji {
  value: string;
  id: number;
  user: CompactUser;
  created_at: string;
}

export interface Offsite {
  id: number;
  status: OffsiteStatus;
  start_date: string;
  end_date: string;
  amount: number;
  // workspace: {
  //   id: number;
  //   short_name: string;
  //   address: WorkspaceAddress;
  //   images: Image[];
  //   floor_plans: Image[];
  //   tour_video: Image | null;
  //   matterport_id: string | null;
  // };
  customer: {
    id: number;
    name: string;
  };
  signed_at: string | null;
  signer_id: number | null;
  workspace_id: number;
  payments: OffsitePayment[];
  // DEPRECATED TODO: remove
  workspace_name: string;
  add_ons: OffsiteAddOn[];
  workspace_city_state: string;
  workspace_image_key: string;
}

export interface OffsitePayment {
  id: number;
  amount_cents: number;
  due_date: string;
  payment_status: OffsitePaymentStatus;
  stripe_invoice_id: string;
  line_items: OffsitePaymentLineItem[];
  type: 'stb_reservation_fee' | 'stb_add_ons';
  stripe_invoice_url: string;
  paid_at: string | null;
}

export interface OffsiteAddOn {
  id: number;
  amount: number;
  description: string;
}

export type OffsitePaymentStatus = 'open' | 'paid' | 'void' | 'processing' | 'failed';

export interface OffsitePaymentLineItem {
  id: number;
  amount_cents: number;
  description: string;
  line_item_id: number;
}

export type OffsiteStatus = 'pending' | 'confirmed' | 'canceled';

export interface Office
  extends Pick<BaseWorkspace, 'id' | 'address' | 'capacity' | 'images' | 'short_name' | 'instructions' | 'floor_plans'>,
    NullablePick<BaseWorkspace, 'tour_video' | 'matterport_id' | 'name' | 'access_type' | 'business_hours'> {
  checkin_instructions: CheckinInstruction[];
  wifis: Wifi[];
  reservable_type: null | 'offsite' | 'reservation';
  task_schedules: OfficeTaskSchedule[];
  start_date: string | null;
  end_date: string | null;
  active: boolean;
  upcoming: boolean;
}

export interface OfficeTaskSchedule extends Pick<TaskSchedule, 'id' | 'day_of_week' | 'weeks_of_month' | 'type'> {}

export interface UserReferralInfo {
  url: string;
  percentage_discount: number;
  max_amount: number;
}

export interface Customer {
  id: number;
  name: string;
  email: string;
  plan_start_date: string | null;
  domain_matches: string[] | null;
  coworkers: Coworker[];
}

export interface Coworker {
  id: number;
  email: string;
  avatar_url: string | null;
  firstname: string | null;
  lastname: string | null;
  verified_at: string | null;
  admin?: boolean;
}

export interface OfficeLead {
  id: number;
  market: string | null;
  locations: OfficeLeadLocation[];
  amenities: string[];
  days_per_week: number;
  head_count: number | null;
  min_monthly_budget: number | null;
  max_monthly_budget: number;
  move_in_date: string | null;
  num_meeting_rooms: number | null;
  other_amenities: string[];
  created_at: string;
  requester_id: number | null;
  customer_id: number;
  workspaces: OfficeLeadWorkspace[];
  collaborators: Coworker[];
  tours: OfficeLeadTour[];
  updated_at: string;
}

export interface OfficeLeadLocation {
  neighborhood: string | null;
  city: string;
  region: string;
  country: string;
  place_id: string;
  loc: LatLng;
  display?: string;
}

export interface OfficeLeadTour {
  id: number;
  starting_location: string | null;
  status: OfficeLeadTourStatus;
  date: string;
  starts_at: string;
  ends_at: string;
  guests: OfficeLeadTourGuest[];
  destinations: TourDestination[];
  destination_requests: TourDestinationRequest[];
}

export interface TourDestination {
  id: number;
  workspace: TourDestinationWorkspace;
  review: TourDestinationReview | null;
  date: string;
}

export interface TourDestinationRequest {
  id: number;
  workspace: TourDestinationWorkspace;
  user: { id: number };
}

export interface TourDestinationReview {
  id: number;
  workspace: TourDestinationWorkspace;
  response: TourDestinationReviewResponse | null;
}

export type TourDestinationReviewResponse = 'did_not_tour' | 'disliked' | 'liked';

export interface TourDestinationWorkspace {
  id: number;
  image: Image;
  short_name: string;
  address: WorkspaceAddress;
}

export type TourDestinationStatus = 'pending';

export type OfficeLeadTourStatus = 'pending' | 'scheduled' | 'completed';

export interface OfficeLeadTourGuest {
  id: number;
  firstname: string;
  lastname: string;
  avatar_url: string;
}

export type AvailabilityStatus = 'available' | 'not_available' | 'available_soon';

export interface OfficeLeadWorkspace {
  id: number;
  address: WorkspaceAddress;
  short_name: string;
  monthly_price: number;
  available_short_term: boolean;
  daily_rate: number | null;
  square_feet: number;
  capacity: number;
  workspace_features: WorkspaceFeature[];
  images: Image[];
  tour_video: Image | null;
  floor_plans: Image[];
  matterport_id: string | null;
  description: string;
  // is_reserved: boolean;
  num_meeting_rooms: number;
  has_storage: boolean;
  user_interactions: OfficeLeadWorkspaceUserInteraction[];
  availability_status: AvailabilityStatus;
  availability_start_date: string | null;
  first_hosting_availability: string;
  is_hot: boolean;
  is_new: boolean;
  is_recommended: boolean;
}

export interface OfficeLeadWorkspaceUserInteraction {
  // This is office_lead_workspaces.id
  id: number;
  workspace_id: number;
  user: {
    id: number;
    firstname: string;
    lastname: string;
    avatar_url: string;
  };
  added_at: string | null;
  proposer: OfficeLeadWorkspaceProposer | null;
  proposed_at: string | null;
  removed_at: string | null;
  rejected_at: string | null;
  status?: OfficeLeadWorkspaceStatus;
}

export type OfficeLeadWorkspaceStatus = 'proposed' | 'rejected' | 'added' | 'removed';
export interface OfficeLeadWorkspaceProposer {
  id: number;
  firstname: string;
  lastname: string;
  avatar_url: string;
}

export interface UserInteraction {
  ftux_dismissed: boolean;
  whats_next_dismissed: boolean;
  dashboard_office_options_viewed: boolean;
}

export interface UserAction {
  value: string;
  created_at: string;
}

export interface CreateUserInput {
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  calling_code?: string;
  google_id?: string;
  google_data?: GoogleProfileData;
  apple_id?: string;
  apple_data?: AppleProfileData;
}

export interface NotificationPreferencesAttributes {
  reminders: Reminders;
  support: Support;
  community: Community;
}

export interface OtherGuest {
  id: number;
  avatar_url: string;
  firstname: string;
}

export interface XhrErrorResponse {
  err: { [key: string]: string[] };
  status: number;
  type: string;
}

export interface ApiErrorResponse {
  data: { [key: string]: string[] };
  message: string;
  type: string; // TODO: make this union of hivenerror types?
  status: number;
}

export interface StartupResponse {
  countries: Country[];
  cities: City[];
  markets: Market[];
  force_version_update: boolean;
  min_version_number: string;
  web_redux_purge: boolean;
  web_redux_version_number: string;
  products: CustomerProduct[];
  consumable_categories: ConsumableCategory[];
  consumables: Consumable[];
}

export interface City {
  name: string;
  region: string;
  country: string;
  lat: number;
  lng: number;
}

export interface Market {
  name: string;
  bounds: {
    north: number;
    south: number;
    east: number;
    west: number;
  };
  hybrid_available: boolean;
}

// from ipbase.com
export interface Ip2LocationResponse {
  data: {
    ip: string;
    location: {
      city: {
        name: string;
        name_translated: string;
      };
      continent: {
        code: string;
        name: string;
        name_translated: string;
      };
      country: {
        alpha2: string;
        alpha3: string;
        calling_codes: string[];
        emoji: string;
        ioc: string;
        is_in_european_union: boolean;
        languages: {
          name: string;
          name_native: string;
        }[];
        name: string;
        name_native: string;
        timezones: string[];
      };
      latitude: number;
      longitude: number;
      region: {
        name: string;
        name_translated: string;
      };
      zip: string;
    };
    timezone: {
      code: string;
      gmt_offset: number;
      id: string;
      is_daylight_saving: true;
    };
  };
}

export type LatLng = {
  lat: number;
  lng: number;
};

export type Bounds = {
  ne: LatLng;
  sw: LatLng;
};

export interface UserCalendar {
  workspaces: CustomerUserWorkspace[];
  dates: UserCalendarDate[];
}

export interface UserCalendarDate {
  date: string;
  bookings: UserCalendarDateBooking[];
  workspaces: CustomerUserWorkspace[];
}

export interface UserCalendarDateBooking {
  id: number;
  workspace_id: number;
  user: UserCalendarDateWorkspaceBookingUser;
}

export interface UserCalendarDateWorkspaceBookingUser {
  id: number;
  firstname: string;
  avatar_url: string;
}

export type PayoutStatus =
  | 'needs_approval'
  | 'ready_to_send'
  | 'scheduled'
  | 'pending'
  | 'processing'
  | 'initiated'
  | 'completed'
  | 'issue'
  | 'failed'
  | 'canceled'
  | 'externally_paid';

export interface Payout {
  id: number;
  created_at: string;
  amount: number;
  description: string;
  status: PayoutStatus;
  delivery_type: 'ach' | 'check' | null;
  expected_on: string | null;
}

export interface PayoutMethod {
  routable_payment_method_id: string;
  type: 'bank' | 'address';
  delivery_method: 'ach' | 'check';
  is_primary: boolean;
  address: null | {
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    country: string;
    postal_code: string;
    print_business_name: string | null;
    print_name: string | null;
    state: string;
  };
  bank: null | {
    account_number: string;
    institution_name: string;
    routing_number: string;
    account_type: 'checking' | 'savings';
    logo_url: string;
  };
}

export interface PayoutAccount {
  id: number;
  payout_day: number;
  default_rev_share_percentage: number;
  payout_upcoming_month: boolean;
  routable_company_id: string;
  display_name: string;
  status: 'added' | 'invited';
  type: 'business' | 'personal';
  contact: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string | null;
  };
  payout_methods: PayoutMethod[];
}

export interface VendorPayoutAccount
  extends Omit<PayoutAccount, 'payout_day' | 'default_rev_share_percentage' | 'payout_upcoming_month'> {}

export type ListingStatus = 'rejected' | 'delisted' | 'pending' | 'in_review' | 'listed';
export type PropertyType = 'residential' | 'commercial' | 'mixed_use';
export type Status = 'approved' | 'pending' | 'rejected';
export type Environment = 'development' | 'staging' | 'production';

export interface JWT {
  data: { valid_client: boolean; env: Environment; user_id: number };
  exp: number;
  iss: string;
}

export type PartialPick<T, K extends keyof T> = {
  [P in K]?: T[P];
};

export type NullablePick<T, K extends keyof T> = {
  [P in K]: T[P] | null;
};

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type ValueOf<T> = T[keyof T];

export type ExtendPartialPick<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export type Updatable<T extends { id: string | number }> = Partial<Omit<T, 'id'>> & Pick<T, 'id'>;

export namespace V2 {
  export interface User {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    avatar_url: string;
    phone: string;
    calling_code: string;
    is_admin: boolean;
    verified_at: string | null;
    google_id: string | null;
    apple_id: string | null;
    active_features: string[];
    user_actions: UserAction[];
    user_types: UserType[];
    tenancy_id: number | null;
    tenancy_ux_override: boolean;
    sales_tax_rate: number | null;
  }

  export interface UserType {
    name: UserTypeName;
  }

  export type UserTypeName =
    | 'plan_admin'
    | 'plan_employee'
    | 'tenant_admin'
    | 'tenant_employee'
    | 'vendor_admin'
    | 'vendor_employee'
    | 'codi_admin'
    | 'licensor';
}

export interface Feedback {
  user_id: number;
  liked: boolean;
  created_at: string;
  message?: string;
}

export type EditableProductSetRentalGroupFeedback = {
  product_set_rental_group_id: number;
  user_id: number;
  liked: boolean;
  message?: string;
};
