import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { app, media } from 'context';
import Clickable from 'ds/Clickable';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { LayoutOverflow } from 'ds/types';
import { BLUE_500_HEX, Color } from 'shared';

interface Props {
  items: TabItem[];
  top?: number;
  children: JSX.Element[];
  initialSelectedIndex?: number;
  flexGrow?: number;
  menuMarginX?: number;
  textColor?: Color;
  pullLeftXs?: boolean;
  overflow?: LayoutOverflow;
  syncSelectedIndex?: boolean;
}

interface TabItem {
  label: string;
  count?: number;
  pathname?: string;
  search?: string;
  width?: string | number;
  onClick?: VoidFunction;
  tabTextColor?: Color | string;
  borderGradient?: string;
  semibold?: boolean;
}

const Tabs: React.FC<Props> = ({
  items,
  top,
  children,
  initialSelectedIndex = 0,
  flexGrow = 1,
  menuMarginX,
  textColor,
  pullLeftXs,
  overflow = 'auto',
  syncSelectedIndex = false
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedIndex);
  const history = useHistory();
  const { xs } = useContext(media);
  const { appPaddingX } = useContext(app);

  useEffect(() => {
    if (syncSelectedIndex) {
      setSelectedIndex(initialSelectedIndex);
    }
  }, [initialSelectedIndex, syncSelectedIndex]);

  return (
    <>
      <Layout
        {...(top === undefined ? {} : { position: 'sticky', top })}
        color="white"
        overflow={overflow}
        zIndex={100}
        borderBottom={!xs}
        {...(xs ? {} : { align: 'center' })}
        marginX={menuMarginX}
      >
        <Layout
          align="center"
          overflow="auto"
          flexGrow={flexGrow}
          borderBottom={xs}
          {...(xs
            ? {
                position: 'relative',
                left: pullLeftXs ? -appPaddingX : undefined,
                width: pullLeftXs ? `calc(100% + ${appPaddingX * 2}px)` : undefined
              }
            : {})}
        >
          {items.map(
            ({ label, count, pathname, search, width, onClick, tabTextColor, borderGradient, semibold }, index) => {
              const isSelected = index === selectedIndex;

              return (
                <Clickable
                  key={label}
                  onClick={() => {
                    setSelectedIndex(index);

                    onClick && onClick();

                    if (pathname || search) {
                      if (!pathname) {
                        history.push({ search });
                      } else {
                        history.push({ pathname: pathname, search });
                      }
                    }
                  }}
                  style={{
                    flex: '1 1 0',
                    justifyContent: 'center',
                    ...(isSelected && {
                      borderBottom: borderGradient ? '4px solid' : `4px solid ${BLUE_500_HEX}`,
                      borderImageSource: borderGradient,
                      borderImageSlice: borderGradient ? 1 : undefined
                    })
                  }}
                >
                  <Layout
                    align="center"
                    paddingTop={12}
                    paddingBottom={isSelected ? 9 : 12}
                    paddingX={xs ? 12 : 24}
                    position="relative"
                    display="inline-flex"
                    width={width}
                    justify={width ? 'center' : undefined}
                  >
                    {textColor ? (
                      <Text color={textColor} size="body2">
                        {label}
                      </Text>
                    ) : (
                      <>
                        <Layout position="absolute" left={xs ? 12 : 24} top={11}>
                          <Text
                            size="body2"
                            {...(isSelected
                              ? {
                                  color: 'blue-500',
                                  semibold: true
                                }
                              : { color: 'gray-900', semibold: semibold ? semibold : false })}
                            wrap={false}
                            __style={
                              tabTextColor?.includes('gradient')
                                ? {
                                    background: tabTextColor,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    backgroundClip: 'text',
                                    color: 'transparent'
                                  }
                                : undefined
                            }
                          >
                            {label}
                          </Text>
                        </Layout>
                        <Layout __style={{ visibility: 'hidden' }}>
                          <Text size="body2" semibold wrap={false}>
                            {label}
                          </Text>
                        </Layout>
                      </>
                    )}
                    {typeof count === 'number' && (
                      <Layout marginLeft={8}>
                        <Layout
                          color={isSelected ? 'blue-25' : 'gray-25'}
                          paddingX={4}
                          paddingY={4}
                          borderRadius={4}
                          minWidth={24}
                          justify="center"
                        >
                          <Text size="tag" color={isSelected ? 'blue-500' : 'gray-900'}>
                            {count}
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                </Clickable>
              );
            }
          )}
        </Layout>
      </Layout>
      {children[selectedIndex]}
    </>
  );
};

export default Tabs;
