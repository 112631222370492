import React, { useContext, useEffect } from 'react';
import { BreadcrumbList, DefinedRegion, WithContext } from 'schema-dts';

import FAQs from 'components/FAQs';
import Head from 'components/Head';
import TopCities from 'components/TopCities';

import { app, media } from 'context';
import { Animate, Layout, Text } from 'ds';
import { Replacement, replaceValues } from 'ds/utils';
import { geocodeToAddress } from 'shared';
import { actions } from 'store/App';
import { AppLocation } from 'store/App/types';
import { useAppDispatch } from 'store/hooks';

import About from './About';
import Amenities from './Amenities';
import CTA from './CTA';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import LogosMarquee from './LogosMarquee';
import Map from './Map';
import ValueProps from './ValueProps';
import ImageText from '../ImageText';
import Workspaces from '../Workspaces';

type SectionType = {
  type: string;
  fields: any;
};

interface Props {
  fields: any;
  location: AppLocation;
}

const Page: React.FC<Props> = ({ fields, location }) => {
  const { sectionGutterY } = useContext(app);
  const dispatch = useAppDispatch();
  const { name, id } = location;

  useEffect(() => {
    geocodeToAddress(name).then(address => {
      dispatch(actions.updateButterLocationById({ id, lat: address.lat, lng: address.lon }));
    });
  }, [dispatch, name, id]);

  const {
    seo: { title, description, keywords }
  } = fields;

  const replacements: Replacement[] = [{ variable: 'location', value: location.name }];

  const sections = transformFields(fields);

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${window.location.origin}/#website`,
          name: 'Codi',
          url: window.location.origin
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': window.location.href,
          name: `Office Space for rent in ${location.name}`,
          url: window.location.href
        }
      }
    ]
  };

  const definedRegion: WithContext<DefinedRegion> = {
    '@context': 'https://schema.org',
    '@type': 'DefinedRegion',
    url: window.location.href,
    name: location.name,
    addressRegion: location.region,
    addressCountry: location.country,
    description: location.about_description,
    image: location.image
  };

  return (
    <>
      <Head
        title={replaceValues(location.seo_title || title, replacements)}
        description={replaceValues(location.seo_description || description, replacements)}
        keywords={replaceValues(location.seo_keywords || keywords, replacements)}
      />
      <script type="application/ld+json">{JSON.stringify(breadcrumbs)}</script>
      <script type="application/ld+json">{JSON.stringify(definedRegion)}</script>
      {sections.map((section: any, index: number) => {
        return <Section location={location} key={index} section={section} marginTop={sectionGutterY} />;
      })}
    </>
  );
};

interface SectionProps {
  section: any;
  marginTop: number;
  location: AppLocation;
}

const Section: React.FC<SectionProps> = ({
  section: { type, fields },
  marginTop,
  location,
  location: { name, hero_body, image, about_description, lat, lng, show_map, show_top_cities }
}) => {
  const { xs, lg } = useContext(media);
  const { navBarHeight, windowWidth, contentWidth } = useContext(app);

  const mapHeight = `calc(100vh - ${navBarHeight}px`;
  const replacements: Replacement[] = [{ variable: 'location', value: name }];

  switch (type) {
    case 'hero':
      return (
        <Layout paddingTop={xs ? 44 : 72}>
          <Hero
            {...fields}
            title={replaceValues(fields.title, replacements)}
            body={replaceValues(hero_body || fields.body, replacements)}
            location={location}
          />
        </Layout>
      );

    case 'how_it_works':
      return <HowItWorks steps={fields.steps} title={fields.title} />;

    case 'value_props':
      return (
        <Layout marginTop={xs ? undefined : 40}>
          <ValueProps items={fields.value_props} image={fields.image} title={fields.title} />
        </Layout>
      );

    case 'map':
      const left = (windowWidth - contentWidth) / -2;

      return (
        <Layout
          width={windowWidth}
          maxHeight={600}
          position="relative"
          marginTop={80}
          left={left}
          height={show_map ? mapHeight : 0}
        >
          {typeof lat === 'number' && typeof lng === 'number' && show_map && (
            <Map lat={lat} lng={lng} key={name} location={location} />
          )}
        </Layout>
      );

    case 'workspaces':
      return (
        <Workspaces
          {...fields}
          {...(xs
            ? {
                text: (
                  <Text size="h4" color="blue-700">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: fields.title.replace(
                          '{{location}}',
                          '<br /><span class="is-color-blue-500">' + name + '</span>'
                        )
                      }}
                    />
                  </Text>
                )
              }
            : { title: replaceValues(fields.title, replacements) })}
          ids={location.workspace_ids?.split(', ')?.map(id => parseInt(id.trim()))}
        />
      );

    case 'about':
      if (!about_description || !image) return null;

      return (
        <Layout {...(lg ? { marginTop } : {})}>
          <About location={name} description={about_description} image={image} />
        </Layout>
      );

    case 'top_cities':
      if (!show_top_cities) return null;

      return (
        <Animate align="center" marginTop={lg ? 80 : 0}>
          <TopCities excludedLocation={location} />
        </Animate>
      );

    case 'image_text':
      return <ImageText {...fields} padding_top_xs={32} padding_bottom_xs={32} />;

    case 'amenities':
      return (
        <Layout marginTop={80}>
          <Amenities {...fields} />
        </Layout>
      );

    case 'faqs':
      return (
        <Layout marginY={marginTop}>
          <FAQs qas={fields.qas} />
        </Layout>
      );

    case 'logos_marquee':
      return <LogosMarquee {...fields} />;

    case 'cta':
      return <CTA {...fields} />;

    default:
      return null;
  }
};

const SINGLE_REPEATER_FIELDS: Partial<Record<SectionType['type'], string[]>> = {
  value_props: ['image'],
  amenities: ['premium_image', 'standard_image'],
  workspaces: ['cta'],
  image_text: ['cta', 'image_xs', 'blob', 'blob_xs'],
  cta: ['image']
};

function transformFields(fields: any): SectionType[] {
  const componentTypes = Object.keys(SINGLE_REPEATER_FIELDS) as SectionType['type'][];

  return fields.sections.map(({ fields: section, type }: any) => {
    const updated = { ...section };

    componentTypes.forEach(componentType => {
      if (componentType === type) {
        SINGLE_REPEATER_FIELDS[componentType]?.forEach(field => {
          if (Array.isArray(updated[field])) {
            updated[field] = updated[field][0];
          }
        });
      }
    });

    return { fields: updated, type };
  });
}

export default Page;
