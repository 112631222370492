import React, { useContext } from 'react';

import { app } from 'context';
import { Clickable, Icon, Layout, Link, Text } from 'ds';

interface Props {
  onLocationClick: VoidFunction;
  onFilterClick: VoidFunction;
  daysPerWeek: number;
  location: string;
  isLoading: boolean;
  backPathname: string;
  showPopover?: boolean;
  offsitesOnly?: number;
}

const MobileTopBar: React.FC<Props> = ({ onLocationClick, location, onFilterClick, daysPerWeek, backPathname }) => {
  const { contentWidth, contentPaddingX } = useContext(app);

  const filterButtonContent = (
    <Clickable
      onClick={onFilterClick}
      name="search map filter button"
      style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', flexShrink: 1, paddingLeft: 12 }}
    >
      <Icon size="sm" name="filter" color={daysPerWeek === 0 ? 'gray-900' : 'blue-500'} />
    </Clickable>
  );

  const filterButton = filterButtonContent;

  return (
    <Layout
      color="white"
      height={48}
      left={contentPaddingX}
      width={contentWidth}
      boxShadow="2px 0px 22px 0px #57496D1F"
      paddingX={16}
      borderRadius={100}
      align="center"
      justify="space-between"
    >
      <Layout align="center">
        <Link href={backPathname}>
          <Layout display="inline-flex" paddingY={6} paddingRight={16} inline>
            <Icon name="leftChevron" size="sm" color="gray-900" />
          </Layout>
        </Link>
        <Clickable
          onClick={onLocationClick}
          name="search map location button"
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Layout width={contentWidth - 142} paddingRight={12}>
            <Text size="body1" align="left" ellipsis scale>
              {location}
            </Text>
          </Layout>
        </Clickable>
      </Layout>
      <Layout borderLeft height={20} direction="row" align="center">
        <Layout align="center">{filterButton}</Layout>
      </Layout>
    </Layout>
  );
};

export default MobileTopBar;
