import { createSelector } from '@reduxjs/toolkit';

import { selectUserId } from 'store/User/selectors';
import { RootState } from 'store/store';

export const selectVendorUsers = (state: RootState) => state.Vendor.vendorUsers;
export const selectVendorUser = createSelector(selectUserId, selectVendorUsers, (userId, vendorUsers) =>
  vendorUsers.find(vu => vu.user.id === userId)
);
export const selectVendorTasks = (state: RootState) => state.Vendor.tasks;
export const selectVendorTaskSchedules = (state: RootState) => state.Vendor.taskSchedules;
export const selectVendor = (state: RootState) => state.Vendor.vendor;
export const selectIsVendor = createSelector(selectVendor, vendor => !!vendor);
export const selectVendorId = (state: RootState) => state.Vendor.vendor?.id;
export const selectIsVendorAdmin = createSelector(selectVendorUser, vendorUser => !!vendorUser?.is_vendor_admin);
