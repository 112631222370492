import React, { useRef } from 'react';

import { CalendarDateTaskGroupSummary } from 'store/CleaningService/types';

import Icon from '../Icon';
import Layout from '../Layout';
import Text from '../Text';
import Tooltip from '../Tooltip';

import { ReactComponent as PendingCleaningTaskBackground } from './pending-cleaning-task-background.svg';
import { ReactComponent as PendingTouchUpTaskBackground } from './pending-touch-up-task-background.svg';

interface Props {
  summary: CalendarDateTaskGroupSummary;
}

const CalendarDateTaskGroupDisplay: React.FC<Props> = ({ summary: { is_confirmed, type, is_recurring } }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isPending = !is_confirmed;

  const typeDisplay = type === 'cleaning' ? 'Cleaning' : 'Touch-up';

  let taskGroupContent = (
    <>
      <Layout
        position="relative"
        align="center"
        paddingY={1}
        paddingX={4}
        zIndex={2}
        borderRadius={4}
        {...(isPending && type === 'touch-up'
          ? { borderColor: 'purple-700', borderWidth: 1 }
          : isPending && type === 'cleaning'
          ? { borderColor: 'blue-600', borderWidth: 1 }
          : type === 'cleaning'
          ? { color: 'blue-600' }
          : type === 'touch-up'
          ? { color: 'purple-700' }
          : {})}
      >
        {isPending && (
          <Layout position="absolute" top={0} left={0} zIndex={1} width="100%" height="100%">
            {type === 'cleaning' ? (
              <PendingCleaningTaskBackground
                preserveAspectRatio="none"
                style={{ width: '100%', height: '100%', display: 'block' }}
              />
            ) : (
              <PendingTouchUpTaskBackground
                preserveAspectRatio="none"
                style={{ width: '100%', height: '100%', display: 'block' }}
              />
            )}
          </Layout>
        )}
        <Layout align="center" gap={4} zIndex={2} position="relative">
          <Text size="tag" color={isPending ? 'gray-900' : 'white'} semibold>
            {typeDisplay}
          </Text>
          {is_recurring && <Icon size={12} name="refresh" color={isPending ? 'gray-900' : 'white'} stroke={2} />}
        </Layout>
      </Layout>
    </>
  );

  return (
    <Layout position="relative" __ref={containerRef} width="100%">
      {isPending ? (
        <Tooltip
          text={
            <Text size="tag" color="white" align="center">
              Awaiting vendor
              <br />
              confirmation
            </Text>
          }
          placement="bottom"
        >
          {taskGroupContent}
        </Tooltip>
      ) : (
        taskGroupContent
      )}
    </Layout>
  );
};

export default CalendarDateTaskGroupDisplay;
