import React, { useContext, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import pluralize from 'pluralize';
import qs from 'qs';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { REQUEST_IN_PROGRESS, REQUEST_READY, REQUEST_SUCCEEDED } from 'helpers/constants';

import { app, media } from 'context';
import { Layout, Select, Spinner, Text } from 'ds';
import { RequestState } from 'ds/types';
import { DEMO_SEARCH_WORKSPACE } from 'ds/workspace-data';
import { extractCityFromResults, geocodeLocation } from 'lib/location';
import { OFFICE_REQUEST_PATH } from 'routes';
import { Listing } from 'shared';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { selectOfficeLeads } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import NoResults from './NoResults';
import RequestOfficePrompt from './RequestOfficePrompt';
import WorkspaceListCard from './WorkspaceListCard';
import { FILTER_BAR_HEIGHT, LIST_MAP_SPACE, SEARCH_WORKSPACE_CARD_PADDING_Y, SORT_LABELS } from './constants';
import { generateSearch, getWorkspaceListWidth, parseSearch } from './utils';

interface Props {
  workspaces: Listing[];
  fullWidth: boolean;
  requestState: RequestState;
  daysPerWeek?: number;
  sortLabel: string;
  offsitesOnly: boolean;
}

const WorkspaceList: React.FC<Props> = ({ workspaces, fullWidth, requestState, daysPerWeek = 5, offsitesOnly }) => {
  const listRef = useRef<FixedSizeList>(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const { virtualization } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const virtualizationEnabled = !!virtualization;
  const { contentPaddingX, width, navBarHeight, contentHeight } = useContext(app);
  const mediaContext = useContext(media);
  const paddingLeft = contentPaddingX;
  const paddingRight = fullWidth ? contentPaddingX : LIST_MAP_SPACE;
  const listWidth = fullWidth ? width - contentPaddingX * 2 : getWorkspaceListWidth(mediaContext);
  const [cardRect, setCardRect] = useState<DOMRect>();
  const rowHeight = cardRect ? cardRect.height : 0;
  const listHeight = contentHeight - FILTER_BAR_HEIGHT;
  const mapHeight = `calc(100vh - ${navBarHeight + FILTER_BAR_HEIGHT}px)`;

  const officeLeads = useAppSelector(selectOfficeLeads);

  return (
    <Layout paddingLeft={paddingLeft} paddingRight={paddingRight} paddingBottom={40} flexGrow={1}>
      <Layout
        onMeasure={rect => {
          setCardRect(rect);
        }}
        visibility="hidden"
        position="absolute"
        paddingY={SEARCH_WORKSPACE_CARD_PADDING_Y}
      >
        <WorkspaceListCard
          workspace={{ ...DEMO_SEARCH_WORKSPACE }}
          daysPerWeek={daysPerWeek}
          offsitesOnly={offsitesOnly}
        />
      </Layout>
      {requestState === REQUEST_READY || (requestState === REQUEST_IN_PROGRESS && workspaces.length === 0) ? (
        <Layout justify="center" marginTop={40}>
          <Spinner size="md" />
        </Layout>
      ) : requestState === REQUEST_SUCCEEDED && workspaces.length === 0 ? (
        <Layout width={listWidth} height={mapHeight} marginTop={72} justify="center">
          <NoResults offsitesOnly={offsitesOnly} />
        </Layout>
      ) : (
        <>
          {workspaces.length > 0 && (
            <Layout height={62} width="100%" justify="space-between" align="center" direction="row">
              <Layout direction="row" align="center">
                <Text size="body2" color="gray-700">
                  {pluralize('workspace', workspaces.length, true)}
                </Text>
                <Layout marginLeft={12} />
              </Layout>
              <Layout align="center">
                <Text size="body3" color="gray-700">
                  Sort by
                </Text>
                <Layout marginLeft={12}>
                  <Select
                    options={SORT_LABELS.map(l => ({ label: l, value: l }))}
                    onChange={({ value }) => {
                      history.replace({
                        search: generateSearch({
                          search: location.search,
                          sortLabel: value
                        })
                      });
                    }}
                    size="sm"
                  />
                </Layout>
              </Layout>
            </Layout>
          )}
          <Layout>
            {rowHeight && virtualizationEnabled ? (
              <FixedSizeList
                ref={listRef}
                height={listHeight}
                itemCount={workspaces.length}
                itemSize={rowHeight}
                width={listWidth}
                itemData={{ daysPerWeek, workspaces }}
              >
                {WorkspaceListCell}
              </FixedSizeList>
            ) : (
              workspaces.map((ws, index) => {
                return (
                  <React.Fragment key={ws.id}>
                    <Layout paddingY={SEARCH_WORKSPACE_CARD_PADDING_Y} borderTop={index !== 0}>
                      <WorkspaceListCard workspace={ws} daysPerWeek={daysPerWeek} offsitesOnly={offsitesOnly} />
                    </Layout>
                    {!officeLeads.length && index === 0 && (
                      <Layout paddingY={SEARCH_WORKSPACE_CARD_PADDING_Y} borderTop>
                        <RequestOfficePrompt
                          offsitesOnly={offsitesOnly}
                          onClick={() => {
                            const { center, zoom } = parseSearch(search);

                            if (!center || !zoom || zoom < 10) {
                              dispatch(officeRequestActions.setLaunchPath(location.pathname));
                              history.push(OFFICE_REQUEST_PATH);

                              return;
                            }

                            geocodeLocation({
                              location: center,
                              callback: results => {
                                const loc = extractCityFromResults(results);

                                if (!!loc) {
                                  const { city, region, country } = loc;

                                  if (city && region && country) {
                                    // TODO
                                    // dispatch(officeRequestActions.setLocation({ city, region, country }));
                                  }
                                }
                                dispatch(officeRequestActions.setLaunchPath(location.pathname));
                                history.push(OFFICE_REQUEST_PATH);
                              }
                            });
                          }}
                        />
                      </Layout>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </Layout>
        </>
      )}
    </Layout>
  );
};

const WorkspaceListCell: React.FC<ListChildComponentProps> = ({
  style,
  index,
  data: { workspaces, daysPerWeek, offsitesOnly }
}) => {
  const workspace = workspaces[index];

  if (!workspace) return null;

  return (
    <Layout paddingY={SEARCH_WORKSPACE_CARD_PADDING_Y} __style={{ ...style }} borderTop={index !== 0}>
      <WorkspaceListCard workspace={workspace} daysPerWeek={daysPerWeek} offsitesOnly={offsitesOnly} />
    </Layout>
  );
};

export default WorkspaceList;
