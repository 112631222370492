import { Icon, Layout, Text } from 'ds';
import { Color, WHITE_HEX } from 'shared';

import { Size } from './Text';

interface AddQuantityProps {
  textAndIconColor?: Color;
  value: number;
  size: 'lg' | 'sm';
  handleDecreaseQuantity: () => void;
  handleIncreaseQuantity: () => void;
  handleRemoveItem?: () => void;
  showRemoveItemButton?: boolean;
  minValue?: number;
  maxValue?: number;
}

const sizes = {
  lg: {
    iconSize: 'md',
    gap: 24,
    borderRadius: 24,
    textSize: 'body-sm',
    height: 50,
    buttonSize: 'lg',
    buttonWidth: 60,
    buttonHeight: 36
  },
  sm: {
    textSize: 'body-sm',
    borderRadius: 1000,
    gap: 0,
    height: 36,
    buttonSize: 'sm',
    buttonWidth: 48
  }
};

const AddQuantity = ({
  textAndIconColor = 'gray-900',
  value,
  size = 'lg',
  handleDecreaseQuantity,
  handleIncreaseQuantity,
  handleRemoveItem,
  showRemoveItemButton = false,
  minValue = 1,
  maxValue
}: AddQuantityProps) => {
  return (
    <Layout direction="row" height={sizes[size].height}>
      <Layout align="center" justify="flex-start">
        <button
          className={'hover-bg-color-gray-50'}
          style={{
            width: sizes[size].buttonWidth,
            height: '100%',
            borderTopLeftRadius: sizes[size].borderRadius,
            borderBottomLeftRadius: sizes[size].borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid #eceff1',
            borderBottom: '1px solid #eceff1',
            borderLeft: '1px solid #eceff1',
            backgroundColor: WHITE_HEX,
            cursor: value === minValue && !showRemoveItemButton ? 'not-allowed' : 'pointer'
          }}
          onClick={showRemoveItemButton && handleRemoveItem ? handleRemoveItem : handleDecreaseQuantity}
          disabled={value <= minValue && !showRemoveItemButton}
        >
          <Icon name={showRemoveItemButton ? 'trashcan' : 'minus'} stroke={2} size={20} color={textAndIconColor} />
        </button>
      </Layout>
      <Layout
        align="center"
        justify="center"
        flexGrow={1}
        __style={{
          borderTop: '1px solid #eceff1',
          borderBottom: '1px solid #eceff1',
          backgroundColor: WHITE_HEX
        }}
      >
        <Text color={textAndIconColor} size={sizes[size].textSize as Size} semibold>
          {value}
        </Text>
      </Layout>
      <Layout align="center" justify="flex-end">
        <button
          className={'hover-bg-color-gray-50'}
          style={{
            width: sizes[size].buttonWidth,
            height: '100%',
            borderTopRightRadius: sizes[size].borderRadius,
            borderBottomRightRadius: sizes[size].borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid #eceff1',
            borderBottom: '1px solid #eceff1',
            borderRight: '1px solid #eceff1',
            backgroundColor: WHITE_HEX
          }}
          onClick={handleIncreaseQuantity}
          disabled={!!maxValue && value >= maxValue}
        >
          <Icon name="plus" size={20} color={textAndIconColor} />
        </button>
      </Layout>
    </Layout>
  );
};

export default AddQuantity;
