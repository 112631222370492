import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { priceAdjustedForDaysPerWeek } from 'helpers/price';

import { app, media } from 'context';
import {
  HEADER_PORTAL_ID,
  HEADER_Z_INDEX,
  Icon,
  Layout,
  LocationInput,
  SizeFilter,
  Text,
  TextButton,
  ToggleControl,
  Tooltip
} from 'ds';
import MeetingRoomsFilter from 'ds/Filters/MeetingRoomsFilter';
import OptionFilter from 'ds/Filters/OptionFilter';
import { OnLocationChange } from 'ds/inputs/Location/LocationInput';
import { Listing } from 'shared';
import { selectSearchWorkspaces } from 'store/Search/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import { DAYS_PER_WEEK_OPTIONS, FILTER_BAR_FIELD_SIZE, FILTER_BAR_HEIGHT } from './constants';
import { SearchFilters } from './types';
import { generateSearch } from './utils';
import PriceFilter from '../../ds/Filters/PriceFilter';

interface Props extends SearchFilters {
  location: string;
  onLocationChange: OnLocationChange;
  hideSearchInput?: boolean;
}

const TopBar: React.FC<Props> = ({
  location,
  onLocationChange,
  daysPerWeek = 5,
  minPrice,
  maxPrice,
  minSqft,
  maxSqft,
  offsitesOnly,
  numMeetingRooms,
  hideSearchInput
}) => {
  const { navBarHeight, contentPaddingX } = useContext(app);
  const { md, lg } = useContext(media);
  const zIndex = HEADER_Z_INDEX - 5;
  const [locationQuery, setLocationQuery] = useState<string | undefined>(location);
  const allWorkspaces: Listing[] = useAppSelector(selectSearchWorkspaces);
  const filteredWorkspaces = !!offsitesOnly
    ? allWorkspaces
    : allWorkspaces.filter(ws => ws.available_short_term && !!ws.daily_rate && !ws.is_reserved);
  const history = useHistory();
  const { search } = useLocation();
  const [resetOrdinal, setResetOrdinal] = useState<number>(0);
  const isLoggedIn = !!useAppSelector(selectUser);

  useEffect(() => {
    setLocationQuery(location);
  }, [location]);

  const filter = (
    <OptionFilter
      label="Days needed per week"
      options={[...DAYS_PER_WEEK_OPTIONS]}
      value={daysPerWeek}
      onChange={({ value }) => {
        history.replace({ search: generateSearch({ search, daysPerWeek: value }) });
      }}
      icon="calendar"
    />
  );

  const priceValuesAdjusted = filteredWorkspaces.map(ws =>
    !!offsitesOnly ? ws.daily_rate : priceAdjustedForDaysPerWeek({ monthlyPrice: ws.monthly_price || 0, daysPerWeek })
  );

  const headerPortal = document.getElementById(HEADER_PORTAL_ID);

  return (
    <StickyHeader top={navBarHeight} zIndex={zIndex}>
      <Layout
        height={FILTER_BAR_HEIGHT}
        width="100%"
        align="center"
        color="white"
        justify="space-between"
        paddingBottom={8}
        paddingX={contentPaddingX}
      >
        <Layout justify="space-between" align="center" flexGrow={1}>
          <Layout align="center">
            {headerPortal &&
              !hideSearchInput &&
              ReactDOM.createPortal(
                <Layout
                  {...(lg
                    ? { marginLeft: 40, width: 500 }
                    : md
                    ? { marginLeft: 28, width: 288 }
                    : { marginLeft: 28, width: 288 })}
                >
                  <LocationInput
                    size={FILTER_BAR_FIELD_SIZE}
                    value={locationQuery}
                    onChange={({ value }) => {
                      setLocationQuery(value);
                    }}
                    onLocationChange={({ location, place }) => {
                      setLocationQuery(location);
                      onLocationChange({ location, place });
                    }}
                    hideLabel
                    label="Search"
                    onClear={() => {
                      setLocationQuery(undefined);
                    }}
                    placeholder="Where do you want to work?"
                  />
                </Layout>,
                headerPortal
              )}
            {!offsitesOnly && <>{filter}</>}
            {isLoggedIn && (
              <Layout marginLeft={offsitesOnly ? undefined : 12}>
                <PriceFilter
                  values={priceValuesAdjusted}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  offsitesOnly={!!offsitesOnly}
                  onSubmit={({ minPrice, maxPrice }) => {
                    history.replace({
                      search: generateSearch({ search, minPrice, maxPrice })
                    });
                  }}
                  key={resetOrdinal}
                />
              </Layout>
            )}
            <Layout marginLeft={12}>
              <SizeFilter
                values={filteredWorkspaces.map(ws => ws.square_feet || 0)}
                minSqft={minSqft}
                maxSqft={maxSqft}
                key={resetOrdinal}
                onSubmit={values =>
                  history.replace({
                    search: generateSearch({ search, ...values })
                  })
                }
              />
            </Layout>
            <Layout marginLeft={12}>
              <MeetingRoomsFilter
                numMeetingRooms={numMeetingRooms || 0}
                key={resetOrdinal}
                onSubmit={values =>
                  history.replace({
                    search: generateSearch({ search, ...values })
                  })
                }
              />
            </Layout>
            <Layout marginLeft={12}>
              <TextButton
                scale
                text="Reset"
                textSize="body3"
                color="gray-700"
                semibold={false}
                onClick={() => {
                  setResetOrdinal(resetOrdinal + 1);
                  history.replace({
                    search: generateSearch({
                      search,
                      daysPerWeek: null,
                      minPrice: null,
                      maxPrice: null,
                      minSqft: null,
                      maxSqft: null,
                      numMeetingRooms: null,
                      offsitesOnly: undefined
                    })
                  });
                }}
              />
            </Layout>
          </Layout>
          <Layout align="center">
            <Text size="body1">Show only offsite availability</Text>
            <Layout marginLeft={8} />
            <Tooltip
              size="sm"
              text={
                <Text size="body3" color="white" inline>
                  We offer spaces by the day for
                  <br />
                  offsites, workshops, and more.
                </Text>
              }
              placement="bottom-end"
              containerStyle={{ display: 'inline-flex' }}
            >
              <Icon name="info" size="xs" />
            </Tooltip>
            <Layout marginLeft={16} />
            <ToggleControl
              size="sm"
              checked={!!offsitesOnly}
              onChange={value => {
                setResetOrdinal(resetOrdinal + 1);
                history.replace({
                  search: generateSearch({
                    search,
                    daysPerWeek: null,
                    minPrice: null,
                    maxPrice: null,
                    minSqft: null,
                    maxSqft: null,
                    offsitesOnly: !!value ? 1 : null
                  })
                });
              }}
            />
          </Layout>
        </Layout>
      </Layout>
    </StickyHeader>
  );
};

export default TopBar;
