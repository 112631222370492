import { DateTime } from 'luxon';

import { CalendarDateTaskGroup, CleaningServiceCalendarDate } from 'store/CleaningService/types';

export function makeCalendarDatesFromTaskGroups({
  taskGroups,
  startDate,
  endDate
}: {
  taskGroups: CalendarDateTaskGroup[];
  startDate: DateTime;
  endDate: DateTime;
}) {
  const dates: CleaningServiceCalendarDate[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const currentDateISO = currentDate.toISODate();
    const calendarDateTaskGroup = taskGroups.find(c => c.date === currentDateISO);
    const combinedDate: CleaningServiceCalendarDate = {
      summary: calendarDateTaskGroup
        ? {
            price: calendarDateTaskGroup.price,
            is_recurring: calendarDateTaskGroup.is_recurring,
            type: calendarDateTaskGroup.type,
            is_confirmed: calendarDateTaskGroup.is_confirmed
          }
        : undefined,
      tasks: calendarDateTaskGroup ? calendarDateTaskGroup.tasks : [],
      date: currentDate
    };
    dates.push(combinedDate);
    currentDate = currentDate.plus({ days: 1 });
  }

  return dates;
}
