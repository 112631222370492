import { NavItem } from 'ds/types';
import { ADMIN_MORE_PATH } from 'routes';
import { User } from 'shared';

import {
  ACCOUNT_NAV_ITEM,
  ACCOUNT_NAV_ITEMS,
  ADMIN_BUILDINGS_NAV_ITEM,
  ADMIN_CUSTOMERS_NAV_ITEM,
  ADMIN_LICENSORS_NAV_ITEM,
  ADMIN_MAP_NAV_ITEM,
  ADMIN_NAV_ITEM,
  ADMIN_PAYOUTS_NAV_ITEM,
  ADMIN_PRODUCTS_NAV_ITEM,
  ADMIN_REQUESTS_NAV_ITEM,
  ADMIN_SHORT_TERM_BOOKINGS_NAV_ITEM,
  ADMIN_SUPPORT_NAV_ITEM,
  ADMIN_TENANTS_NAV_ITEM,
  ADMIN_TOOLS_NAV_ITEM,
  ADMIN_USERS_NAV_ITEM,
  ADMIN_VENDORS_NAV_ITEM,
  ADMIN_WORKSPACES_NAV_ITEM,
  CLEANING_SERVICE_NAV_ITEM,
  CUSTOMER_SUPPORT_NAV_ITEM,
  DASHBOARD_NAV_ITEM,
  FAVORITES_NAV_ITEM,
  FURNITURE_AND_EQUIPMENT_NAV_ITEM,
  HOME_NAV_ITEM,
  MEMBERSHIP_NAV_ITEM,
  MORE_NAV_ITEM,
  NAV_ITEM_ORDER,
  OFFICE_SEARCH_NAV_ITEM,
  OFFSITES_NAV_ITEM,
  PANTRY_AND_SUPPLIES_NAV_ITEM,
  PROPERTIES_ACCOUNTS_NAV_ITEM,
  PROPERTIES_LISTINGS_NAV_ITEM,
  PROPERTIES_NAV_ITEM,
  PROPERTIES_PAYOUTS_NAV_ITEM,
  PUBLIC_SITE_NAV_ITEM,
  REFERRALS_NAV_ITEM,
  SERVICES_AND_AMENITIES_NAV_ITEM,
  TENANT_ADMIN_NAV_ITEM,
  VENDOR_SCHEDULES_NAV_ITEM,
  VENDOR_TASKS_ITEM,
  VENDOR_TEAM_NAV_ITEM
} from './Navigation/constants';

export function isNavigationItemActive({
  href,
  exact,
  pathname,
  matchHrefs = []
}: Pick<NavItem, 'href' | 'exact' | 'matchHrefs'> & { pathname: string }) {
  return (
    hrefIsMatch({ href, exact, pathname }) ||
    matchHrefs.some(({ href, exact }) => hrefIsMatch({ href, exact, pathname }))
  );
}

export function hrefIsMatch({ href, exact, pathname }: { href?: string | RegExp; exact?: boolean; pathname: string }) {
  if (exact && typeof href === 'string') {
    return href === pathname;
  } else if (href && typeof href === 'string') {
    return pathname.startsWith(href);
  } else if (typeof href === 'object') {
    return href.test(pathname);
  } else {
    return false;
  }
}

interface GetNavItemParams {
  isPropertyUX: boolean;
  isTenantUx: boolean;
  isTenantAdmin: boolean;
  isVendor: boolean;
  isVendorAdmin: boolean;
  hasListingRole: boolean;
  hasPayoutAdminRole: boolean;
  hasPayoutViewerRole: boolean;
  hasPlanAdminType: boolean;
  hasOffices: boolean;
  hasOffsites: boolean;
  isCustomerUser: boolean;
  isProspect: boolean;
  hasSignedPlans: boolean;
  hasActivePlans: boolean;
  selectedOfficeInCustomerAdminOffices: boolean;
  user: User | null;
}

export function getBottomNavItems(params: GetNavItemParams) {
  return limitBottomNavItems(getMobileNavItems(params));
}

export function getMoreNavItems(params: GetNavItemParams) {
  return sliceMoreNavItems(getMobileNavItems(params));
}

export function getAdminNavItems() {
  const items = [
    ADMIN_BUILDINGS_NAV_ITEM,
    ADMIN_TENANTS_NAV_ITEM,
    ADMIN_WORKSPACES_NAV_ITEM,
    ADMIN_USERS_NAV_ITEM,
    ADMIN_CUSTOMERS_NAV_ITEM,
    ADMIN_LICENSORS_NAV_ITEM,
    ADMIN_VENDORS_NAV_ITEM,
    ADMIN_SHORT_TERM_BOOKINGS_NAV_ITEM,
    ADMIN_PAYOUTS_NAV_ITEM,
    ADMIN_REQUESTS_NAV_ITEM,
    ADMIN_PRODUCTS_NAV_ITEM,
    ADMIN_MAP_NAV_ITEM,
    ADMIN_TOOLS_NAV_ITEM,
    ADMIN_SUPPORT_NAV_ITEM
  ];

  return items;
}

export function getMobileNavItems({
  isPropertyUX,
  isVendor,
  isTenantUx,
  hasListingRole,
  hasPayoutAdminRole,
  hasPayoutViewerRole,
  hasPlanAdminType,
  isCustomerUser,
  hasOffices,
  hasOffsites,
  isProspect,
  hasSignedPlans,
  selectedOfficeInCustomerAdminOffices,
  isVendorAdmin
}: GetNavItemParams) {
  const items = [];

  if (isPropertyUX) {
    if (hasListingRole) {
      items.push(PROPERTIES_LISTINGS_NAV_ITEM);
    }

    if (hasPayoutAdminRole) {
      items.push(PROPERTIES_ACCOUNTS_NAV_ITEM);
    }

    if (hasPayoutAdminRole || hasPayoutViewerRole) {
      items.push(PROPERTIES_PAYOUTS_NAV_ITEM);
    }
  } else if (isVendor) {
    items.push(VENDOR_TASKS_ITEM);
    if (isVendorAdmin) {
      items.push(VENDOR_TEAM_NAV_ITEM);
      items.push(VENDOR_SCHEDULES_NAV_ITEM);
    }
  } else if (isTenantUx) {
    items.push(CLEANING_SERVICE_NAV_ITEM);
    items.push(PANTRY_AND_SUPPLIES_NAV_ITEM);
    items.push(FURNITURE_AND_EQUIPMENT_NAV_ITEM);
    items.push(CUSTOMER_SUPPORT_NAV_ITEM);
    items.push(TENANT_ADMIN_NAV_ITEM);
  } else {
    items.push(DASHBOARD_NAV_ITEM);

    if (isProspect) {
      items.push(FAVORITES_NAV_ITEM);
    } else if (!hasOffices) {
      items.push(OFFICE_SEARCH_NAV_ITEM);
    }

    if (hasPlanAdminType && hasSignedPlans && selectedOfficeInCustomerAdminOffices) {
      items.push(SERVICES_AND_AMENITIES_NAV_ITEM);
      items.push(CUSTOMER_SUPPORT_NAV_ITEM);
    }

    if (isCustomerUser) {
      items.push(REFERRALS_NAV_ITEM);
    }

    if (hasOffsites) {
      items.push(OFFSITES_NAV_ITEM);
    }
  }

  const navItemOrderType = isTenantUx ? 'tenant' : 'other';
  items.sort(
    (item1, item2) =>
      NAV_ITEM_ORDER[navItemOrderType].indexOf(item1.label) - NAV_ITEM_ORDER[navItemOrderType].indexOf(item2.label)
  );

  return items;
}

export function getAdminBottomNavItems() {
  return limitBottomNavItems(getAdminNavItems(), { isAdmin: true });
}

export function getAdminMoreNavItems() {
  return sliceMoreNavItems(getAdminNavItems());
}

export function limitBottomNavItems(items: NavItem[], { isAdmin }: { isAdmin?: boolean } = {}) {
  let limitedItems = [...items];

  if (limitedItems.length > 3) {
    const navItem = { ...MORE_NAV_ITEM };

    if (isAdmin) {
      navItem.href = ADMIN_MORE_PATH;
    }
    limitedItems.splice(3, 0, navItem);
    limitedItems = limitedItems.slice(0, 4);
  } else {
    limitedItems.push(ACCOUNT_NAV_ITEM);
  }

  return limitedItems;
}

export function sliceMoreNavItems(items: NavItem[]) {
  if (items.length > 3) {
    return items.slice(3);
  } else {
    return [];
  }
}

export function getUXSwitcherNavItems({
  isPublicPage,
  isAdmin,
  isAdminUX,
  isPropertyUX,
  hasPropertiesNavItem
}: {
  isPublicPage: boolean;
  isAdmin: boolean;
  isAdminUX: boolean;
  isPropertyUX: boolean;
  hasPropertiesNavItem: boolean;
}) {
  const buttons: NavItem[] = [];

  if (isPublicPage) {
    buttons.push(DASHBOARD_NAV_ITEM);

    if (isAdmin) {
      buttons.splice(0, 0, ADMIN_NAV_ITEM);
    }
    if (hasPropertiesNavItem) {
      buttons.push(PROPERTIES_NAV_ITEM);
    }
  } else if (isAdmin) {
    buttons.push(PUBLIC_SITE_NAV_ITEM, isAdminUX ? DASHBOARD_NAV_ITEM : ADMIN_NAV_ITEM);

    if (hasPropertiesNavItem && !isPropertyUX) {
      buttons.push(PROPERTIES_NAV_ITEM);
    } else if (isPropertyUX) {
      buttons.push(DASHBOARD_NAV_ITEM);
    }
  } else if (hasPropertiesNavItem) {
    buttons.push(PUBLIC_SITE_NAV_ITEM, isPropertyUX ? DASHBOARD_NAV_ITEM : PROPERTIES_NAV_ITEM);
  }

  return buttons;
}

export function getMobileAccountItems({
  hasActivePlans,
  hasPropertiesNavItem,
  isAdmin,
  isTenantUx
}: {
  hasActivePlans: boolean;
  hasPropertiesNavItem: boolean;
  isAdmin: boolean;
  isTenantUx: boolean;
}) {
  let accountItems: NavItem[] = [];

  if (hasActivePlans && !isTenantUx) {
    accountItems.push(MEMBERSHIP_NAV_ITEM);
  }

  if (isTenantUx) {
    accountItems.push(HOME_NAV_ITEM);
  }

  if (!(hasPropertiesNavItem || isAdmin)) {
    accountItems.push(PUBLIC_SITE_NAV_ITEM);
  }

  return accountItems.concat(ACCOUNT_NAV_ITEMS);
}
