import { Layout, PillButton, Text } from 'ds';
import { formatUsd } from 'helpers';
import { Color } from 'shared';
import { selectSalesTaxRate } from 'store/Tenancy/selectors';
import { useAppSelector } from 'store/hooks';
interface CostSummaryBoxProps {
  itemsSubtotalCents: number;
  deliveryFeeCents?: number;
  disclaimerMessage?: JSX.Element;
  sticky?: boolean;
  top?: number;
  changesMade?: boolean;
  onResetChanges?: () => void;
  onClickSaveChanges?: () => void;
  backgroundColor?: Color;
  paddingX?: number;
  footer?: JSX.Element;
}

const CostSummaryBox = ({
  itemsSubtotalCents,
  disclaimerMessage,
  sticky = false,
  top,
  deliveryFeeCents,
  changesMade = false,
  onResetChanges,
  onClickSaveChanges,
  backgroundColor,
  paddingX = 24,
  footer
}: CostSummaryBoxProps) => {
  const salesTaxRate = useAppSelector(selectSalesTaxRate);
  const salesTaxAmountCents = salesTaxRate ? Math.round((itemsSubtotalCents * salesTaxRate) / 100) : 0;
  const totalCents = itemsSubtotalCents + (deliveryFeeCents || 0) + (salesTaxAmountCents || 0);
  return (
    <Layout
      width="100%"
      paddingTop={24}
      paddingBottom={footer ? 0 : 24}
      borderRadius={16}
      color={backgroundColor ? backgroundColor : undefined}
      height="fit-content"
      position={sticky ? 'sticky' : undefined}
      top={sticky ? top : undefined}
      flex
      direction="column"
    >
      <Layout flex direction="column" gap={12} paddingX={paddingX}>
        <Layout flex direction="row" justify="space-between">
          <Text size="body-sm" semibold>
            Items subtotal
          </Text>
          <Text size="body-sm" semibold>
            {formatUsd(itemsSubtotalCents / 100)}
          </Text>
        </Layout>
        {deliveryFeeCents && (
          <Layout flex direction="row" justify="space-between">
            <Text size="body-sm" semibold>
              Delivery fee
            </Text>
            <Text size="body-sm" semibold>
              {formatUsd(deliveryFeeCents / 100)}
            </Text>
          </Layout>
        )}
        {salesTaxRate && (
          <Layout flex direction="row" justify="space-between">
            <Text size="body-sm" semibold>
              Tax ({salesTaxRate}%)
            </Text>
            <Text size="body-sm" semibold>
              {formatUsd(salesTaxAmountCents / 100)}
            </Text>
          </Layout>
        )}
        <Layout flex direction="row" justify="space-between">
          <Text size="body-sm" semibold>
            Restocking
          </Text>
          <Text size="body-sm" color="purple-gradient" semibold>
            Included
          </Text>
        </Layout>
      </Layout>
      <Layout
        flex
        paddingX={paddingX}
        direction="row"
        marginTop={16}
        paddingTop={16}
        justify="space-between"
        __style={{ borderTop: '1px solid #ECEFF1' }}
      >
        <Text size="headline-xs" color="gray-900">
          Total
        </Text>
        <Text size="headline-xs" color="gray-900">
          {formatUsd(totalCents / 100)}
        </Text>
      </Layout>
      {!!disclaimerMessage && (
        <Layout flex direction="row" marginTop={16} justify="space-between" paddingX={paddingX}>
          {disclaimerMessage}
        </Layout>
      )}
      {changesMade && (
        <Layout flex direction="column" marginTop={24} gap={24} paddingX={paddingX}>
          <PillButton borderRadius={40} type="primary" size="md" text="Save changes" onClick={onClickSaveChanges} />
          <Layout flex justify="center" align="center">
            <button
              onClick={onResetChanges}
              style={{
                color: '#78909C',
                fontSize: 12,
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '2%',
                width: 'fit-content'
              }}
            >
              Reset changes
            </button>
          </Layout>
        </Layout>
      )}
      {footer && footer}
    </Layout>
  );
};

export default CostSummaryBox;
