import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { RootState } from 'store/store';

export const selectTenancy = (state: RootState) => state.Tenancy;

export const selectDefaultPaymentMethod = createSelector(selectTenancy, tenancy => tenancy.default_payment_method);

export const selectBillingEmail = createSelector(selectTenancy, tenancy => tenancy.billing_email);

export const selectBuildingUnits = createSelector(selectTenancy, tenancy => tenancy.building_units);

export const selectMembers = createSelector(selectTenancy, tenancy => tenancy.members);

export const selectPayments = createSelector(selectTenancy, tenancy => tenancy.payments);

export const selectPlatformFees = createSelector(selectTenancy, tenancy => tenancy.platform_fees);
export const selectProposedPlatformFee = createSelector(selectPlatformFees, platformFees =>
  platformFees?.find(fee => !!fee.sent_at && !fee.approved_at)
);
export const selectActivePlatformFee = createSelector(selectPlatformFees, platformFees =>
  platformFees?.find(
    fee =>
      !!fee.approved_at &&
      (!fee.end_date || DateTime.fromISO(fee.end_date).startOf('day') >= DateTime.now().startOf('day'))
  )
);
export const selectPaymentById = (id: number) =>
  createSelector(selectTenancy, tenancy => tenancy.payments.find(payment => payment.id === id));

export const selectSalesTaxRate = createSelector(selectTenancy, tenancy => tenancy.sales_tax_rate);
