import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'qs';

import { showSnackbar } from 'helpers/snackbar';

import { media } from 'context';
import { APP_BANNER_HEIGHT, IconButton, Layout, Text } from 'ds';
import { createUserAction } from 'shared/requests';
import { actions } from 'store/AIAssistant';
import { selectThreadsSidebarVisible } from 'store/AIAssistant/selectors';
import { selectIsTenantUx, selectUIState } from 'store/UI/selectors';
import { actions as userActions } from 'store/User';
import { selectUser, selectUserActions } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ChatbotThread from './ChatbotThread';
import ChatbotThreads from './ChatbotThreads';
import CustomerChatbotThreadInput from './CustomerChatbotThreadInput';
import KickoffModal from './KickoffModal';
import { fetchThreads } from './requests';
import MobileComingSoon from '../MobileComingSoon';
import { KICKOFF_MODAL_ACTION } from '../constants';

interface RouteParams {
  id?: string;
}

const AIAssistant: React.FC = () => {
  const dispatch = useAppDispatch();
  const { appBannerType } = useAppSelector(selectUIState);
  const { isMobile } = useContext(media);
  const history = useHistory();
  const user = useAppSelector(selectUser);
  const isTenantUx = useAppSelector(selectIsTenantUx);
  const params = useParams<RouteParams>();
  const threadId = params.id ? parseInt(params.id) : undefined;
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  // TODO: do we want a randomly generated greeting?
  const [greeting, _setGreeting] = useState('What can I help with today?');
  const isThreadsDrawerVisible = useAppSelector(selectThreadsSidebarVisible);
  const [sideBarIsAlreadyShown, setSideBarIsAlreadyShown] = useState(isThreadsDrawerVisible);
  const qsParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const hasKickoffParam = 'kickoff' in qsParams;

  const existingUserActions = useAppSelector(selectUserActions);
  const viewedKickoffModal = existingUserActions.find(a => a.value === KICKOFF_MODAL_ACTION);

  const [showKickoffModal, setShowKickoffModal] = useState<boolean>(false);

  useEffect(() => {
    if (hasKickoffParam && !viewedKickoffModal) {
      setShowKickoffModal(true);
    }
  }, [hasKickoffParam, viewedKickoffModal]);

  useEffect(() => {
    const setThreads = () =>
      fetchThreads()
        .then(({ data }) => {
          dispatch(actions.setThreads(data));
        })
        .catch(() => {
          showSnackbar({ message: 'Uh oh! There was an error connecting. Please try again later.', negative: true });
        })
        .finally(() => {});

    setThreads();
  }, [dispatch, threadId]);

  useEffect(() => {
    if (user?.id && showKickoffModal) {
      createUserAction({ userId: user?.id, value: KICKOFF_MODAL_ACTION }).then(({ data }) => {
        dispatch(userActions.updateUser({ user_actions: data }));
      });
    }
  }, [showKickoffModal, dispatch, user?.id]);

  if (!user) {
    return null;
  }

  if (!isTenantUx && !process.env.STORYBOOK) return null;

  if (isMobile) {
    return <MobileComingSoon />;
  }

  return (
    <Layout
      width="100%"
      marginTop={appBannerType ? APP_BANNER_HEIGHT : 0}
      direction="row"
      justify="center"
      align="center"
      className={isThreadsDrawerVisible ? 'content-shift' : 'content-reset'}
    >
      <KickoffModal isVisible={showKickoffModal} onClose={() => setShowKickoffModal(false)} />
      {!isThreadsDrawerVisible && (
        <Layout position="absolute" top={24 + (appBannerType ? APP_BANNER_HEIGHT : 0)} left={84}>
          <IconButton
            iconColor="gray-400"
            name="sideMenu"
            stroke={2}
            size="md"
            onClick={() => dispatch(actions.setSidebarVisible(true))}
            type="noBackground"
          />
        </Layout>
      )}
      <ChatbotThreads
        sideBarIsAlreadyShown={sideBarIsAlreadyShown}
        isVisible={isThreadsDrawerVisible}
        onClose={() => {
          setSideBarIsAlreadyShown(false);
          dispatch(actions.setSidebarVisible(false));
        }}
        loadedFirstTime={loadedFirstTime}
        setLoadedFirstTime={setLoadedFirstTime}
      />
      <Layout direction="column" width="100%" justify="center" align="center">
        {threadId && <ChatbotThread threadId={threadId} />}
        {!threadId && (
          <Layout direction="column" width="100%" justify="center" align="center">
            <Text size="body-xl" bold align="center">
              Hi, {user?.firstname}.
            </Text>
            <Text size="body-xl" bold color="purple-gradient">
              {greeting}
            </Text>
          </Layout>
        )}
        <Layout marginTop={36} width={768} maxWidth={768}>
          <CustomerChatbotThreadInput threadId={threadId} />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AIAssistant;
