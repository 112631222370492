import { createSlice } from '@reduxjs/toolkit';

import {
  ConsumablesOrder,
  ConsumablesProduct,
  ConsumablesProductCategory,
  ConsumablesProductSubscription,
  ConsumablesProductType
} from 'shared';

const initialState: ConsumablesState = {
  selectedCategoryName: null,
  selectedTypeName: null,
  categories: [],
  types: [],
  products: [],
  featuredTypes: [],
  consumablesStateLoaded: false,
  consumablesProductSubscriptions: null,
  consumablesOrders: null
};

export type ConsumablesState = {
  selectedCategoryName: string | null;
  selectedTypeName: string | null;
  categories: ConsumablesProductCategory[];
  types: ConsumablesProductType[];
  products: ConsumablesProduct[];
  featuredTypes: ConsumablesProductType[];
  consumablesStateLoaded: boolean;
  consumablesProductSubscriptions: ConsumablesProductSubscription[] | null;
  consumablesOrders: ConsumablesOrder[] | null;
};

const consumablesSlice = createSlice({
  name: 'Consumables',
  initialState,
  reducers: {
    setSelectedCategoryName: (state, action) => {
      state.selectedCategoryName = action.payload;
    },
    setSelectedTypeName: (state, action) => {
      state.selectedTypeName = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setTypes: (state, action) => {
      state.types = action.payload;
    },

    setConsumablesStateLoaded: (state, action) => {
      state.consumablesStateLoaded = action.payload;
    },

    removeConsumablesProductSubscription: (state, action) => {
      if (state.consumablesProductSubscriptions) {
        state.consumablesProductSubscriptions = state.consumablesProductSubscriptions.filter(
          (productSubscription: ConsumablesProductSubscription) => productSubscription.id !== action.payload
        );
      }
    },

    setConsumablesProductSubscriptions: (state, action) => {
      state.consumablesProductSubscriptions = action.payload;
    },

    setConsumablesOrders: (state, action) => {
      state.consumablesOrders = action.payload;
    },

    updateConsumablesOrder: (state, action) => {
      if (!state.consumablesOrders) return;
      state.consumablesOrders = state.consumablesOrders?.map(order =>
        order.id === action.payload.id ? { ...order, has_given_feedback: action.payload.has_given_feedback } : order
      );
    }
  }
});

export const { actions } = consumablesSlice;

export default consumablesSlice.reducer;
