import { Clickable, Icon, Layout, Text, TextButton } from 'ds';
import { selectDefaultPaymentMethod } from 'store/Tenancy/selectors';
import { useAppSelector } from 'store/hooks';

import { ReactComponent as StripeBank } from 'ds/StripeBank.svg';

interface PaymentMethodCardProps {
  onClick: () => void;
  onUpdate: () => void;
}
const PaymentMethodCard = ({ onClick, onUpdate }: PaymentMethodCardProps) => {
  const defaultPaymentMethod = useAppSelector(selectDefaultPaymentMethod);

  if (!defaultPaymentMethod) {
    return (
      <Clickable onClick={onClick} fullWidth>
        <Layout padding={12} height={64} borderRadius={8} justify="space-between" align="center" borderWidth={1}>
          <Layout direction="row" gap={12} align="center">
            <StripeBank />
            <Text size="body-md" semibold>
              Add your bank
            </Text>
          </Layout>
          <Icon name="plus" color="purple-gradient" size="xl" />
        </Layout>
      </Clickable>
    );
  }
  return (
    <Layout
      direction="row"
      paddingY={12}
      paddingX={16}
      borderRadius={8}
      borderColor="gray-50"
      borderWidth={1}
      justify="space-between"
    >
      <Layout direction="row" gap={12} align="center">
        <StripeBank />
        <Layout direction="column">
          <Text size="body-sm" semibold>
            {defaultPaymentMethod.us_bank_account?.bank_name}
          </Text>
          <Text size="body-xs" color="gray-400">
            •••• {defaultPaymentMethod.us_bank_account?.last4}
          </Text>
        </Layout>
      </Layout>
      <TextButton text="Update" color="blue-500" textSize="body-sm" semibold={false} onClick={onUpdate} />
    </Layout>
  );
};

export default PaymentMethodCard;
