import { useMemo } from 'react';

import { Clickable, Layout, Text } from 'ds';

import { ReactComponent as Cleaning } from './Cleaning.svg';
import { ReactComponent as PantryAndSupplies } from './Pantry_and_supplies.svg';
import { ReactComponent as TellMeMore } from './TellMeMore.svg';

type NuxPromptCardType = 'pantry_and_supplies' | 'office_cleaning' | 'how_can_we_help';

interface NuxPromptCardProps {
  description: string;
  type: NuxPromptCardType;
  onClick: () => void;
  id: string;
}

const NuxPromptCard = ({ description, type, onClick, id }: NuxPromptCardProps) => {
  const iconContent = useMemo(() => {
    switch (type) {
      case 'how_can_we_help':
        return (
          <Layout width={128} height={128}>
            <TellMeMore />
          </Layout>
        );
      case 'pantry_and_supplies':
        return <PantryAndSupplies />;
      case 'office_cleaning':
        return <Cleaning />;
    }
  }, [type]);
  return (
    <Clickable onClick={onClick} id={id}>
      <Layout
        className="nux-prompt-card"
        width="100%"
        height={223.33}
        padding={12}
        borderRadius={16}
        flex
        direction="column"
        gap={12}
        boxShadow="2px 0px 22px 0px rgba(87, 73, 109, 0.12)"
      >
        <Layout
          width={224}
          height={167.33}
          borderRadius={12}
          background="linear-gradient(60.89deg, rgba(0, 120, 255, 0.1) 0.07%, rgba(142, 95, 244, 0.1) 100%)"
          justify="center"
          align="center"
        >
          {iconContent}
        </Layout>
        <Text size="body-sm" semibold>
          {description}
        </Text>
      </Layout>
    </Clickable>
  );
};

export default NuxPromptCard;
