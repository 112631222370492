import React, { useContext, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { DateTime } from 'luxon';

import { getScreenSize, media } from 'context';
import {
  determineCardLatLng,
  generateLocationKey,
  generateSuperCluster,
  shiftOverlappingWorkspaces
} from 'ds/map/utils';
import { track } from 'lib/analytics';
import { googleBoundsToCodiBounds } from 'lib/location';

import MapMarkers from './MapMarkers';
import MapWorkspaceCard from './MapWorkspaceCard';
import { LIGHT_MAP_STYLES, MAX_CLUSTER_ZOOM, MapScreenSize, SCREEN_SIZE_TO_CARD_WIDTH } from './constants';
import { BuildingGroups, GroupedWorkspaces, MapProps, OnMarkerClick } from './types';
import GhostWorkspaceCard from '../GhostWorkspaceCard';

const Map: React.FC<MapProps> = ({
  workspaces,
  setSelectedWorkspaceId,
  selectedWorkspaceId,
  splitMapView,
  center,
  defaultCenter,
  defaultZoom,
  onChange,
  onMarkerClickCenterChange,
  onClusterClick,
  onTilesLoaded,
  onGoogleApiLoaded,
  options,
  isAdmin = false,
  name = '',
  workspaceCardType,
  markerType,
  daysPerWeek,
  offsitesOnly,
  onDragEnd,
  onZoomAnimationEnd,
  showActiveWorkspaceCard = true,
  markers = [],
  onClick,
  ...props
}) => {
  const [cardRect, setCardRect] = useState<DOMRect>();
  const mediaContext = useContext(media);
  const [dragging, setDragging] = useState<boolean>(false);
  const onMarkerClick: OnMarkerClick = e => {
    markerClickedAtRef.current = DateTime.now();
    props.onMarkerClick?.(e);
  };
  const markerClickedAtRef = useRef<DateTime>();
  const clearWorkspace = () => {
    // <hack> to make mobile UI work</hack>
    if (markerClickedAtRef.current && DateTime.now().diff(markerClickedAtRef.current).toMillis() < 300) {
      return;
    }
    if (!setSelectedWorkspaceId) return;

    setSelectedWorkspaceId(null);
  };
  let screenSize: MapScreenSize = getScreenSize(mediaContext);
  const localMapRef = useRef<google.maps.Map | null>(null);
  const mapRef = props.mapRef || localMapRef;
  const zoom = props.zoom || mapRef.current?.getZoom();
  const zoomLevelRef = useRef<number | undefined>(zoom);
  const [visitedWorkspaceIds, setVisitedWorkspaceIds] = useState<number[]>([]);

  const superCluster = generateSuperCluster({ workspaces });
  const refBounds = mapRef.current?.getBounds();
  const bounds = props.bounds || (refBounds ? googleBoundsToCodiBounds(refBounds) : undefined);
  const clusters =
    props.useClusters && bounds && zoom
      ? superCluster.getClusters([bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat], zoom)
      : [];
  const useClusters = props.useClusters && !!clusters.length && clusters.some(c => c.properties.point_count || 0 > 1);

  useEffect(() => {
    if (!selectedWorkspaceId) return;

    if (!visitedWorkspaceIds.includes(selectedWorkspaceId)) {
      setVisitedWorkspaceIds([...visitedWorkspaceIds, selectedWorkspaceId]);
    }
  }, [selectedWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (screenSize === 'md' && splitMapView) {
    screenSize = 'mdSV';
  }

  const selectedWorkspace = workspaces.find(ws => ws.id === selectedWorkspaceId);
  const cardWidth = cardRect?.width;
  const cardHeight = cardRect?.height;
  const map = mapRef.current;

  const buildingGroups: BuildingGroups = workspaces.reduce<BuildingGroups>((groups, ws) => {
    const {
      address: { lat, lon: lng }
    } = ws;
    const key = generateLocationKey(ws);
    const group = groups[key];

    if (group) {
      groups[key] = { ...group, workspaces: [...group.workspaces, ws] };
    } else {
      groups[key] = {
        lat,
        lng,
        workspaces: [ws]
      };
    }

    return groups;
  }, {});
  const workspaceGroups =
    typeof zoom === 'number' && zoom < 18
      ? Object.values(buildingGroups).filter(({ workspaces }) => workspaces.length > 1)
      : [];

  let filteredWorkspaces = workspaces.filter(ws => {
    const price = !!offsitesOnly ? ws.daily_rate : ws.monthly_price;
    const priceRequirementMet = markerType === 'price' ? price && price > 0 : true;
    let isPartOfBuildingGroup = false;

    if (map && typeof zoom === 'number' && zoom < 18) {
      const buildingWorkspaces = buildingGroups[generateLocationKey(ws)]?.workspaces || [];

      isPartOfBuildingGroup = buildingWorkspaces.length > 1;
    }

    return priceRequirementMet && !isPartOfBuildingGroup;
  });

  let shiftedWorkspaces = filteredWorkspaces;
  let overlappingGroups: GroupedWorkspaces = [];

  if (map && typeof zoom === 'number' && zoom > MAX_CLUSTER_ZOOM) {
    const overlapping = shiftOverlappingWorkspaces(shiftedWorkspaces, map);
    shiftedWorkspaces = overlapping.workspaces;
    overlappingGroups = overlapping.groups;
  }

  return (
    <>
      <GhostWorkspaceCard
        width={workspaceCardType === 'search' ? 264 : SCREEN_SIZE_TO_CARD_WIDTH[screenSize]}
        type={workspaceCardType}
        onMeasure={cardRect => setCardRect(cardRect)}
      />
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API,
          id: 'codi-google-maps-script',
          libraries: ['places', 'geometry']
        }}
        zoom={zoom}
        options={{
          fullscreenControl: false,
          scrollwheel: false,
          gestureHandling: 'greedy',
          clickableIcons: false,
          disableDoubleClickZoom: !!selectedWorkspaceId,
          styles: LIGHT_MAP_STYLES,
          ...options
        }}
        onClick={onClick}
        onChange={onChange}
        center={center}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={params => {
          const { map } = params;

          if (props.mapRef) {
            props.mapRef.current = map;
          } else {
            mapRef.current = map;
          }

          onGoogleApiLoaded && onGoogleApiLoaded(params);
        }}
        onDrag={() => {
          setDragging(true);
        }}
        onDragEnd={mapObj => {
          track('Element Interacted', {
            type: 'map',
            action: 'drag',
            name,
            value: `${mapObj.center.lat()},${mapObj.center.lng()}`
          });
          setDragging(false);

          const map = mapRef.current;

          if (!map || !onDragEnd) return;

          onDragEnd(map);
        }}
        onZoomAnimationEnd={(zoomLevel: number) => {
          const zoom = zoomLevelRef.current;
          if (typeof zoom === 'number' && zoomLevel > zoom) {
            track('Element Interacted', { type: 'map', action: 'zoom in', name, value: zoomLevel });
          } else {
            track('Element Interacted', { type: 'map', action: 'zoom out', name, value: zoomLevel });
          }

          zoomLevelRef.current = zoomLevel;
          onZoomAnimationEnd && onZoomAnimationEnd(zoomLevel);
        }}
        onTilesLoaded={onTilesLoaded}
        yesIWantToUseGoogleMapApiInternals
      >
        {/* these components are rendered by function call instead of JSX because google-map-react
        only supports rendering lat, lng with first level children */}
        {MapMarkers({
          shiftedWorkspaces,
          workspaces,
          workspaceGroups,
          markers,
          cardWidth,
          cardHeight,
          visitedWorkspaceIds,
          selectedWorkspaceId,
          offsitesOnly,
          daysPerWeek,
          onMarkerClickCenterChange,
          onMarkerClick,
          markerType,
          setSelectedWorkspaceId,
          useClusters: !!useClusters,
          onClusterClick,
          mapRef,
          zoom,
          // @ts-expect-error
          clusters,
          // @ts-expect-error
          superCluster
        })}
        {mapRef.current && cardWidth && cardHeight && selectedWorkspace && showActiveWorkspaceCard && !useClusters && (
          <MapWorkspaceCard
            type={workspaceCardType}
            key={selectedWorkspace.id}
            dragging={dragging}
            width={cardWidth}
            height={cardHeight}
            workspace={selectedWorkspace}
            {...determineCardLatLng({
              map: mapRef.current,
              cardWidth,
              cardHeight,
              latLng: {
                lat: selectedWorkspace.address.lat,
                lng: selectedWorkspace.address.lon
              },
              overlappingGroups,
              selectedWorkspaceId: selectedWorkspace.id
            })}
            clearWorkspace={clearWorkspace}
            isAdmin={isAdmin}
            daysPerWeek={daysPerWeek}
            offsitesOnly={!!offsitesOnly}
          />
        )}
      </GoogleMapReact>
    </>
  );
};

export default Map;
