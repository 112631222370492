import { DateTime } from 'luxon';

export function nextDeliveryDate(today: DateTime = DateTime.now()): DateTime {
  const candidate2 = upcomingNthFriday(today, 2);
  const candidate4 = upcomingNthFriday(today, 4);

  const daysUntil2 = candidate2.diff(today, 'days').days;
  const daysUntil4 = candidate4.diff(today, 'days').days;

  if (daysUntil4 < daysUntil2) {
    return daysUntil4 >= 5 ? candidate4 : candidate2;
  } else {
    return daysUntil2 >= 5 ? candidate2 : candidate4;
  }
}

export function upcomingNthFriday(today: DateTime = DateTime.now(), n: number): DateTime {
  let year = today.year;
  let month = today.month; // Note: months are 1-based in Luxon

  // Calculate the first Friday of the month
  let firstOfMonth = DateTime.local(year, month, 1);
  let firstFridayOffset = (5 - firstOfMonth.weekday + 7) % 7;
  let firstFriday = firstOfMonth.plus({ days: firstFridayOffset });
  let candidate = firstFriday.plus({ days: (n - 1) * 7 });

  // If candidate is not in the future, calculate for next month
  if (candidate <= today) {
    const nextMonth = firstOfMonth.plus({ months: 1 });
    year = nextMonth.year;
    month = nextMonth.month;

    firstOfMonth = DateTime.local(year, month, 1);
    firstFridayOffset = (5 - firstOfMonth.weekday + 7) % 7;
    firstFriday = firstOfMonth.plus({ days: firstFridayOffset });
    candidate = firstFriday.plus({ days: (n - 1) * 7 });
  }

  return candidate;
}
