import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { RootState } from 'store/store';

import { CleaningServiceCalendarDate } from './types';

// TODO: make selector for hasCleaningService?
// with request that gets all services for tenant?
export const selectCleaningServiceSchedules = (state: RootState) => state.CleaningService.schedules;
export const selectHasCleaningServiceSchedules = createSelector(
  selectCleaningServiceSchedules,
  schedules => !!schedules && !!schedules.length
);
export const selectCleaningServiceCalendarTaskGroups = (state: RootState) => state.CleaningService.calendarTaskGroups;
export const selectCleaningServiceCalendarDates = createSelector(
  [
    selectCleaningServiceCalendarTaskGroups,
    (_state: RootState, dateRange: { startDate: DateTime; endDate: DateTime }) => dateRange
  ],
  (calendarTaskGroups, dateRange) => {
    const effectiveStartDate = dateRange?.startDate;
    const effectiveEndDate = dateRange?.endDate;
    const dates: CleaningServiceCalendarDate[] = [];
    let currentDate = effectiveStartDate;

    while (currentDate <= effectiveEndDate) {
      const currentDateISO = currentDate.toISODate();
      const calendarDateTaskGroup = calendarTaskGroups.find(c => c.date === currentDateISO);
      const combinedDate: CleaningServiceCalendarDate = {
        summary: calendarDateTaskGroup
          ? {
              price: calendarDateTaskGroup.price,
              is_recurring: calendarDateTaskGroup.is_recurring,
              type: calendarDateTaskGroup.type,
              is_confirmed: calendarDateTaskGroup.is_confirmed
            }
          : undefined,
        tasks: calendarDateTaskGroup ? calendarDateTaskGroup.tasks : [],
        date: currentDate
      };
      dates.push(combinedDate);
      currentDate = currentDate.plus({ days: 1 });
    }

    return dates;
  }
);
export const selectIsWaitingOnVendor = createSelector(selectCleaningServiceSchedules, schedules => {
  return schedules.length > 0 && schedules.every(s => s.status === 'pending');
});
export const selectHasCleaningService = createSelector(selectCleaningServiceSchedules, schedules => {
  return schedules.filter(s => ['active', 'upcoming'].includes(s.status)).length > 0;
});

export const selectCleaningServicePricing = (state: RootState) => state.CleaningService.pricing;
export const selectGaveOnboardExistingCleanerInquiry = (state: RootState) =>
  state.CleaningService.gaveOnboardExistingCleanerInquiry;
