import axios from 'shared/lib/axios';
import {
  DraftProductRentalCartItem,
  ProductRentalCart,
  ProductRentalCartItem,
  ProductSet,
  ProductSetRentalGroup,
  ProductSetRentalOrder
} from 'shared/types';

export function getProductSets() {
  return axios.get<ProductSet[]>('/v1/product_sets');
}

export function getProductRentalCart() {
  return axios.get<ProductRentalCart>('/v1/product_rental_cart');
}

export function addProductRentalCartItem(productRentalCartItem: DraftProductRentalCartItem) {
  return axios.post<ProductRentalCartItem>('/v1/product_rental_cart_items', {
    product_rental_cart_item: productRentalCartItem
  });
}

export function removeProductRentalCartItem(productRentalCartItem: ProductRentalCartItem) {
  return axios.delete<ProductRentalCartItem>(`/v1/product_rental_cart_items/${productRentalCartItem.id}`);
}

export function updateProductRentalCartItem(productRentalCartItem: ProductRentalCartItem) {
  return axios.put<ProductRentalCartItem>(
    `/v1/product_rental_cart_items/${productRentalCartItem.id}`,
    productRentalCartItem
  );
}

export function checkoutProductRentalCart() {
  return axios.post('/v1/product_rental_cart/checkout');
}

export function getProductSetRentalOrders() {
  return axios.get<ProductSetRentalOrder[]>('/v1/product_set_rental_orders');
}

export function getActiveProductSetRentals() {
  return axios.get('/v1/product_set_rentals');
}

export function createProductSetRentalGroupFeedback(productSetRentalGroupId: number, liked: boolean, message?: string) {
  return axios.post<ProductSetRentalGroup>(`/v1/product_set_rental_groups/${productSetRentalGroupId}/feedback`, {
    liked,
    message
  });
}

export function createFurniturePurchaseInquiry(productSetId: number) {
  return axios.post('/v1/furniture_purchase_inquiries', { product_set_id: productSetId });
}
