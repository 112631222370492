import React, { useContext, useEffect } from 'react';

import { media } from 'context';
import { Icon, Layout, Modal, PillButton, Text, TextButton } from 'ds';
import { CLEANING_SERVICE_PATH, FURNITURE_PATH, PANTRY_PATH } from 'routes';
import { actions as cleaningServiceActions } from 'store/CleaningService';
import { selectHasCleaningServiceSchedules } from 'store/CleaningService/selectors';
import { actions as consumablesActions } from 'store/Consumables';
import { selectHasProductSubscriptions } from 'store/Consumables/selectors';
import { actions as furnitureAndEquipmentActions } from 'store/FurnitureAndEquipment';
import { selectHasFurnitureAndEquipment } from 'store/FurnitureAndEquipment/selectors';
import { selectActivePlatformFee } from 'store/Tenancy/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCleaningServiceSchedules } from 'ux/Tenant/CleaningService/requests';
import { getActiveProductSetRentals } from 'ux/Tenant/FurnitureAndEquipment/requests';
import { getConsumablesProductSubscriptions } from 'ux/Tenant/PantryAndSupplies/requests';

import { ReactComponent as AiAssistantIcon } from 'ds/ai-assistant.svg';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const KickoffModal: React.FC<Props> = ({ isVisible, onClose }) => {
  const { isMobile } = useContext(media);
  const activePlatformFee = useAppSelector(selectActivePlatformFee);
  const services = activePlatformFee?.services;
  const hasCleaningServiceSchedules = useAppSelector(selectHasCleaningServiceSchedules);
  const hasProductSubscriptions = useAppSelector(selectHasProductSubscriptions);
  const hasFurnitureAndEquipment = useAppSelector(selectHasFurnitureAndEquipment);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadCleaningServiceSchedules();
    loadProductSubscriptions();
    loadFurnitureInventory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const threadInput = document.querySelector('.Chatbot-threadInput');
    if (!threadInput) return;

    if (isVisible) {
      (threadInput as HTMLElement).blur();
    } else {
      (threadInput as HTMLElement).focus();
    }
  }, [isVisible]);

  const loadCleaningServiceSchedules = () => {
    getCleaningServiceSchedules().then(({ data }) => dispatch(cleaningServiceActions.setSchedules(data)));
  };

  const loadProductSubscriptions = () => {
    getConsumablesProductSubscriptions().then(({ data }) =>
      dispatch(consumablesActions.setConsumablesProductSubscriptions(data))
    );
  };

  const loadFurnitureInventory = () => {
    getActiveProductSetRentals().then(({ data }) => {
      dispatch(furnitureAndEquipmentActions.setProductSetRentalInventory(data));
    });
  };

  const servicesKeyOrder = ['office_assistant', 'cleaning', 'consumables', 'basic_furniture', 'full_furniture'];
  const servicesOrdered = services
    ? [...services].sort((a, b) => servicesKeyOrder.indexOf(a.key) - servicesKeyOrder.indexOf(b.key))
    : undefined;

  const iconSize = isMobile ? 56 : 56;
  const middleColumnWidth = isMobile ? 210 : 260;
  const buttonWidth = isMobile ? 60 : 112;
  const buttonText = isMobile ? 'Start' : 'Get started';

  const serviceConfig = {
    office_assistant: {
      icon: <AiAssistantIcon width={32} height={32} />,
      title: 'Meet your AI office assistant',
      description: 'Chat with Ivy to streamline your work',
      flipIcon: false,
      showButton: false,
      buttonHref: ''
    },
    cleaning: {
      icon: <Icon name="featherDuster" color="purple-gradient" size="xl" />,
      title: 'Schedule cleanings',
      description: 'Schedule weekly or one-time cleanings',
      flipIcon: true,
      showButton: hasCleaningServiceSchedules,
      buttonHref: CLEANING_SERVICE_PATH
    },
    consumables: {
      icon: <Icon name="beverageSupplies" color="purple-gradient" size="xl" />,
      title: 'Select pantry items & supplies',
      description: 'Shop items and get restocked twice a month',
      flipIcon: false,
      showButton: hasProductSubscriptions,
      buttonHref: PANTRY_PATH
    },
    basic_furniture: {
      icon: <Icon name="standing_desk" color="purple-gradient" size="xl" />,
      title: 'Shop furniture',
      description: 'Upgrade to specific furniture items',
      flipIcon: false,
      showButton: hasFurnitureAndEquipment,
      buttonHref: FURNITURE_PATH
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      bottomSheetIsRounded={isMobile}
      onClose={onClose}
      width={isMobile ? undefined : 500}
      showCloseButton={false}
      paddingX={24}
      closeOnClickOutside={false}
      footer={
        <Layout justify="center" align="center" width="100%" paddingBottom={12}>
          <TextButton onClick={onClose} size="md" text="Skip for now" textSize="body-sm" color="black" />
        </Layout>
      }
    >
      <Layout direction="column" paddingTop={28} paddingX={46} paddingBottom={12} gap={12} align="center">
        <AiAssistantIcon width={72} height={72} />
        <Text size="headline-md" color="purple-gradient">
          Let's kick things off.
        </Text>
      </Layout>
      <Layout paddingX={24} paddingTop={32} direction="column" gap={24} flexGrow={1}>
        {servicesOrdered?.map(service => {
          return (
            <Layout key={service.key} direction="row" justify="space-between" align="center" flexGrow={1}>
              <Layout flexGrow={1} direction="row" justify="flex-start" align="center" gap={12}>
                <Layout
                  width={iconSize}
                  height={iconSize}
                  color="purple-gradient-transparent"
                  borderRadius={8}
                  justify="center"
                  align="center"
                  transform={serviceConfig[service.key as keyof typeof serviceConfig].flipIcon ? 'rotate(180deg)' : ''}
                >
                  {serviceConfig[service.key as keyof typeof serviceConfig].icon}
                </Layout>
                <Layout direction="column" gap={4} width={middleColumnWidth}>
                  <Text size="body-sm">{serviceConfig[service.key as keyof typeof serviceConfig].title}</Text>
                  <Text size="body-xs" color="gray-400">
                    {serviceConfig[service.key as keyof typeof serviceConfig].description}
                  </Text>
                </Layout>
              </Layout>
              {serviceConfig[service.key as keyof typeof serviceConfig].showButton ? (
                <Layout width={buttonWidth}>
                  <PillButton
                    href={serviceConfig[service.key as keyof typeof serviceConfig].buttonHref}
                    size="md"
                    text={buttonText}
                    type="purpleGradient"
                    fullWidth
                  />
                </Layout>
              ) : (
                <Layout padding={12} justify="center" align="center">
                  <Icon name="checkmark" color="purple-gradient" size="md" />
                </Layout>
              )}
            </Layout>
          );
        })}
      </Layout>
    </Modal>
  );
};

export default KickoffModal;
