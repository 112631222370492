import { FocusEventHandler, forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Animate from 'ds/Animate';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import { selectInputFocusOrdinal } from 'store/AIAssistant/selectors';

interface Props {
  handleSubmit: () => void;
  disabled: boolean;
  input: string;
  onChange: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disableAnimation?: boolean;
}

const ChatbotThreadInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, input, handleSubmit, onFocus, onBlur, disableAnimation }, ref) => {
    const inputFocusOrdinal = useSelector(selectInputFocusOrdinal);

    useEffect(() => {
      if (inputFocusOrdinal === 0) return;

      if (ref && 'current' in ref) {
        ref.current?.focus();
      }
    }, [inputFocusOrdinal]); // eslint-disable-line react-hooks/exhaustive-deps

    const Content = (
      <Layout align="center" borderRadius={1000}>
        <input
          className="Chatbot-threadInput"
          autoFocus={true}
          value={input}
          onChange={e => onChange(e.target.value)}
          onKeyDown={({ key }) => {
            if (ref && 'current' in ref) {
              if (document.activeElement !== ref.current) {
                return;
              }
            }

            if (!input) return;

            if (key === 'Enter') {
              handleSubmit();
            }
          }}
          onFocus={onFocus}
          autoComplete="off"
          ref={ref}
          spellCheck={false}
          placeholder="Message..."
          onBlur={onBlur}
        />
        <Layout width={768} position="absolute" align="center">
          <Layout position="absolute" right={12}>
            <IconButton size="sm" type="primaryBlue" name="upArrow" onClick={handleSubmit} stroke={2} />
          </Layout>
        </Layout>
      </Layout>
    );

    return (
      <Animate duration={400} animation="fade-up" disableAnimation={disableAnimation} delay={20}>
        {Content}
      </Animate>
    );
  }
);

export default ChatbotThreadInput;
