import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';

import { Path } from 'routes';
import { safeAssign } from 'shared';

const initialAuthState = {};

const initialState: UIState = {
  ...initialAuthState,
  snackbarMessages: [],
  publicHeaderHasShadow: true,
  officeRequestWizardHideFooter: false,
  defaultTypeaheadResults: {},
  finishAddingPropertyModalDismissed: false,
  bottomNavigationIsVisible: true,
  uxType: undefined,
  selectedOfficeId: null,
  prevPathname: null
};

export type Redirect = { pathname: Path; search?: string };

export type UxType = 'admin' | 'customer' | 'property' | 'vendor' | 'tenant';

export type UIState = {
  snackbarMessages: SnackbarMessage[];
  publicHeaderHasShadow: boolean;
  officeRequestWizardHideFooter: boolean;
  defaultTypeaheadResults: { [name: string]: { ids: (number | string)[] } };
  finishAddingPropertyModalDismissed: boolean;
  uxType?: UxType;
  bottomNavigationIsVisible: boolean;
  appBannerType?: 'review' | 'recommendations' | 'platform_fee_proposal' | null;
  selectedOfficeId: number | null;
  prevPathname: string | null;
};

export type SnackbarMessage = {
  value: string;
  duration?: number;
  id: number;
  negative?: boolean;
  link?: { href: string; text: string };
  img?: {
    srcKey: string;
    type?: 'workspace';
  };
  CTA?: {
    text: string;
    onClick: VoidFunction;
  };
  position?: 'left' | 'center';
  type?: 'light' | 'dark';
};

let snackbarId = 1;

const uiSlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    resetAuthState: state => {
      safeAssign(state, initialAuthState);
    },
    addSnackbarMessage: (state, { payload }: PayloadAction<Omit<SnackbarMessage, 'id'>>) => {
      state.snackbarMessages.push({ ...payload, id: snackbarId });
      snackbarId++;
    },
    clearSnackbarMessage: (state, { payload }: PayloadAction<Pick<SnackbarMessage, 'id'>>) => {
      state.snackbarMessages = state.snackbarMessages.filter(s => s.id !== payload.id);
    },
    setPublicHeaderHasShadow: (state, { payload }: PayloadAction<boolean>) => {
      state.publicHeaderHasShadow = payload;
    },
    setOfficeRequestWizardHideFooter: (state, { payload }: PayloadAction<boolean>) => {
      state.officeRequestWizardHideFooter = payload;
    },
    updateUIState: (state, { payload }: PayloadAction<Partial<UIState>>) => {
      return { ...state, ...payload };
    },
    addDefaultTypeaheadResults: (
      state,
      { payload: { name, id } }: PayloadAction<{ name: string; id: string | number }>
    ) => {
      const currentResults = state.defaultTypeaheadResults[name];

      if (!currentResults) {
        state.defaultTypeaheadResults[name] = { ids: [] };
      }
      const currentIds = currentResults?.ids || [];
      const updatedIds = [...currentIds];
      updatedIds.unshift(id);

      state.defaultTypeaheadResults[name].ids = uniq(updatedIds.slice(0, 5));
    },
    setSelectedOfficeId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedOfficeId = payload;
    }
  }
});

export const { actions } = uiSlice;

export default uiSlice.reducer;
