import React, { useContext, useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';

import { media } from 'context';
import { Button, Layout, Modal, PillButton, Text } from 'ds';
import { BLUE_500_HEX, BLUE_50_HEX, GRAY_400_HEX, GRAY_50_HEX, GRAY_700_HEX, GRAY_900_HEX } from 'shared';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { createSetupIntent, getDefaultPaymentMethod, updateDefaultPaymentMethod } from './requests';

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === 'production' ? 'pk_live_FIjwb0XoD7GN4J2GCukoKk2u' : 'pk_test_IKX09H9MA4Vpme8wKsNUqrjQ'
);

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

const PaymentMethods: React.FC<Props> = ({ isVisible, onUpdate, onClose }) => {
  const { isMobile } = useContext(media);
  if (isMobile) {
    require('./StripePaymentMethodModal-mobile.scss');
  }
  const user = useAppSelector(selectUser);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>();
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    getDefaultPaymentMethod().then(({ data }) => setDefaultPaymentMethod(data));
  }, []);

  if (!user || defaultPaymentMethod === undefined) return null;

  const handleChange = (event: any) => {
    if (event.complete) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  };

  const handleSubmit = async () => {
    // event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setRequestInProgress(true);
    setError(null);

    try {
      const bankAccountElement = elements.getElement(PaymentElement);
      if (!bankAccountElement) {
        setError('Bank account element not found');
        return;
      }

      const { error: setupError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              name: user.customer?.name || '',
              email: user.customer?.email || ''
            },
            allow_redisplay: 'always'
          }
        },
        redirect: 'if_required'
      });

      if (setupError) {
        setError(setupError.message || 'An error occurred');
        return;
      }

      updateDefaultPaymentMethod().then(() => {
        onClose();
        onUpdate();
        setRequestInProgress(false);
      });
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Modal
      className="StripePaymentMethodModal"
      bottomSheetIsRounded={isMobile}
      onClose={onClose}
      isVisible={isVisible}
      width={isMobile ? undefined : 360}
      header="Add your bank"
      headerAlign="left"
      hasFooterBorder={false}
      footer={
        isMobile ? (
          <PillButton
            type="primary"
            size="lg"
            text="Save bank"
            fullWidth
            disabled={!stripe || requestInProgress || saveButtonDisabled}
            showSpinner={requestInProgress}
            onClick={handleSubmit}
          />
        ) : (
          <Layout direction="row" justify="flex-end" gap={12}>
            <Button size="sm" type="secondary" text="Cancel" onClick={onClose} />
            <Button
              size="sm"
              type="primary"
              text="Save bank"
              disabled={!stripe || requestInProgress || saveButtonDisabled}
              showSpinner={requestInProgress}
              onClick={handleSubmit}
            />
          </Layout>
        )
      }
      paddingBottom={0}
    >
      <Layout direction="column" height={isMobile ? undefined : 472} paddingX={isMobile ? 24 : 0}>
        <Text size="body-sm" color="gray-700">
          Please enter your bank account information below:
        </Text>
        <Layout width="100%">
          <form onSubmit={handleSubmit}>
            <Layout marginBottom={24} className={isMobile ? 'StripePaymentMethodModal-mobile' : ''}>
              <PaymentElement
                onChange={handleChange}
                options={{
                  business: {
                    name: user?.customer?.name || ''
                  },
                  fields: {
                    billingDetails: {
                      name: 'never',
                      email: 'never'
                    }
                  },
                  paymentMethodOrder: ['us_bank_account']
                }}
              />
            </Layout>

            {error && (
              <Layout padding={16} marginBottom={16} borderRadius={4}>
                <Text color="red-500">{error}</Text>
              </Layout>
            )}
          </form>
        </Layout>
      </Layout>
    </Modal>
  );
};

interface PaymentMethodsWrapperProps {
  isVisible: boolean;
  onUpdate: () => void;
  onClose: () => void;
}

const PaymentMethodsWrapper: React.FC<PaymentMethodsWrapperProps> = ({ isVisible, onUpdate, onClose }) => {
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    createSetupIntent().then(({ data }) => setClientSecret(data.clientSecret));
  }, []);

  if (!clientSecret) {
    return null; // or loading spinner
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900'
          }
        ],
        clientSecret,
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: GRAY_700_HEX,
            colorDanger: '#df1b41',
            fontFamily: 'Inter',
            fontSizeBase: '14px',
            spacingUnit: '4px',
            borderRadius: '4px',
            gridRowSpacing: '12px',
            gridColumnSpacing: '12px'
          },
          rules: {
            '.Input': {
              border: `1px solid ${GRAY_50_HEX}`,
              borderRadius: '8px',
              boxShadow: 'none',
              padding: '16px',
              fontSize: '12px',
              color: GRAY_400_HEX
            },
            '.Input:focus': {
              boxShadow: 'none',
              borderColor: 'none'
            },
            '.PickerItem': {
              border: 'none',
              boxShadow: '2px 0px 22px 0px rgba(87, 73, 109, 0.06)',
              borderRadius: '8px',
              color: GRAY_900_HEX,
              fontSize: '12px'
            },
            '.PickerItem:hover': {
              backgroundColor: BLUE_50_HEX
            },
            '.PickerItem--selected': {
              border: `1px solid ${GRAY_50_HEX}`,
              boxShadow: 'none'
            },
            '.PickerItem--selected:hover': {
              border: `1px solid ${GRAY_50_HEX}`,
              boxShadow: 'none'
            },
            '.PickerAction': {
              color: BLUE_500_HEX
            },
            '.Label': {
              fontSize: '14px',
              color: GRAY_700_HEX
            }
          }
        }
      }}
    >
      <PaymentMethods isVisible={isVisible} onUpdate={onUpdate} onClose={onClose} />
    </Elements>
  );
};

export default PaymentMethodsWrapper;
