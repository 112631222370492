import { Layout } from 'ds';

interface OrderProgressBarProps {
  status: 'open' | 'locked';
}

const OrderProgressBar = ({ status }: OrderProgressBarProps) => {
  const progressPercentage = status === 'open' ? 5 : 75;
  return (
    <Layout flex width="100%">
      <Layout height={4} color="gray-50" width="100%" borderRadius={8} position="relative">
        <Layout
          height={4}
          color="blue-500"
          width={`${progressPercentage}%`}
          borderRadius={8}
          position="absolute"
          left={0}
        />
      </Layout>
    </Layout>
  );
};

export default OrderProgressBar;
