export const CHATBOT_HOME_SCREEN = 'home';
export const CHATBOT_THREADS_SCREEN = 'threads';
export const CHATBOT_NEW_SCREEN = `${CHATBOT_THREADS_SCREEN}/-1` as const;
export const CHATBOT_DESKTOP_MAX_HEIGHT = 704;
export const CHATBOT_DESKTOP_WIDTH = 390;
export const CHATBOT_BUTTON_DIMENSION = 48;
export const CHATBOT_BOTTOM_NAVIGATION_HEIGHT = 76;
export const CHATBOT_NAVIGATION_HEADER_HEIGHT = 64;
export const CHATBOT_THREAD_VERTICAL_SPACING = 24;

export const GREETING_VARIATIONS = [
  'Hi, {{name}}. This is Codi, your office assistant. How can I help you today?',
  'Hello, {{name}}! This is Codi, your office assistant. How may I assist you today?',
  'Hi {{name}}! Codi here, your office assistant. What can I do for you today?',
  'Hey, {{name}}! This is Codi, your office assistant. How can I support you today?',
  "Hey {{name}}! It's Codi, your office assistant. What do you need help with today?",
  'Greetings, {{name}}! Codi here, your office assistant. How can I be of service today?',
  'Hi there, {{name}}! This is Codi, your office assistant. How can I assist you today?',
  "Hello, {{name}}! It's Codi, your office assistant. How can I help you today?",
  'Hi {{name}}! This is Codi, your friendly office assistant. How may I assist you right now?',
  'Hey there, {{name}}! Codi here, your office assistant. What assistance do you need today?',
  "Hello, {{name}}! It's Codi, your office assistant. How can I help make your day easier?",
  'Hi {{name}}! This is Codi, your office assistant at your service. What do you need today?',
  'Hi {{name}}! Codi here, your office assistant. How can I lend a hand today?',
  "Hey {{name}}! It's Codi, your office assistant. Let me know how I can assist you today!",
  'Greetings, {{name}}! This is Codi, your dedicated office assistant. How can I help you out today?',
  'Hi there, {{name}}! This is Codi, your office assistant. What can I assist with today?',
  'Hello, {{name}}! Codi here, your office assistant ready to help. What do you need assistance with?',
  "Hi, {{name}}! This is Codi, your office assistant eager to assist you today. What's on the agenda?",
  "Hi {{name}}! It's Codi, your office assistant here to help you out today. What's on your mind?",
  'Hey there, {{name}}! This is Codi, your office assistant at your service. How may I assist you today?',
  "Hello, {{name}}! It's Codi, your helpful office assistant. How can I make things easier for you today?",
  'Hi {{name}}! This is Codi, your office assistant ready to help with anything you need today.',
  'Hi {{name}}! Codi here - your office assistant - how can I support you in achieving your goals today?',
  'Greetings, {{name}}! This is Codi, your office assistant. What do you need help with today?',
  "Hello there, {{name}}! It's Codi, your friendly office assistant. What can I do for you today?",
  'Hi {{name}}! This is Codi, your trusted office assistant. How can I assist you today?',
  "Hello {{name}}! It's Codi, your reliable office assistant. What can I help you with?",
  'Hey {{name}}, this is Codi - your dedicated office assistant - ready to assist!',
  'Hi {{name}}! This is Codi, your office assistant, looking forward to helping you today.',
  "Hi there {{name}}! It's Codi, your office assistant, ready to tackle any challenge.",
  'Greetings {{name}}! This is Codi, your office assistant, eager to help you succeed today.',
  "Hello {{name}}! It's Codi, your office assistant, here to make your day smoother.",
  'Hey there {{name}}! This is Codi, your office assistant, ready to provide excellent service.',
  'Hi {{name}}! This is Codi, your office assistant, prepared to help in any way possible.',
  "Hi {{name}}! It's Codi, your office assistant, excited to assist you today.",
  'Hi {{name}}! This is Codi, your office assistant, committed to supporting your needs.',
  'Greetings {{name}}! This is Codi, your office assistant, at your service today.',
  'Hi {{name}}! This is Codi, your office assistant, ready to help you accomplish your tasks.',
  'Hi {{name}}! This is Codi, your office assistant, here to provide solutions.',
  'Welcome, {{name}}! This is Codi - your trusted office assistant - ready to tackle any challenge with you today!',
  'Good day, {{name}}! Codi here, your office assistant, ready to streamline your workday.',
  'Hi {{name}}! This is Codi, your office assistant, standing by to help you navigate any challenges.',
  'Greetings {{name}}! Codi at your service - your dedicated office assistant - how can I help optimize your day?',
  'Hello {{name}}! This is Codi, your office assistant, eager to turn your challenges into solutions.',
  'Hi there {{name}}! Codi here - your office assistant - ready to help you achieve your objectives today.',
  'Welcome aboard, {{name}}! This is Codi, your office assistant, prepared to support your success.',
  "Hey {{name}}! It's Codi, your office assistant, here to transform your tasks into accomplishments.",
  'Greetings {{name}}! This is Codi, your office assistant, ready to collaborate on your needs today.',
  'Hi {{name}}! Codi here, your office assistant, looking forward to making your workday more efficient.',
  'Hi {{name}}! This is Codi, your office assistant, here to help you work smarter, not harder.',
  'Welcome {{name}}! This is Codi, your office assistant, ready to bring efficiency and solutions to your day.'
];
