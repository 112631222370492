import React, { useState } from 'react';

import ComboBox, { Props as ComboBoxProps } from 'ds/ComboBox';
import ComboBoxResult from 'ds/ComboBox/ComboBoxResult';
import { Vendor } from 'shared';

export type Props = Omit<ComboBoxProps<number>, 'results' | 'image' | 'onChange' | 'label'> &
  Partial<Pick<ComboBoxProps<number>, 'onChange' | 'label'>> & {
    vendors: Pick<Vendor, 'id' | 'name'>[];
    selectedVendorId: number | null;
  };

const VendorComboBox: React.FC<Props> = ({
  vendors,
  selectedVendorId,
  label = 'Vendor',
  onChange,
  onSelect,
  ...props
}) => {
  const vendor = vendors.find(v => v.id === selectedVendorId);
  const [vendorQuery, setVendorQuery] = useState<null | string>(vendor?.name || null);

  return (
    <ComboBox
      required={false}
      label={label}
      placeholder="Vendor"
      size="sm"
      results={(vendorQuery
        ? vendors.filter(v => v.name.toLowerCase().includes(vendorQuery.toLowerCase()))
        : vendors
      ).map(v => ({
        value: v.id,
        element: <ComboBoxResult label={v.name} key={v.id} query={vendorQuery || ''} />
      }))}
      query={
        selectedVendorId && vendorQuery === null
          ? vendors.find(v => v.id === selectedVendorId)?.name
          : vendorQuery || ''
      }
      onChange={params => {
        setVendorQuery(params.value);

        onChange?.(params);
      }}
      onSelect={value => {
        setVendorQuery(null);
        onSelect(value);
      }}
      showResultsWhenEmpty
      {...props}
    />
  );
};

export default VendorComboBox;
