import React, { useContext } from 'react';

import { app } from 'context';
import { Icon, Layout, PageLayout, Text } from 'ds';

interface Props {}

const MobileComingSoon: React.FC<Props> = () => {
  const { bottomNavigationHeight } = useContext(app);

  return (
    <PageLayout type="default">
      <Layout height={`calc(100vh - ${bottomNavigationHeight}px)`} align="center" justify="center">
        <Layout direction="column" align="center" gap={16}>
          <Icon name="monitor" color="purple-gradient" size={64} />
          <Text size="headline-sm" color="purple-gradient" align="center">
            Tiny screens deserve
            <br />
            big experiences.
          </Text>
          <Text size="body-sm" color="gray-700" align="center">
            We’re working on it!
            <br />
            Until then, check us out on a desktop.
          </Text>
        </Layout>
      </Layout>
    </PageLayout>
  );
};

export default MobileComingSoon;
