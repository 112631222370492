import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CalendarDateTaskGroup, CleaningServicePricing, CleaningServiceSchedule } from './types';

export interface CleaningServiceState {
  schedules: CleaningServiceSchedule[];
  calendarTaskGroups: CalendarDateTaskGroup[];
  pricing: CleaningServicePricing | null;
  gaveOnboardExistingCleanerInquiry: boolean;
}

const initialState: CleaningServiceState = {
  schedules: [],
  calendarTaskGroups: [],
  pricing: null,
  gaveOnboardExistingCleanerInquiry: false
};

const cleaningServiceSlice = createSlice({
  name: 'CleaningService',
  initialState,
  reducers: {
    setSchedules: (state, action: PayloadAction<CleaningServiceSchedule[]>) => {
      state.schedules = action.payload;
    },
    setCalendarTaskGroups: (state, action: PayloadAction<CalendarDateTaskGroup[]>) => {
      state.calendarTaskGroups = action.payload;
    },
    setPricing: (state, action: PayloadAction<CleaningServicePricing>) => {
      state.pricing = action.payload;
    },
    setGaveOnboardExistingCleanerInquiry: (state, action: PayloadAction<boolean>) => {
      state.gaveOnboardExistingCleanerInquiry = action.payload;
    }
  }
});

export const { actions } = cleaningServiceSlice;
export default cleaningServiceSlice.reducer;
