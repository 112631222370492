import { Color, TaskStatus } from 'shared';

import { ButtonSize, ButtonSizeConfig, ButtonType, ButtonTypeConfig, CountryData, DayOfWeek, TourTime } from './types';

// Used to be more specific than global styles
export const DS_CLASS_NAME = 'codi_ds';
export const HEADER_PORTAL_ID = 'codi-header-portal';

export const ANIMATION_DURATION = 300;

export const IS_TOUCH_DEVICE =
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;

export const SCROLLABLE_BOX_SHADOW = '2px 0 22px 0 rgba(87, 73, 109, 0.12)';

export const COUNTRY_DATA: CountryData[] = [
  {
    callingCode: '1',
    countryName: 'United States / Canada',
    country: 'US'
  },
  {
    callingCode: '44',
    countryName: 'United Kingdom',
    country: 'GB'
  }
];

export const SIDEBAR_PRIMARY_WIDTH = 60;
export const SIDEBAR_EXPANDED_WIDTH = 256;
export const SIDEBAR_PADDING_X = 12;
export const FOOTER_HEIGHT = 96;
export const HEADER_HEIGHT = 72;
export const APP_BANNER_HEIGHT = 56;
export const HEADER_HEIGHT_MOBILE = 56;
export const MOBILE_TITLE_HEIGHT = 68;
export const MAX_CONTENT_WIDTH = 1608;
export const XS_PAGE_MARGIN = 24;
export const SM_PAGE_MARGIN = 40;
export const MD_PAGE_MARGIN = 40;
export const PAGE_MARGIN = 80;
export const HEADER_MAX_WIDTH = 1608;
export const WORKSPACE_PHOTO_W_TO_H_RATIO = 1.5;
export const SAVE_MESSAGE = 'Changes saved.';
export const ERROR_MESSAGE = 'Changes not saved.';
export const BOTTOM_SHEET_TOP = 40;
export const BOTTOM_NAVIGATION_HEIGHT = 60;
export const CONTENT_PADDING_X_XS = 24;
export const CONTENT_PADDING_X = 40;
export const CUSTOMER_TABLE_PADDING_BOTTOM = 48;
export const HEADER_Z_INDEX = 2002;
export const POPOVER_Z_INDEX = HEADER_Z_INDEX - 1;
export const MODAL_Z_INDEX = HEADER_Z_INDEX + 20;
export const SPINNER_SIZE_XS = 24;
export const SPINNER_SIZE_SM = 32;
export const SPINNER_SIZE_MD = 40;
export const SPINNER_SIZE_LG = 50;
export const DESKTOP_WORKSPACE_CARD_HEIGHT = 152;
export const ADMIN_CONTENT_MAX_WIDTH = 900;
export const CONTENT_MAX_WIDTH = 900;
export const XS_PAGE_PADDING_TOP = 24;
export const TOP_BAR_HEIGHT = 96;
export const TOP_BAR_HEIGHT_XS = 72;
export const SECONDARY_MENU_WIDTH = 200;
export const TERTIARY_MENU_WIDTH = 164;
export const DESKTOP_MODAL_BORDER_RADIUS = 12;
export const SERVICE_PAGES_MAX_WIDTH = 1300;

// These values are also declared in constants.scss and need to be manually synced.
export const ICON_SIZE_XXS = 12;
export const ICON_SIZE_XS = 16;
export const ICON_SIZE_SM = 20;
export const ICON_SIZE_MD = 24;
export const ICON_SIZE_LG = 28;
export const ICON_SIZE_XL = 32;

export const FIELD_HEIGHT_XXS = 32;
export const FIELD_HEIGHT_SM = 48;
export const FIELD_HEIGHT_MD = 56;
export const FIELD_HEIGHT_LG = 64;

export const FIELD_PADDING_X_SM = 24;
export const FIELD_PADDING_X_MD = 24;
export const FIELD_PADDING_X_LG = 24;

export const INPUT_BORDER_WIDTH = 1;
export const INPUT_ERROR_BORDER_WIDTH = 2;
export const DEFAULT_BORDER_COLOR = 'gray-50' as const;

export const CALENDAR_WIDTH = 326;

export const DESKTOP_HERO_PROMO_HEIGHT = 200;
export const MOBILE_HERO_PROMO_HEIGHT = 124;
export const SECONDARY_PROMO_HEIGHT = 156;

export const FIELD_CONFIG = {
  xxs: {
    fieldPaddingX: FIELD_PADDING_X_SM,
    height: FIELD_HEIGHT_SM
  },
  xs: {
    fieldPaddingX: FIELD_PADDING_X_SM,
    height: FIELD_HEIGHT_SM
  },
  sm: {
    fieldPaddingX: FIELD_PADDING_X_SM,
    height: FIELD_HEIGHT_SM
  },
  md: {
    fieldPaddingX: FIELD_PADDING_X_MD,
    height: FIELD_HEIGHT_MD
  },
  lg: {
    fieldPaddingX: FIELD_PADDING_X_LG,
    height: FIELD_HEIGHT_LG
  }
} as const;

export const BUTTON_TYPE_CONFIG: Record<ButtonType, ButtonTypeConfig> = {
  primary: {
    color: 'blue-500',
    hoverColor: 'blue-600',
    activeColor: 'blue-700',
    textColor: 'white',
    spinnerColor: 'white',
    disabledColor: 'blue-300'
  },
  primaryInverse: {
    color: 'white',
    hoverColor: 'blue-50',
    activeColor: 'blue-200',
    textColor: 'blue-500',
    spinnerColor: 'blue-500',
    disabledColor: 'gray-50'
  },
  secondary: {
    color: 'gray-25',
    hoverColor: 'gray-50',
    activeColor: 'gray-100',
    textColor: 'gray-900',
    spinnerColor: 'gray-600',
    disabledColor: 'gray-50'
  },
  secondaryOutline: {
    color: 'white',
    hoverColor: 'gray-50',
    activeColor: 'gray-100',
    activeBorderColor: 'gray-100',
    disabledColor: 'gray-50',
    textColor: 'gray-900',
    spinnerColor: 'gray-600',
    borderWidth: 1,
    borderColor: 'gray-50'
  },
  blueGradient: {
    color: 'blue-gradient',
    hoverColor: 'blue-gradient',
    activeColor: 'blue-gradient',
    textColor: 'white',
    spinnerColor: 'white',
    disabledColor: 'blue-gradient'
  },
  purpleGradient: {
    color: 'purple-gradient',
    hoverColor: 'purple-gradient',
    activeColor: 'purple-gradient',
    textColor: 'white',
    spinnerColor: 'white',
    disabledColor: 'purple-gradient'
  }
};

export const BUTTON_SIZE_CONFIG: Record<ButtonSize, ButtonSizeConfig> = {
  xxs: { borderRadius: 8, iconSize: 'xs' },
  xs: { borderRadius: 8, iconSize: 'xs' },
  sm: { borderRadius: 8, iconSize: 'sm' },
  md: { borderRadius: 8, iconSize: 'sm' },
  lg: { borderRadius: 8, iconSize: 'md' }
};

export const ANCHOR_CONFIG = {
  bottom: {
    borderRadius: 0,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderWidth: 0,
    borderTopWidth: 1,
    borderColor: 'gray-50',
    fullWidth: true
  },
  top: {
    borderRadius: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: 'gray-50',
    fullWidth: true
  }
} as const;

export const DAYS_OF_WEEK_ORDERED: DayOfWeek[] = [
  { name: 'Monday', abbreviation: 'Mo', abbreviation3: 'Mon', ordinal: 1 },
  { name: 'Tuesday', abbreviation: 'Tu', abbreviation3: 'Tue', ordinal: 2 },
  { name: 'Wednesday', abbreviation: 'We', abbreviation3: 'Wed', ordinal: 3 },
  { name: 'Thursday', abbreviation: 'Th', abbreviation3: 'Thu', ordinal: 4 },
  { name: 'Friday', abbreviation: 'Fr', abbreviation3: 'Fri', ordinal: 5 },
  { name: 'Saturday', abbreviation: 'Sa', abbreviation3: 'Sat', ordinal: 6 },
  { name: 'Sunday', abbreviation: 'Su', abbreviation3: 'Sun', ordinal: 0 }
];

export const SUNDAY_0_DAYS_OF_WEEK_ORDERED = [DAYS_OF_WEEK_ORDERED[6], ...DAYS_OF_WEEK_ORDERED.slice(0, 6)];

export const COMMON_NON_WORK_EMAIL_DOMAINS = [
  'gmail.com',
  'duck.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'ymail.com',
  'comcast.net',
  'icloud.com',
  'msn.com',
  'aol.com'
];

export const BOOKING_EMAIL = 'book@codi.com';

export const STANDARD_FEATURES = [
  {
    icon: 'settings',
    label: 'Rent',
    description: 'Fully serviced; includes monthly rent and maintenance'
  },
  { icon: 'wifi', label: 'Fast WiFi', description: 'High-speed internet service and networking equipment' },
  { icon: 'chair', label: 'Furniture', description: 'Ergonomic workstations, lounges, and white boards' },
  { icon: 'cleaning', label: 'Cleaning', description: 'Weekly cleaning, kitchen, bathroom supplies' },
  { icon: 'beverage', label: 'Beverages', description: 'Tea, coffee, and sparkling water — restocked monthly' }
] as const;

export const TOUR_TIMING_OPTIONS: TourTime[] = [
  {
    label: 'Morning',
    startsAt: '09:00',
    endsAt: '12:00',
    timeDisplay: '9 AM - 12 PM'
  },
  {
    label: 'Afternoon',
    startsAt: '12:00',
    endsAt: '16:00',
    timeDisplay: '12 - 4 PM'
  },
  {
    label: 'Evening',
    startsAt: '16:00',
    endsAt: '20:00',
    timeDisplay: '4 - 8 PM'
  },
  {
    label: 'Anytime',
    startsAt: '09:00',
    endsAt: '20:00',
    timeDisplay: '9 AM - 8 PM'
  }
];

export const CHATBOT_THREAD_INPUT_HEIGHT = 88;

export const CUSTOMER_SUPPORT_TICKET_STATUS_TO_COLOR = {
  open: 'gold-100',
  closed: 'gray-25'
} as const;

export const CUSTOMER_SUPPORT_TICKET_STATUS_TO_DOT_COLOR = {
  open: 'gold-700',
  closed: 'gray-300'
} as const;

export const CUSTOMER_SUPPORT_TICKET_STATUS_TO_TEXT_COLOR = {
  open: 'gold-700',
  closed: 'gray-900'
} as const;

export interface StatusTagProps {
  backgroundColor: Color;
  textColor: Color;
  dotColor: Color;
  label?: string;
  useOutlineDot?: boolean;
}

export const TASK_STATUS_TO_COLOR_PROPS: Record<TaskStatus, StatusTagProps> = {
  open: {
    backgroundColor: 'gold-100',
    dotColor: 'gold-700',
    textColor: 'gold-900'
  },
  unconfirmed: {
    backgroundColor: 'blue-50',
    dotColor: 'blue-500',
    textColor: 'blue-500',
    useOutlineDot: true
  },
  scheduled: {
    backgroundColor: 'blue-50',
    dotColor: 'blue-500',
    textColor: 'blue-500'
  },
  scheduled_completable: {
    backgroundColor: 'blue-50',
    dotColor: 'blue-500',
    textColor: 'blue-500',
    label: 'Scheduled'
  },
  overdue: {
    backgroundColor: 'red-100',
    dotColor: 'red-700',
    textColor: 'gray-900'
  },
  completed: {
    backgroundColor: 'green-100',
    dotColor: 'green-700',
    textColor: 'green-700'
  },
  canceled: {
    backgroundColor: 'gray-25',
    dotColor: 'gray-400',
    useOutlineDot: true,
    textColor: 'gray-900'
  }
};
