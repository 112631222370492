import React from 'react';

import { Layout, Text } from 'ds';
import Accordion from 'ds/Accordion';

interface Props {
  /** Questions and answers */
  qas: {
    question: string;
    answer: string;
  }[];
  gapX?: number;
}

const FAQs: React.FC<Props> = ({ qas, gapX }) => {
  return (
    <Layout>
      <Text size="display-sm" color="blue-600" align="center">
        Codi FAQ
      </Text>
      <Layout marginTop={32}>
        <Accordion
          columns={2}
          items={qas.map(qa => {
            return { title: qa.question, body: qa.answer };
          })}
          gapX={gapX}
        />
      </Layout>
    </Layout>
  );
};

export default FAQs;
