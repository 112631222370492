import axios, { AxiosError } from 'axios';
import { DateTime } from 'luxon';

import { ApiErrorResponse } from 'shared/types';

const axiosInstance = axios.create();

interface ApiError {
  error: string;
  message: string;
}

const handleError = (error: Error | AxiosError<ApiError>) => {
  if (!axios.isAxiosError(error) || !error.response) {
    const type = error.message.toLowerCase().includes('network error')
      ? 'NetworkError'
      : error.message.replace(/ /g, '');
    const errorResponse: ApiErrorResponse = { status: 0, data: {}, message: error.message, type };
    return Promise.reject(errorResponse);
  }

  const {
    data: { error: errorType, ...response },
    status
  } = error.response;

  // TOOD: make API return consistent type and shape.
  let [data, message] =
    response.message && isJson(response.message) ? [JSON.parse(response.message), ''] : [{}, response.message];

  if (data.base && Array.isArray(data.base)) {
    message = data.base.join('\n');
  }

  const errorResponse: ApiErrorResponse = { status, data, message, type: errorType };

  return Promise.reject(errorResponse);
};

axiosInstance.interceptors.request.use(request => {
  request.headers.RequestTime = DateTime.local().toISO();
  return request;
});

axiosInstance.interceptors.response.use(response => response, handleError);

function isJson(string: string) {
  try {
    JSON.parse(string);
  } catch {
    return false;
  }

  return true;
}

export default axiosInstance;
