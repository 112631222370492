import { DateTime } from 'luxon';

export const getOrderStatus = (delivery_date: string) => {
  const today = DateTime.now().startOf('day');
  const deliveryDateTime = DateTime.fromISO(delivery_date).startOf('day');

  if (deliveryDateTime < today) {
    return 'delivered';
  }

  const daysUntilDelivery = deliveryDateTime.diff(today, 'days').days;

  if (daysUntilDelivery >= 5) {
    return 'open';
  }

  return 'locked';
};
