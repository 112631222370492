import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { RootState } from 'store/store';
import { ChatbotThread } from 'ux/Tenant/AIAssistant/types';

export const selectCustomerSupportTickets = (state: RootState) => state.AIAssistant.supportTickets;
const selectThreads = (state: RootState) => state.AIAssistant.threads;

export const selectThreadsSidebarVisible = (state: RootState) => state.AIAssistant.sidebarVisible;

export const selectChatbotThreads = createSelector(
  selectThreads,
  selectCustomerSupportTickets,
  (threads, supportTickets) => {
    const threadsWithSupportTickets = [
      ...threads.map(t => ({
        ...t,
        chatbot_interactions: t.chatbot_interactions.map(i => ({
          ...i,
          chatbot_intents: i.chatbot_intents.map(ci => ({
            ...ci,
            support_ticket: ci.support_ticket?.id
              ? supportTickets.find(st => st.id === ci.support_ticket?.id) || null
              : null
          }))
        }))
      }))
    ];
    return threadsWithSupportTickets.sort((a, b) => {
      const interactions_a = a.chatbot_interactions;
      const interactions_b = b.chatbot_interactions;
      const lastInteraction_a = interactions_a[interactions_a.length - 1];
      const lastInteraction_b = interactions_b[interactions_b.length - 1];

      if (!lastInteraction_a) return 0;
      if (!lastInteraction_b) return 1;

      return (
        DateTime.fromISO(lastInteraction_b.created_at).toMillis() -
        DateTime.fromISO(lastInteraction_a.created_at).toMillis()
      );
    });
  }
);

export const selectChatbotThreadById = (threadId: number) => (state: RootState) => {
  return selectChatbotThreads(state).find(thread => thread.id === threadId);
};

export const selectLatestChatbotThread = createSelector(selectChatbotThreads, threads => threads[0]);
export const selectThreadInputs = (state: RootState) => state.AIAssistant.threadInputs;
const selectPendingThreadInteractions = (state: RootState) => state.AIAssistant.pendingThreadInteractions;
export const selectPendingThreadInteraction = (threadId: number) =>
  createSelector(selectPendingThreadInteractions, pendingInteractions =>
    pendingInteractions.find(pi => pi.threadId === threadId)
  );
export const selectInputFocusOrdinal = (state: RootState) => state.AIAssistant.inputFocusOrdinal;

interface ThreadGroup {
  name: string;
  threads: ChatbotThread[];
}

export const selectChatbotThreadsAndGroupByMonth = createSelector(selectChatbotThreads, (threads): ThreadGroup[] => {
  const now = DateTime.now();
  const threeDaysAgo = now.minus({ days: 3 });
  const startOfLastYear = now.startOf('year');
  const startOfThisMonth = now.startOf('month');

  return threads
    .reduce((acc: ThreadGroup[], thread) => {
      const threadDate = DateTime.fromISO(thread.created_at);

      // Thread is from last 3 days
      if (threadDate >= threeDaysAgo) {
        const recentGroup = acc.find(group => group.name === 'Recently') || {
          name: 'Recently',
          threads: []
        };

        if (!acc.find(group => group.name === 'Recently')) {
          acc.push(recentGroup);
        }

        recentGroup.threads.push(thread);
      }
      // Thread is from this month but older than 3 days
      else if (threadDate >= startOfThisMonth) {
        const thisMonthGroup = acc.find(group => group.name === 'This month') || {
          name: 'This month',
          threads: []
        };

        if (!acc.find(group => group.name === 'This month')) {
          acc.push(thisMonthGroup);
        }

        thisMonthGroup.threads.push(thread);
      }
      // Thread is from this year but before this month
      else if (threadDate >= startOfLastYear) {
        const monthName = threadDate.toFormat('MMMM');
        const monthGroup = acc.find(group => group.name === monthName) || {
          name: monthName,
          threads: []
        };

        if (!acc.find(group => group.name === monthName)) {
          acc.push(monthGroup);
        }

        monthGroup.threads.push(thread);
      }
      // Thread is from previous years
      else {
        const yearName = threadDate.toFormat('yyyy');
        const yearGroup = acc.find(group => group.name === yearName) || {
          name: yearName,
          threads: []
        };

        if (!acc.find(group => group.name === yearName)) {
          acc.push(yearGroup);
        }

        yearGroup.threads.push(thread);
      }

      return acc;
    }, [])
    .sort((a, b) => {
      // Always keep "Recently" at the top
      if (a.name === 'Recently') return -1;
      if (b.name === 'Recently') return 1;
      // Keep "This month" second
      if (a.name === 'This month') return -1;
      if (b.name === 'This month') return 1;

      // Sort other groups by date (most recent first)
      const dateA = DateTime.fromFormat(a.name, a.name.length === 4 ? 'yyyy' : 'MMMM');
      const dateB = DateTime.fromFormat(b.name, b.name.length === 4 ? 'yyyy' : 'MMMM');
      return dateB.toMillis() - dateA.toMillis();
    });
});
